// ---------------------------------------------------------------------
// src/pages/ConversationScenarioSelection/ConversationScenarioSelection.tsx
// ---------------------------------------------------------------------

import React, { useEffect, useState, useMemo } from 'react';
import { Layout } from '../../components/Layout';
import { useTranslation } from 'react-i18next';
import './ConversationScenarioSelection.scss';
import { useAuth } from '../../context/AuthContext';
import { SettingsButton } from '../../components/SettingsButton';
import { useStudy } from '../../context/StudyContext';
import { getApiUrl } from '../../config/environment';
import { useTotalMessageCount } from '../../context/TotalMessageCountContext';
import { SPECIAL_USERS } from '../../config/limits'; // Pour savoir si UNLIMITED
import RegisterEmailForm from '../RegisterEmailForm';
import PaywallOffer from '../PaywallOffer';
import { useUser } from '../../context/UserContext';
import { scenarios } from './scenariosList';

function groupScenarios(list: any[], size: number) {
  const grouped = [];
  for (let i = 0; i < list.length; i += size) {
    grouped.push(list.slice(i, i + size));
  }
  return grouped;
}

interface ConversationScenarioSelectionProps {
  onScenarioSelect: () => void;
}

export const ConversationScenarioSelection: React.FC<ConversationScenarioSelectionProps> = ({
  onScenarioSelect,
}) => {
  const { currentUser } = useAuth();
  const { studyLanguage, setSelectedScenario } = useStudy();
  const { totalMessageCount = 0, refreshTotalMessageCount } = useTotalMessageCount();
  const { userData, refreshUserData } = useUser();

  // 1) On utilise 2 namespaces :
  //    - "scenarios" => conversationScenarioSelection.*
  //    - "registerEmailForm" => successMessage
  const { t: tScenarios } = useTranslation('scenarios');
  const { t: tRegister } = useTranslation('registerEmailForm');

  // ---------------------------------------------------------------------
  // États locaux
  // ---------------------------------------------------------------------
  const [highlightedScenario, setHighlightedScenario] = useState<string | null>(null);
  const [showRegisterEmailForm, setShowRegisterEmailForm] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // ---------------------------------------------------------------------
  // Groupement de scénarios par bloc de 5 (à adapter si besoin)
  // ---------------------------------------------------------------------
  const scenarioGroups = useMemo(() => groupScenarios(scenarios, 5), []);

  // ---------------------------------------------------------------------
  // Au montage => refreshUserData
  // ---------------------------------------------------------------------
  useEffect(() => {
    refreshUserData().catch((err) => {
      console.error('[ConversationScenarioSelection] => refreshUserData ERROR =>', err);
    });
  }, [refreshUserData]);

  // ---------------------------------------------------------------------
  // Log userData si modif
  // ---------------------------------------------------------------------
  useEffect(() => {
    if (userData) {
      console.log('[ConversationScenarioSelection] => userData changed =>', {
        dailyStreak: userData.dailyStreak,
        streakMessagesToday: userData.streakMessagesToday,
      });
    }
  }, [userData]);

  // ---------------------------------------------------------------------
  // Calcul des scénarios déjà complétés par l’utilisateur
  // ---------------------------------------------------------------------
  const completedScenarios = useMemo(() => {
    const studyLangObj = userData?.studyLanguages?.find(
      (lang) => lang.language === studyLanguage
    );
    if (!studyLangObj) return [];
    return (
      studyLangObj.scenarios?.filter((sc) => sc.completed).map((s) => s.scenarioId) || []
    );
  }, [userData, studyLanguage]);

  // ---------------------------------------------------------------------
  // Index du groupe “débloqué” (scénarios successifs)
  // ---------------------------------------------------------------------
  const unlockedGroupIndex = useMemo(() => {
    let unlocked = 0;
    for (let i = 0; i < scenarioGroups.length; i++) {
      const group = scenarioGroups[i];
      const allCompleted = group.every((sc) => completedScenarios.includes(sc.id));
      if (allCompleted) {
        unlocked = i + 1;
      } else {
        break;
      }
    }
    return Math.min(unlocked, scenarioGroups.length - 1);
  }, [completedScenarios, scenarioGroups]);

  // ---------------------------------------------------------------------
  // Reset du highlightedScenario et scroll top au montage
  // ---------------------------------------------------------------------
  useEffect(() => {
    setHighlightedScenario(null);
    window.scrollTo(0, 0);
  }, []);

  // ---------------------------------------------------------------------
  // handleScenarioSelect => déclenché au clic sur un scénario
  // ---------------------------------------------------------------------
  const handleScenarioSelect = async (scenarioId: string) => {
    setHighlightedScenario(scenarioId);

    if (!currentUser) {
      console.warn('[handleScenarioSelect] => No user => abort');
      return;
    }

    // (A) Check si UNLIMITED => pas de blocage
    if (!SPECIAL_USERS.UNLIMITED_ACCESS.includes(currentUser.uid)) {
      const subscriptionEndDate = userData?.subscriptionEndDate
        ? new Date(userData.subscriptionEndDate)
        : null;
      const isSubActive = subscriptionEndDate && subscriptionEndDate > new Date();

      // (B) Pas abonné => check anonyme => signUp
      if (!isSubActive && userData?.isAnonymous) {
        // Ex: Bloquage front pour anonymes
        const ANON_LIMIT = 8;
        if (totalMessageCount >= ANON_LIMIT) {
          setShowRegisterEmailForm(true);
          return;
        }
      }

      // (C) => On peut faire un fetch “canSelectScenario” (ex: pour re-check la limite 50)
      //     => si 403 => setIsLimitReached(true)
      try {
        const checkUrl = `${getApiUrl()}/api/scenario/canSelectScenario?uid=${currentUser.uid}`;
        console.log('[ConversationScenarioSelection] => checkUrl =>', checkUrl);

        const checkRes = await fetch(checkUrl);
        if (checkRes.status === 403) {
          console.warn('[ConversationScenarioSelection] => 403 => limit reached');
          setIsLimitReached(true);
          return;
        }
        if (!checkRes.ok) {
          throw new Error(`Backend returned ${checkRes.status}`);
        }
      } catch (error) {
        console.error('[handleScenarioSelect] => Error checking scenario limit =>', error);
        // On peut décider de bloquer ou non en cas d'erreur
        return;
      }
    }

    // (D) Met à jour le scénario dans le contexte
    setSelectedScenario(scenarioId);

    // (E) Sauvegarde en base => “updateScenarios” AVEC forceNewConversation
    try {
      const res = await fetch(`${getApiUrl()}/api/scenario/updateScenarios`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: currentUser.uid,
          studyLanguage,
          scenarioId,
          lastAccessed: new Date(),
          forceNewConversation: true, // <--- NOUVEAU
        }),
      });
      if (!res.ok) {
        throw new Error(`Failed to save scenario: HTTP ${res.status}`);
      }

      // (F) Appelle le callback parent => navigation ou autre
      onScenarioSelect();
    } catch (err) {
      console.error('[handleScenarioSelect] => Error =>', err);
    }
  };

  // ---------------------------------------------------------------------
  // handleSignUpSuccess => appelé après inscription
  // ---------------------------------------------------------------------
  const handleSignUpSuccess = async () => {
    setShowRegisterEmailForm(false);
    setShowSuccessMessage(true);
    await refreshTotalMessageCount();
    setTimeout(() => setShowSuccessMessage(false), 5000);
  };

  // ---------------------------------------------------------------------
  // Rendu JSX
  // ---------------------------------------------------------------------
  return (
    <Layout>
      <div className="relative w-full">
        {/* Bouton paramétrage */}
        <SettingsButton />

        {/* (1) Fond normal : liste des scénarios (si pas isLimitReached & pas showRegisterEmailForm) */}
        {!showRegisterEmailForm && !isLimitReached && (
          <div className="conversation-scenario-selection custom-top-padding">
            {/* Titre */}
            <div className="title-container">
              <h2
                className="
                  page-title
                  inline-block
                  text-[2rem]
                  sm:text-[2.4rem]
                  md:text-[3.3rem]
                  font-extrabold
                  text-[#243238]
                  leading-tight
                  text-center
                  mb-4
                  whitespace-normal
                  sm:whitespace-nowrap
                "
              >
                {/* conversationScenarioSelection.title => scenarios.json */}
                {tScenarios('conversationScenarioSelection.title')}{' '}
                <span className="title-emoji">🗣️</span>
              </h2>
            </div>

            {/* (a) étoiles + streak */}
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              {totalMessageCount > 0 && (
                <div className="star-count" style={{ marginBottom: '0.2rem' }}>
                  <p style={{ fontWeight: 'normal' }}>{totalMessageCount} ⭐️</p>
                </div>
              )}
              {totalMessageCount > 0 && userData && (
                <div className="star-count">
                  <p style={{ fontWeight: 'normal', margin: 0 }}>
                    {/* conversationScenarioSelection.streakCounter => pass {count: dailyStreak} */}
                    {tScenarios('conversationScenarioSelection.streakCounter', {
                      count: userData.dailyStreak ?? 0,
                    })}
                  </p>
                </div>
              )}
            </div>

            {/* (b) liste de scénarios groupés */}
            <div className="scenarios-list">
              {scenarioGroups.map((group, groupIndex) => {
                const isGroupUnlocked = groupIndex <= unlockedGroupIndex;

                return (
                  <div key={`group-${groupIndex}`} className="scenario-group">
                    <div className="group-divider">
                      <span className="group-number">{groupIndex + 1}</span>
                    </div>

                    {!isGroupUnlocked && (
                      <div className="unlock-message">
                        {/* conversationScenarioSelection.unlockMessage => pass {level: groupIndex} */}
                        {tScenarios('conversationScenarioSelection.unlockMessage', {
                          level: groupIndex,
                        })}
                      </div>
                    )}

                    <div className="group-scenarios">
                      {group.map((scenarioObj: any) => {
                        const isCompleted = completedScenarios.includes(scenarioObj.id);
                        const isLocked = !isGroupUnlocked;

                        // free_chat => style particulier
                        const isFreeChat = scenarioObj.id === 'free_chat';

                        return (
                          <div
                            key={scenarioObj.id}
                            className={`
                              scenario-card
                              ${highlightedScenario === scenarioObj.id ? 'selected' : ''}
                              ${isCompleted ? 'completed' : ''}
                              ${isLocked ? 'locked' : ''}
                              ${isFreeChat ? 'free-chat-special' : ''}
                            `}
                            onClick={() => !isLocked && handleScenarioSelect(scenarioObj.id)}
                          >
                            {isLocked && <span className="lock-icon">🔒</span>}

                            {/* Titre du scénario */}
                            <h3
                              className="
                                m-0
                                text-[1.2rem]
                                sm:text-[1.25rem]
                                md:text-[1.375rem]
                                lg:text-[1.65rem]
                                leading-snug
                                text-gray-800
                                font-bold
                              "
                            >
                              <span
                                className="
                                  scenario-emoji
                                  block
                                  text-center
                                  mb-2
                                "
                                style={{ fontSize: '5rem' }}
                              >
                                {scenarioObj.emoji}
                              </span>
                              {/* scenario.titleKey => ex: "conversationScenarioSelection.scenarios.cafe.title" */}
                              {tScenarios(scenarioObj.titleKey)}
                              {isCompleted && <span className="completed-icon">✅</span>}
                            </h3>

                            {/* Description */}
                            <p
                              className="
                                mt-2
                                text-[1.2rem]
                                sm:text-[1.25rem]
                                md:text-[1.375rem]
                                lg:text-[1.65rem]
                                leading-snug
                                text-gray-800
                              "
                            >
                              {/* scenario.descriptionKey => ex: "conversationScenarioSelection.scenarios.cafe.description" */}
                              {tScenarios(scenarioObj.descriptionKey)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* (2) Overlay si showRegisterEmailForm = true */}
        {showRegisterEmailForm && (
          <>
            {showSuccessMessage && (
              <div className="fixed top-16 w-full text-center p-4 bg-green-100 text-green-800 font-semibold z-50 shadow-lg">
                {/* RegisterEmailForm => registerEmailForm.json => "successMessage" */}
                {tRegister('successMessage')}
              </div>
            )}
            <div className="fixed inset-0 z-50 bg-black/50 overflow-auto">
              <div className="flex min-h-screen items-center justify-center p-4">
                <div
                  className="
                    bg-white
                    p-6
                    rounded-lg
                    shadow-lg
                    w-full
                    max-w-md
                    overflow-y-auto
                  "
                  style={{ maxHeight: '90vh' }}
                >
                  <RegisterEmailForm onSuccess={handleSignUpSuccess} />
                </div>
              </div>
            </div>
          </>
        )}

        {/* (3) Overlay si isLimitReached = true => PaywallOffer */}
        {isLimitReached && (
          <div className="fixed inset-0 z-50 bg-black/50 overflow-auto">
            <div className="flex min-h-screen items-center justify-center p-4">
              <div
                className="
                  bg-white
                  p-6
                  rounded-lg
                  shadow-lg
                  w-full
                  max-w-lg
                  overflow-y-auto
                "
                style={{ maxHeight: '90vh' }}
              >
                <PaywallOffer />
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ConversationScenarioSelection;