// src/pages/ConsolePage/checkPaywallLimit.ts
import { getApiUrl } from '../../config/environment';

export async function checkPaywallLimit(uid: string): Promise<boolean> {
  const apiUrl = getApiUrl();

  console.log('[checkPaywallLimit] => DÉBUT =>', {
    uid,
    finalEndpoint: `${apiUrl}/api/conversation/canSendMessage?uid=${uid}`,
    timestamp: new Date().toISOString(),
  });

  try {
    const res = await fetch(`${apiUrl}/api/conversation/canSendMessage?uid=${uid}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    console.log('[checkPaywallLimit] => RÉPONSE => status=', res.status);

    if (res.status === 403) {
      console.warn('[checkPaywallLimit] => 403 => paywall limit => return true');
      return true;
    }
    if (!res.ok) {
      console.error('[checkPaywallLimit] => Erreur HTTP =>', {
        status: res.status,
        statusText: res.statusText,
      });
      return false; // En cas d'erreur => on ne bloque pas
    }

    console.log('[checkPaywallLimit] => OK => 200 => pas de blocage => false');
    return false;
  } catch (err) {
    console.error('[checkPaywallLimit] => Exception =>', err);
    return false;
  }
}