//src/pages/ConsolePage/useScenarioCompletion.ts

import { useState, useCallback } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useUser } from '../../../context/UserContext';
import { useStudy } from '../../../context/StudyContext';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8082';

export function useScenarioCompletion(checkAndTriggerStreakIncrease: (oldStreak: number) => Promise<void>) {
  const { currentUser } = useAuth();
  const { userData } = useUser();
  const { studyLanguage, selectedScenario } = useStudy();

  const [showScenarioSuccessMessage, setShowScenarioSuccessMessage] = useState(false);
  const [scenarioAlreadyCompleted, setScenarioAlreadyCompleted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const markScenarioAsCompleted = useCallback(async () => {
    if (!currentUser) return;

    const uid = currentUser.uid;
    const oldStreak = userData?.dailyStreak || 0;

    try {
      const response = await fetch(`${API_BASE_URL}/api/scenario/completeScenario`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid,
          studyLanguage,
          scenarioId: selectedScenario,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        setError(data.error || 'Unknown error');
        return;
      }

      if (data.message === 'Scénario déjà complété') {
        setScenarioAlreadyCompleted(true);
        setTimeout(() => setScenarioAlreadyCompleted(false), 5000);
      } else {
        setShowScenarioSuccessMessage(true);
        setTimeout(() => setShowScenarioSuccessMessage(false), 5000);

        // Vérifier la streak
        await checkAndTriggerStreakIncrease(oldStreak);
      }
    } catch (err: any) {
      setError(err.message || 'Erreur scenario');
    }
  }, [currentUser, userData?.dailyStreak, studyLanguage, selectedScenario, checkAndTriggerStreakIncrease]);

  return {
    showScenarioSuccessMessage,
    scenarioAlreadyCompleted,
    error,
    markScenarioAsCompleted,
    setError,
  };
}