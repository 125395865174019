// src/pages/Marketing/ContactPage.tsx

import React, { useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout';

const ContactPage: React.FC = () => {
  // On cible le namespace "contactPage"
  const { t } = useTranslation('contactPage');

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'idle' | 'sending' | 'sent' | 'error'>('idle');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setStatus('sending');

    try {
      // Exemple : POST to /api/contact
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, message }),
      });

      if (!response.ok) {
        throw new Error(`Server returned status: ${response.status}`);
      }
      setStatus('sent');
    } catch (err) {
      console.error('Error sending contact form:', err);
      setStatus('error');
    }
  };

  return (
    <Layout>
      <section
        className="
          relative
          w-screen left-1/2 ml-[-50vw]
          right-1/2 mr-[-50vw]
          border-t border-gray-300
          py-24
        "
      >
        <div className="max-w-screen-lg mx-auto px-4 text-center text-[1.65rem] leading-snug">

          {/* Page title */}
          <h1 className="text-3xl md:text-4xl font-bold mb-6">
            {t('title')}
          </h1>

          {/* Intro text */}
          <p className="text-gray-700 mb-12 max-w-[50rem] mx-auto">
            {t('intro')}
          </p>

          {/* Success message */}
          {status === 'sent' && (
            <div className="bg-teal-100 p-4 mb-6 text-teal-700 font-semibold rounded">
              {t('successMessage')}
            </div>
          )}

          {/* Error message */}
          {status === 'error' && (
            <div className="bg-red-100 p-4 mb-6 text-red-700 font-semibold rounded">
              {t('errorMessage')}
            </div>
          )}

          {/* Contact Form */}
          <form
            onSubmit={handleSubmit}
            className="max-w-xl mx-auto text-left mb-12"
          >
            {/* Email field */}
            <label
              className="block mb-2 font-semibold"
              htmlFor="email"
            >
              {t('emailLabel')}
            </label>
            <input
              id="email"
              type="email"
              required
              className="
                border-2 border-black rounded
                w-full p-3 mb-6
                focus:outline-none focus:border-blue-500
              "
              placeholder={t('emailPlaceholder') || ''}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            {/* Message field */}
            <label
              className="block mb-2 font-semibold"
              htmlFor="message"
            >
              {t('messageLabel')}
            </label>
            <textarea
              id="message"
              rows={5}
              required
              className="
                border-2 border-black rounded
                w-full p-3 mb-6
                focus:outline-none focus:border-blue-500
              "
              placeholder={t('messagePlaceholder') || ''}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            {/* Submit button */}
            <button
              type="submit"
              disabled={status === 'sending'}
              className={`
                px-8 py-3
                ${
                  status === 'sending'
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#3f7afd] hover:bg-[#2f69df]'
                }
                text-white font-semibold text-[1.2rem] rounded
                transition-transform transform hover:scale-105 focus:outline-none focus:ring-4
                focus:ring-blue-300
              `}
            >
              {status === 'sending'
                ? t('sendingButton')
                : t('sendButton')}
            </button>
          </form>

          {/* “Go to Homepage” button */}
          <Link
            to="/"
            className={`
              inline-block
              px-8 py-4
              bg-[#3f7afd] hover:bg-[#2f69df]
              text-white font-bold text-[1.4rem] md:text-[1.5rem] rounded-lg
              transition-transform transform hover:scale-105 focus:outline-none focus:ring-4
              focus:ring-blue-300
            `}
          >
            {t('goToHomepage')}
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;