// src/pages/SuccessPage.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import { useUser } from '../context/UserContext';
import { useAuth } from '../context/AuthContext'; 
import { getApiUrl } from '../config/environment'; // ou où tu définis getApiUrl

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  // On cible le namespace "successPage"
  const { t } = useTranslation('successPage');
  const { refreshUserData } = useUser();
  const { currentUser } = useAuth(); // On a besoin du UID
  
  useEffect(() => {
    console.log('[SuccessPage] Payment successful, executing useEffect');
    
    // 1) On rafraîchit d’abord les données (au cas où le Webhook Stripe est déjà passé en production).
    console.log('[SuccessPage] Refreshing user data after payment success');
    refreshUserData()
      .then(() => {
        console.log('[SuccessPage] User data refreshed successfully');
      })
      .catch((error) => {
        console.error('[SuccessPage] Error refreshing user data:', error);
      });
    
    // 2) En DEV => forcer l’abonnement (si currentUser existe)
    //    On vérifie REACT_APP_ENVIRONMENT ou NODE_ENV
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      console.log('[SuccessPage] Dev environment detected => forcing membership');
      
      if (currentUser?.uid) {
        const forceUrl = `${getApiUrl()}/api/dev/forceMembership`;
        fetch(forceUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ uid: currentUser.uid }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log('[SuccessPage] devForceMembership =>', data);
            // Rafraîchir à nouveau pour voir isPayingMember = true
            return refreshUserData();
          })
          .catch((err) => {
            console.warn('[SuccessPage] devForceMembership error =>', err);
          });
      }
    }

    // 3) Redirection après 3 secondes
    const timer = setTimeout(() => {
      console.log('[SuccessPage] Redirecting to main page');
      navigate('/'); 
    }, 3000);

    return () => {
      clearTimeout(timer);
      console.log('[SuccessPage] Cleanup timer on unmount');
    };
  }, [navigate, refreshUserData, currentUser]);
  
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        {/* Lottie Animation */}
        <div className="flex justify-center mb-6">
          <Player
            autoplay
            loop={false}
            src="/animations/animation-success.json.json"
            style={{ height: '150px', width: '150px' }}
          />
        </div>
        <h1 className="text-2xl font-bold mb-4">{t('title')}</h1>
        <p className="text-lg mb-6">{t('description')}</p>
      </div>
    </div>
  );
};

export default SuccessPage;