// src/pages/ConsolePage/SuggestionBubble.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useWebTTS } from '../../hooks/useWebTTS';
import { convertLangToBCP47 } from '../../utils/langToBCP47';
import { useStudy } from '../../context/StudyContext';

interface SuggestionBubbleProps {
  text?: string;
  revealedPhrase?: string | null;
  iconSrc?: string;
  onClick?: () => void;
}

export function SuggestionBubble({
  text,
  revealedPhrase = null,
  iconSrc = '/icons/lightbulb-icon.png',
  onClick,
}: SuggestionBubbleProps) {
  const { t } = useTranslation('consolePage');
  const leftText = text || t('micInstruction');

  const { studyLanguage } = useStudy();
  const ttsLang = convertLangToBCP47(studyLanguage || 'en');

  const { speak, speaking, cancel } = useWebTTS(ttsLang);

  function handleReadAloud() {
    if (!revealedPhrase) return;
    speak(revealedPhrase, {
      lang: ttsLang,
      volume: 1.0,
      rate: 1.0,
    });
  }

  // Choix de l'icône et de la callback selon speaking
  const isReading = speaking === true;
  const iconForButton = isReading
    ? '/icons/stop-icon-button.png'
    : '/icons/volume-icon.webp';
  const onClickForButton = isReading ? cancel : handleReadAloud;
  const ariaLabel = isReading ? 'Stop reading' : 'Read Aloud';

  return (
    <div className="flex items-center justify-end mb-4 mx-4 lg:container lg:mx-auto lg:px-52 xl:px-96">
      <div
        className="
          relative
          p-4
          rounded-2xl
          max-w-[90%]
          bg-transparent
          border-2 border-dashed border-blue-400
          opacity-90
          hover:opacity-100
          transition-opacity
        "
        style={{ backdropFilter: 'blur(2px)' }}
      >
        {/* (1) Ligne texte + icône ampoule */}
        <div
          className="
            inline-flex
            items-center
            gap-16
            text-blue-800
            text-[1.2rem]
            sm:text-[1.25rem]
            md:text-[1.375rem]
            lg:text-[1.65rem]
            leading-snug
          "
        >
          <span>{leftText}</span>
          <img
            src={iconSrc}
            alt="Suggestion Icon"
            className="
              w-9 h-9
              cursor-pointer
              transform-gpu
              transition-all
              duration-300
              hover:scale-125
              hover:brightness-150
              hover:ring-4
              hover:ring-yellow-300
            "
            onClick={onClick}
          />
        </div>

        {/* (2) Phrase révélée + bouton unique (volume OU stop) */}
        {revealedPhrase && (
          <div className="mt-3 p-3 bg-white bg-opacity-70 rounded-xl text-gray-900">
            <div className="font-bold mb-1">{t('youCouldSayTitle')}</div>
            <div className="text-lg leading-snug">{revealedPhrase}</div>

            {/* Bouton -> volume ou stop, décalé de 5px vers la gauche */}
            <div className="mt-3">
              <button
                onClick={onClickForButton}
                aria-label={ariaLabel}
                style={{ marginLeft: '-8px' }} 
                className="
                  inline-flex items-center justify-center
                  w-10 h-10
                  rounded-full
                  bg-transparent
                  hover:bg-gray-200
                  focus:outline-none
                  transition
                "
              >
                <img
                  src={iconForButton}
                  alt={ariaLabel}
                  className="w-6 h-6"
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}