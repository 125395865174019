// src/pages/LandingPage.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from '../config/environment';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { useUser } from '../context/UserContext';

// Import des sections existantes
import { LandingFeatureSection } from './LandingFeatureSection';
import { LandingTestimonialsSection } from './LandingTestimonialsSection';
import { LandingFAQSection } from './LandingFAQSection';

interface LandingPageProps {
  onGetStarted: () => void;
}

export const LandingPage: React.FC<LandingPageProps> = ({ onGetStarted }) => {
  // 1) On précise qu’on veut le namespace "landingPage"
  const { t } = useTranslation('landingPage');

  // 2) Auth & user data
  const { currentUser, loading } = useAuth();
  const { browserLanguage, nativeLanguageSelected, setNativeLanguageSelected } = useLanguage();
  const { userData, refreshUserData } = useUser();
  const navigate = useNavigate();

  // [A] Initialise la langue maternelle si pas encore définie
  useEffect(() => {
    if (!nativeLanguageSelected) {
      setNativeLanguageSelected(browserLanguage);
    }
  }, [browserLanguage, nativeLanguageSelected, setNativeLanguageSelected]);

  // [B] Handler quand on clique "Get Started"
  const handleGetStartedClick = async () => {
    if (!currentUser) return;

    const selectedNativeLanguage = nativeLanguageSelected || browserLanguage;

    try {
      // Met à jour la langue maternelle en DB si nécessaire
      if (userData?.nativeLanguageSelected !== selectedNativeLanguage) {
        await updateNativeLanguage(currentUser.uid, selectedNativeLanguage);
        await refreshUserData();
      }
      // Met aussi à jour localement si pas encore fait
      if (!nativeLanguageSelected) {
        setNativeLanguageSelected(selectedNativeLanguage);
      }

      onGetStarted();
    } catch (error) {
      console.error('[LandingPage] ❌ Error in navigation =>', error);
    }
  };

  // [C] Met à jour la langue maternelle côté backend
  async function updateNativeLanguage(uid: string, nativeLanguage: string) {
    if (!uid || !nativeLanguage) return;
    const apiUrl = getApiUrl();

    await fetch(`${apiUrl}/api/user/registerOrUpdateUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        uid,
        nativeLanguageSelected: nativeLanguage,
        defaultBrowserLanguage: browserLanguage,
      }),
    });
  }

  // [D] Bouton secondaire => "/login"
  const handleLoginClick = () => {
    navigate('/login');
  };

  // [E] Affiche un spinner si on est encore en loading
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    );
  }

  // [F] Rendu principal
  return (
    <Layout noMaxWidth>
      {/* SECTION HERO */}
      <section
        className="
          h-screen
          flex
          flex-col
          md:flex-row
          items-center
          justify-center
          px-4
          gap-3
          md:gap-20
        "
      >
        {/* Colonne gauche : Vidéo/animation */}
        <div className="flex justify-center mb-4 md:mb-0">
          <div
            className="
              border-2 border-gray-800
              overflow-hidden
              rounded-xl
              w-64 h-64
              sm:w-80 sm:h-80
              md:w-96 md:h-96
              lg:w-[28rem] lg:h-[28rem]
            "
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              className="w-full h-full object-cover"
            >
              <source
                src="/animations/animation-landingpage2.mp4"
                type="video/mp4"
              />
              {/* fallback text */}
              {t('videoFallback')}
            </video>
          </div>
        </div>

        {/* Colonne droite : Titre + 2 boutons */}
        <div className="flex flex-col items-center justify-center">
          <div className="max-w-[44rem] mx-auto text-center">
            {/* Titre principal */}
            <h1
              className="
                text-[2rem]
                sm:text-[2.4rem]
                md:text-[3.3rem]
                font-extrabold
                text-[#243238]
                mb-6
                leading-tight
              "
            >
              {t('title')}
            </h1>

            {/* Description */}
            <p
              className="
                text-[1.2rem]
                sm:text-[1.25rem]
                md:text-[1.375rem]
                lg:text-[1.65rem]
                text-gray-800
                mb-8
                leading-snug
              "
            >
              {t('description')}
            </p>

            {/* Groupe de 2 boutons : même largeur => w-[36rem] */}
            <div className="flex flex-col gap-4 items-center">
              {/* BOUTON 1 : GET STARTED */}
              <button
                onClick={handleGetStartedClick}
                disabled={!currentUser || loading}
                className={`
                  w-[28rem]
                  whitespace-nowrap
                  px-8 py-4
                  uppercase
                  text-white
                  font-semibold
                  text-[1.3rem]
                  md:text-[1.5rem]
                  rounded-lg
                  shadow-lg
                  transition-transform transform hover:scale-105
                  focus:outline-none focus:ring-4 focus:ring-blue-300
                  ${
                    !currentUser || loading
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-[#3f7afd] hover:bg-[#2f69df]'
                  }
                `}
              >
                {t('getStartedButton').toUpperCase()}
              </button>

              {/* BOUTON 2 : I ALREADY HAVE AN ACCOUNT */}
              {(!currentUser || currentUser.isAnonymous) && (
                <button
                  onClick={handleLoginClick}
                  disabled={loading}
                  className="
                    w-[28rem]
                    whitespace-nowrap
                    px-8 py-4
                    uppercase
                    text-[1.3rem]
                    md:text-[1.5rem]
                    font-semibold
                    rounded-lg
                    shadow-lg
                    transition-transform transform hover:scale-105
                    focus:outline-none focus:ring-4 focus:ring-blue-200
                    bg-white
                    border border-[#3f7afd]
                    text-[#3f7afd]
                    hover:bg-gray-50
                  "
                >
                  {t('alreadyHaveAccount', 'I ALREADY HAVE AN ACCOUNT').toUpperCase()}
                </button>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* SECTION FEATURES */}
      <LandingFeatureSection />

      {/* SECTION TESTIMONIALS */}
      <LandingTestimonialsSection />

      {/* SECTION FAQ */}
      <LandingFAQSection />

      {/* SECTION CTA FINALE (full-bleed) */}
      <section
        className={`
          relative
          w-screen left-1/2 ml-[-50vw]
          right-1/2 mr-[-50vw]
          border-t border-gray-300
          py-24
        `}
        style={{
          backgroundImage: 'url("/background-for-all-website.jpg")',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="max-w-screen-lg mx-auto px-4 text-center text-[1.65rem] leading-snug">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">
            {t('finalCtaTitle')}
          </h2>
          <p className="text-gray-800 mb-8">
            {t('finalCtaText')}
          </p>
          <button
            onClick={handleGetStartedClick}
            disabled={!currentUser || loading}
            className={`
              w-[36rem]
              whitespace-nowrap
              px-8 py-4
              uppercase
              text-white
              font-semibold
              text-[1.3rem]
              md:text-[1.5rem]
              rounded-lg
              shadow-lg
              transition-transform transform hover:scale-105
              focus:outline-none focus:ring-4 focus:ring-blue-300
              ${
                !currentUser || loading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-[#3f7afd] hover:bg-[#2f69df]'
              }
            `}
          >
            {t('finalCtaButton').toUpperCase()}
          </button>
        </div>
      </section>
    </Layout>
  );
};

export default LandingPage;