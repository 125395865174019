// src/utils/cultureMap.ts

// On définit un type CultureData avec "name" et "emoji"
interface CultureData {
  name: string;
  emoji: string;
}

// Dictionnaire : code langue => CultureData
// Tu remarqueras qu'on met des versions "courtes",
// ex: "cultura" en espagnol, portugais, italien,
// "culture" en fr/en, "kultur" en de, etc.
const cultureMap: Record<string, CultureData> = {
  ar: { name: 'الثقافة',      emoji: '🌐' },  // court: 'الثقافة'
  de: { name: 'kultur',        emoji: '🇩🇪' },
  en: { name: 'culture',       emoji: '🇺🇸' },
  es: { name: 'cultura',       emoji: '🇪🇸' },
  fr: { name: 'culture',       emoji: '🇫🇷' },
  hi: { name: 'संस्कृति',      emoji: '🇮🇳' },
  it: { name: 'cultura',       emoji: '🇮🇹' },
  ja: { name: '文化',          emoji: '🇯🇵' },
  ko: { name: '문화',          emoji: '🇰🇷' },
  nl: { name: 'cultuur',       emoji: '🇳🇱' },
  pl: { name: 'kultura',       emoji: '🇵🇱' },
  pt: { name: 'cultura',       emoji: '🇧🇷' },
  ru: { name: 'культура',      emoji: '🇷🇺' },
  tr: { name: 'kültür',        emoji: '🇹🇷' },
  zh: { name: '文化',          emoji: '🇨🇳' },
  id: { name: 'budaya',        emoji: '🇮🇩' },
  ur: { name: 'ثقافت',         emoji: '🇵🇰' },
};

// Cette fonction te renvoie { name, emoji }
// Par ex, pour "pt": { name: "cultura", emoji: "🇧🇷" }
export function getCultureData(langCode: string): CultureData {
  // fallback
  return cultureMap[langCode] || { name: 'culture', emoji: '🌐' };
}