// -----------------------------------------------------------------------
// src/pages/ConsolePage/TextInputBoxRealtime.tsx
// -----------------------------------------------------------------------
import React, { useState, KeyboardEvent } from 'react';
import { Send } from 'react-feather';
import { useTranslation } from 'react-i18next';

interface TextInputBoxRealtimeProps {
  /**
   * Fonction appelée pour envoyer le message texte.
   * (Ex. => envoie un event "conversation.item.create" + "response.create" via dataChannel)
   */
  onSendMessage: (text: string) => void;

  /**
   * Callback optionnel pour vérifier la limite (paywall, anonyme...) avant d'envoyer.
   * - Retourne un boolean ou Promise<boolean>.
   * - Si false => on bloque.
   */
  onBeforeSend?: () => boolean | Promise<boolean>;
}

/**
 * TextInputBoxRealtime:
 *   - Style identique aux bulles (border-[#333], bg-white, shadow-sm, etc.)
 *   - Gère Enter pour envoyer (Shift+Enter => saut de ligne)
 *   - Icône Send à droite
 *   - Appelle onSendMessage(...) si la limite n'est pas atteinte
 */
export function TextInputBoxRealtime({
  onSendMessage,
  onBeforeSend,
}: TextInputBoxRealtimeProps) {
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  // 1) Cible le namespace "consolePage"
  const { t: tConsole } = useTranslation('consolePage');

  /**
   * handleSend:
   *   - Vérifie la limite (onBeforeSend),
   *   - Envoie le message,
   *   - Réinitialise le champ.
   */
  const handleSend = async () => {
    const trimmed = message.trim();
    if (!trimmed) return;

    if (onBeforeSend) {
      setIsSending(true);
      const allowed = await onBeforeSend();
      setIsSending(false);
      if (!allowed) {
        console.warn('[TextInputBoxRealtime] => Bloqué par onBeforeSend => skip.');
        return;
      }
    }

    onSendMessage(trimmed);
    setMessage('');
  };

  /**
   * Gère Enter pour envoyer (Shift+Enter => saut de ligne).
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      void handleSend();
    }
  };

  // 2) Choix du placeholder selon l'état isSending
  //    On utilise la clé "sendingPlaceholder" ou "typeYourMessagePlaceholder" du namespace consolePage
  const placeholderText = isSending
    ? tConsole('sendingPlaceholder', 'Envoi en cours...')
    : tConsole('typeYourMessagePlaceholder', 'Tapez votre message ici...');

  return (
    <div
      className="
        relative
        rounded-2xl
        border border-[#333]
        shadow-sm
        bg-white
        p-4
        flex
        items-center
        gap-2
      "
    >
      {/* Zone de saisie multiline */}
      <textarea
        className="
          flex-1
          resize-none
          border-0
          focus:ring-0
          focus:outline-none
          text-gray-800
          leading-snug
          text-[1.2rem]
          sm:text-[1.25rem]
          md:text-[1.375rem]
          lg:text-[1.65rem]
          placeholder-gray-400
          min-h-[36px]
          max-h-40
          overflow-y-auto
        "
        rows={1}
        placeholder={placeholderText}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={isSending}
      />

      {/* Icône Send, avec aria-label traduit */}
      <button
        type="button"
        onClick={handleSend}
        disabled={!message.trim() || isSending}
        className="
          inline-flex items-center justify-center
          p-2
          text-gray-600
          hover:text-blue-600
          disabled:cursor-not-allowed
          disabled:text-gray-300
          transition-colors
        "
        aria-label={tConsole('sendButtonAriaLabel', 'Envoyer')}
      >
        <Send className="w-6 h-6" />
      </button>
    </div>
  );
}

export default TextInputBoxRealtime;