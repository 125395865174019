// src/context/UserContext.tsx

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getApiUrl } from '../config/environment';
import { useAuth } from './AuthContext';

/** Interface pour un scénario */
interface ScenarioData {
  scenarioId: string;
  completed: boolean;
}

/** Interface pour les données d'une langue d'étude */
interface StudyLanguageData {
  language: string;
  level?: number;
  totalConversations?: number;
  scenarios?: ScenarioData[];
}

/** Interface principale pour les données utilisateur */
interface UserData {
  browserLanguage?: string;
  nativeLanguageSelected?: string;
  lastSelectedStudyLanguage?: string;
  lastSelectedStudyLevel?: number;
  studyLanguages?: StudyLanguageData[];
  subscriptionEndDate?: string;
  subscriptionStartDate?: string; // Optionnel
  isPayingMember?: boolean;  // Indique si l'utilisateur est abonné
  email?: string;
  isAnonymous?: boolean;
  dailyStreak?: number;      // Streak quotidienne
  longestStreak?: number;    // Streak la plus longue
  streakMessagesToday?: number; // Nombre de messages aujourd'hui

  /** 
   * Nouveau champ pour gérer le prénom.
   * On le déclare optionnel (?:) pour éviter les erreurs
   * si jamais il n'est pas défini côté backend.
   */
  firstName?: string;
}

/** Type du contexte utilisateur */
interface UserContextProps {
  userData: UserData | null;
  refreshUserData: () => Promise<void>;
}

/** Création du contexte */
const UserContext = createContext<UserContextProps | undefined>(undefined);

console.log('[UserContext] (NOUVEAU LOG) Création du UserProvider');

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const { currentUser } = useAuth();

  /**
   * refreshUserData :
   *  - Récupère (via /getUserProfileInfo) les données utilisateur
   *  - Met à jour userData dans le state
   */
  const refreshUserData = useCallback(async () => {
    const uid = currentUser?.uid;
    if (!uid) {
      // Pas d'utilisateur => on réinitialise userData
      setUserData(null);
      console.log('[UserContext] No current user, setting userData to null');
      console.log('[UserContext] (NOUVEAU LOG) Abandon de refreshUserData car pas de UID');
      return;
    }

    try {
      console.log(`[UserContext] Fetching user data for UID: ${uid}`);

      // Préparation de l'URL
      const apiUrl = getApiUrl();
      const url = `${apiUrl}/api/user/getUserProfileInfo?uid=${uid}`;
      console.log('[UserContext] (NOUVEAU LOG) URL finale pour getUserProfileInfo =>', url);

      // Juste avant la requête
      console.log('[UserContext] (NOUVEAU LOG) Avant fetch =>', {
        endpoint: url,
        timestamp: new Date().toISOString(),
      });

      // Appel API
      const response = await fetch(url);

      // Log de la réponse brute
      console.log('[UserContext] (NOUVEAU LOG) Réponse brute =>', {
        status: response.status,
        statusText: response.statusText,
      });

      if (!response.ok) {
        console.error('[UserContext] ❌ Echec fetch user data:', {
          status: response.status,
          statusText: response.statusText,
        });
        throw new Error(`Failed to fetch user data: ${response.status} ${response.statusText}`);
      }

      // Parsing JSON
      const data = await response.json();
      console.log('[UserContext] (NOUVEAU LOG) Data JSON reçue =>', data);

      // Mise à jour du state
      setUserData(data);
      console.log('[UserContext] User data fetched and set:', data);

    } catch (error) {
      console.error('[UserContext] Error fetching user data:', error);
      console.log('[UserContext] (NOUVEAU LOG) Erreur dans refreshUserData =>', {
        errorMessage: error instanceof Error ? error.message : 'unknown error',
        timestamp: new Date().toISOString(),
      });
    }
  }, [currentUser]);

  /**
   * useEffect : se déclenche à chaque changement de currentUser
   */
  useEffect(() => {
    if (currentUser) {
      console.log('[UserContext] Current user detected:', currentUser.uid);
      console.log('[UserContext] (NOUVEAU LOG) Appel refreshUserData() car currentUser existe');
      refreshUserData();
    } else {
      console.log('[UserContext] No current user detected => set userData=null');
      setUserData(null);
    }
  }, [currentUser, refreshUserData]);

  /**
   * Log final avant le return
   */
  console.log('[UserContext] (NOUVEAU LOG) Juste avant le return =>', {
    userData,
  });

  return (
    <UserContext.Provider value={{ userData, refreshUserData }}>
      {children}
    </UserContext.Provider>
  );
};

/**
 * Hook personnalisé pour consommer le contexte UserContext
 */
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};