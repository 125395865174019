// src/pages/LandingFeatureSection.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Section présentant plusieurs fonctionnalités.
 * Exemple d'UI : cartes ou items en colonne/ligne.
 */
export const LandingFeatureSection: React.FC = () => {
  // On cible le namespace "landingPage"
  const { t } = useTranslation('landingPage');

  return (
    <section
      className={`
        /* Full-bleed */
        relative
        w-screen left-1/2 ml-[-50vw]
        right-1/2 mr-[-50vw]
        border-t border-gray-300
        py-24
        bg-white
      `}
    >
      <div className="max-w-screen-lg mx-auto px-4 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-10">
          {/* ex. "Key Features of Voxanet" */}
          {t('featureSectionTitle')}
        </h2>

        {/*
          On affiche 6 features en “dur”.
          Si tu préfères boucler, tu peux récupérer l’objet "features" 
          via t('features', { returnObjects: true }) et itérer dessus.
        */}
        <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 text-[1.65rem] leading-snug">
          {/* Feature 1 : AI */}
          <div className="p-6 border rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-5xl mb-4">{t('features.ai.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.ai.title')}</h3>
            <p className="text-gray-800">
              {t('features.ai.description')}
            </p>
          </div>

          {/* Feature 2 : Speaking */}
          <div className="p-6 border rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-5xl mb-4">{t('features.speaking.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.speaking.title')}</h3>
            <p className="text-gray-800">
              {t('features.speaking.description')}
            </p>
          </div>

          {/* Feature 3 : Personalized */}
          <div className="p-6 border rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-5xl mb-4">{t('features.personalized.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.personalized.title')}</h3>
            <p className="text-gray-800">
              {t('features.personalized.description')}
            </p>
          </div>

          {/* Feature 4 : Chatbot */}
          <div className="p-6 border rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-5xl mb-4">{t('features.chatbot.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.chatbot.title')}</h3>
            <p className="text-gray-800">
              {t('features.chatbot.description')}
            </p>
          </div>

          {/* Feature 5 : Feedback */}
          <div className="p-6 border rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-5xl mb-4">{t('features.feedback.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.feedback.title')}</h3>
            <p className="text-gray-800">
              {t('features.feedback.description')}
            </p>
          </div>

          {/* Feature 6 : Progress */}
          <div className="p-6 border rounded-lg shadow hover:shadow-xl transition-shadow">
            <div className="text-5xl mb-4">{t('features.progress.emoji')}</div>
            <h3 className="font-bold mb-2">{t('features.progress.title')}</h3>
            <p className="text-gray-800">
              {t('features.progress.description')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};