// src/pages/StudyLanguageSelection.tsx

import React, { useState, useEffect } from 'react';
import { Layout } from '../components/Layout';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { useStudy } from '../context/StudyContext';
import FlagIcon from '../components/FlagIcon';

interface StudyLanguageSelectionProps {
  onLanguageSelect: () => void;
}

interface LanguageCardData {
  code: string;
  countryCode: string;
  nameKey: string;
  learnersCountLabel: string;  // ex. "690M learners"
}

// On place EN en tête, puis les autres
const LANGUAGES_DATA: LanguageCardData[] = [
  {
    code: 'en',
    countryCode: 'us',
    nameKey: 'languages.en',
    learnersCountLabel: '690M learners',
  },
  {
    code: 'ar',
    countryCode: 'sa',
    nameKey: 'languages.ar',
    learnersCountLabel: '8.31M learners',
  },
  {
    code: 'de',
    countryCode: 'de',
    nameKey: 'languages.de',
    learnersCountLabel: '18.6M learners',
  },
  {
    code: 'es',
    countryCode: 'es',
    nameKey: 'languages.es',
    learnersCountLabel: '48.8M learners',
  },
  {
    code: 'fr',
    countryCode: 'fr',
    nameKey: 'languages.fr',
    learnersCountLabel: '27.3M learners',
  },
  {
    code: 'hi',
    countryCode: 'in',
    nameKey: 'languages.hi',
    learnersCountLabel: '11M learners',
  },
  {
    code: 'it',
    countryCode: 'it',
    nameKey: 'languages.it',
    learnersCountLabel: '13.1M learners',
  },
  {
    code: 'ja',
    countryCode: 'jp',
    nameKey: 'languages.ja',
    learnersCountLabel: '23.3M learners',
  },
  {
    code: 'ko',
    countryCode: 'kr',
    nameKey: 'languages.ko',
    learnersCountLabel: '17.8M learners',
  },
  {
    code: 'nl',
    countryCode: 'nl',
    nameKey: 'languages.nl',
    learnersCountLabel: '3.22M learners',
  },
  {
    code: 'pl',
    countryCode: 'pl',
    nameKey: 'languages.pl',
    learnersCountLabel: '2.51M learners',
  },
  {
    code: 'pt',
    countryCode: 'pt',
    nameKey: 'languages.pt',
    learnersCountLabel: '5.86M learners',
  },
  {
    code: 'ru',
    countryCode: 'ru',
    nameKey: 'languages.ru',
    learnersCountLabel: '9.03M learners',
  },
  {
    code: 'tr',
    countryCode: 'tr',
    nameKey: 'languages.tr',
    learnersCountLabel: '4.71M learners',
  },
  {
    code: 'ur',
    countryCode: 'pk',
    nameKey: 'languages.ur',
    learnersCountLabel: '7.6M learners',
  },
  {
    code: 'zh',
    countryCode: 'cn',
    nameKey: 'languages.zh',
    learnersCountLabel: '10.7M learners',
  },
];

export const StudyLanguageSelection: React.FC<StudyLanguageSelectionProps> = ({
  onLanguageSelect,
}) => {
  const { currentUser } = useAuth();

  // On suppose que "studyLanguageSelection" est dans "common.json"
  const { t: tCommon } = useTranslation('common');
  const { setStudyLanguage } = useStudy();

  // Pas de présélection => ''
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');

  useEffect(() => {
    // On ne force plus 'en'
  }, []);

  /**
   * handleCardClick :
   *   - 1) Assigne selectedLanguage
   *   - 2) Si user connecté => on envoie au backend
   *   - 3) onLanguageSelect()
   */
  const handleCardClick = async (langCode: string) => {
    setSelectedLanguage(langCode);

    if (!currentUser) {
      console.error("[StudyLanguageSelection] Pas d'utilisateur connecté");
      return;
    }
    console.log('[StudyLanguageSelection] Sélection =>', langCode);
    setStudyLanguage(langCode);

    try {
      const res = await fetch('/api/user/registerOrUpdateUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: currentUser.uid,
          studyLanguage: langCode,
        }),
      });
      const data = await res.json();
      console.log('[StudyLanguageSelection] Langue enregistrée =>', data);
    } catch (err) {
      console.error('[StudyLanguageSelection] Erreur =>', err);
    }

    onLanguageSelect();
  };

  return (
    <Layout noMaxWidth>
      <section
        className="
          min-h-screen
          flex
          flex-col
          items-center
          justify-start
          px-4
          pt-10        /* Sur mobile : padding-top plus petit (2.5rem) */
          md:pt-40      /* Sur écrans >=768px : padding-top plus grand (10rem) */
          pb-24
        "
      >
        <h1
          className="
            text-[2rem]
            sm:text-[2.4rem]
            md:text-[3rem]
            font-extrabold
            text-[#243238]
            mb-10        /* Sur mobile : marge en bas plus petite (2.5rem) */
            md:mb-20     /* Sur écrans >=768px : marge en bas plus grande (5rem) */
            leading-tight
            text-center
          "
        >
          {tCommon('studyLanguageSelection.title')}
        </h1>

        <div
          className="
            w-full
            max-w-screen-xl
            mx-auto
            grid
            grid-cols-1       /* 1 colonne en base */
            sm:grid-cols-2    /* 2 colonnes >=640px */
            md:grid-cols-3    /* 3 colonnes >=768px */
            lg:grid-cols-4    /* 4 colonnes >=1024px */
            gap-x-8           /* écart horizontal fixe */
            gap-y-10          /* écart vertical */
            mb-6
            justify-items-center
          "
        >
          {LANGUAGES_DATA.map((lang) => {
            const isSelected = lang.code === selectedLanguage;
            return (
              <button
                key={lang.code}
                onClick={() => handleCardClick(lang.code)}
                className={`
                  w-[276px]
                  h-[276px]
                  flex flex-col
                  items-center
                  justify-center
                  border
                  ${
                    isSelected ? 'border-blue-400' : 'border-gray-300'
                  }
                  bg-white
                  rounded-xl
                  shadow-sm
                  hover:shadow-md
                  hover:border-blue-400
                  transition-all
                  cursor-pointer
                `}
              >
                <div
                  className="
                    w-[138px]
                    h-[102px]
                    border border-gray-300
                    rounded-xl
                    overflow-hidden
                    flex items-center justify-center
                  "
                >
                  <FlagIcon
                    countryCode={lang.countryCode}
                    size={138}
                  />
                </div>

                <div
                  className="
                    mt-4
                    text-lg
                    font-bold
                    uppercase
                    text-gray-800
                    tracking-wide
                  "
                >
                  {tCommon(lang.nameKey)}
                </div>

                {/* Remplacement du mot “learners” via .replace(...) */}
                <div
                  className="
                    text-lg
                    font-normal
                    text-gray-700
                    mt-1
                  "
                >
                  {
                    lang.learnersCountLabel.replace(
                      'learners',
                      tCommon('studyLanguageSelection.learnersLabel')
                    )
                  }
                </div>
              </button>
            );
          })}
        </div>

        {/* Le bouton de confirmation est désormais SUPPRIMÉ */}
      </section>
    </Layout>
  );
};

export default StudyLanguageSelection;