//src/pages/ConsolePage/useStreak.ts

import { useCallback, useState } from 'react';
import { useUser } from '../../../context/UserContext';

export function useStreak() {
  const { userData, refreshUserData } = useUser();
  const [showStreakAnimation, setShowStreakAnimation] = useState(false);
  const [showStreakSuccessMessage, setShowStreakSuccessMessage] = useState(false);

  const triggerStreakAnimation = useCallback(() => {
    setShowStreakAnimation(true);
    setTimeout(() => setShowStreakAnimation(false), 3000);
  }, []);

  // Appelée quand on soupçonne que la streak peut augmenter
  const checkAndTriggerStreakIncrease = useCallback(
    async (oldStreak: number) => {
      await refreshUserData();
      const newStreak = userData?.dailyStreak || 0;
      if (newStreak > oldStreak) {
        triggerStreakAnimation();
        setShowStreakSuccessMessage(true);
        setTimeout(() => setShowStreakSuccessMessage(false), 5000);
      }
    },
    [userData?.dailyStreak, refreshUserData, triggerStreakAnimation]
  );

  return {
    showStreakAnimation,
    showStreakSuccessMessage,
    checkAndTriggerStreakIncrease,
  };
}