// ------------------------------------------------------------
// FICHIER : src/App.tsx (Version unifiée avec /login hors :lang)
// ------------------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import i18n from './i18n';
import './App.scss';
import { getApiUrl } from './config/environment';

// -- Pages marketing --
import AboutPage from './pages/Marketing/AboutPage';
import HowItWorksPage from './pages/Marketing/HowItWorksPage';
import ContactPage from './pages/Marketing/ContactPage';
import PricingPage from './pages/Marketing/PricingPage';

// -- Pages de l'app --
import { LandingPage } from './pages/LandingPage';
import { StudyLanguageSelection } from './pages/StudyLanguageSelection';
import { StudyLevelSelection } from './pages/StudyLevelSelection';
import { ConversationScenarioSelection } from './pages/ConversationScenarioSelection/ConversationScenarioSelection';
import { ConsolePage } from './pages/ConsolePage';
import { SettingsPage } from './pages/SettingsPage';
import SuccessPage from './pages/SuccessPage';

// -- (IMPORTANT) Page de login (décommentée)
import LoginPage from './pages/LoginPage';

// -- Contexts et logique --
import { useStudy } from './context/StudyContext';
import { useUser } from './context/UserContext';
import { getAuth } from 'firebase/auth';

/**
 * useUserLang :
 *   Renvoie "fr", "en", etc.
 *   On suppose que LanguageContext expose { displayLanguage, nativeLanguageSelected }.
 */
function useUserLang() {
  // on importe dynamiquement le context
  const languageContext = require('./context/LanguageContext');
  const { displayLanguage, nativeLanguageSelected } = languageContext.useLanguage();
  return nativeLanguageSelected || displayLanguage || 'en';
}

/**
 * AutoLangRedirect :
 *   - Monté sur "/"
 *   - Redirige vers "/fr", "/en", etc. selon la langue détectée
 */
function AutoLangRedirect() {
  const navigate = useNavigate();
  const userLang = useUserLang();

  useEffect(() => {
    navigate(`/${userLang}`, { replace: true });
  }, [userLang, navigate]);

  return null;
}

/**
 * LanglessRedirect :
 *   - Permet de rediriger "/contact" => "/fr/contact"
 *   - Propriété "to" = "contact", "about", "pricing", etc.
 */
function LanglessRedirect({ to }: { to: string }) {
  const navigate = useNavigate();
  const userLang = useUserLang();

  useEffect(() => {
    // Ex: "/contact" => "/fr/contact"
    navigate(`/${userLang}/${to}`, { replace: true });
  }, [userLang, to, navigate]);

  return null;
}

/**
 * InnerApp :
 *   - Monté sur "/:lang/*"
 *   - Applique i18n.changeLanguage(:lang)
 *   - Gère la logique app (landing, scenario, console)
 *   - Gère aussi les pages marketing paramétrées "/:lang/about", etc.
 */
function InnerApp() {
  // -----------------------
  // 1) États et context
  // -----------------------
  const [isStarted, setIsStarted] = useState(false);
  const [currentStep, setCurrentStep] = useState<
    'landing' | 'language' | 'level' | 'scenario'
  >('landing');

  const [isAuthChecking, setIsAuthChecking] = useState(true);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [isAppReady, setIsAppReady] = useState(false);

  const { studyLanguage, setStudyLanguage, studyLevel, setStudyLevel } = useStudy();
  const { refreshUserData } = useUser();

  // Récupération du paramètre :lang dans l'URL
  const { lang } = useParams();
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;
      console.log('[InnerApp] => i18n.changeLanguage:', lang);
    }
  }, [lang]);

  // On récupère la route courante
  const location = useLocation();

  // -----------------------
  // 2) fetchUserLanguageInfo
  // -----------------------
  const fetchUserLanguageInfo = async (uid: string) => {
    try {
      console.log('[InnerApp] 🔍 Récupération infos langues:', {
        userId: uid,
        timestamp: new Date().toISOString(),
      });

      const apiUrl = getApiUrl();
      const response = await fetch(`${apiUrl}/api/user/getUserProfileInfo?uid=${uid}`);
      const data = await response.json();

      console.log('[InnerApp] ✅ Infos langues reçues:', {
        data,
        isNewUser: !data.nativeLanguageSelected,
        timestamp: new Date().toISOString(),
      });

      return {
        ...data,
        isNewUser: !data.nativeLanguageSelected,
      };
    } catch (error) {
      console.error('[InnerApp] ❌ Erreur récupération infos langues:', error);
      return { isNewUser: true };
    }
  };

  // -----------------------
  // 3) Navigation “app" steps
  // -----------------------
  const handleGetStarted = () => {
    console.log('[InnerApp] 🔄 Transition: landing -> language');
    setCurrentStep('language');
  };

  const handleStudyLanguageSelect = () => {
    console.log('[InnerApp] 🔄 Transition: language -> level');
    setCurrentStep('level');
  };

  const handleStudyLevelSelect = () => {
    console.log('[InnerApp] 🔄 Transition: level -> scenario');
    setCurrentStep('scenario');
  };

  const handleScenarioSelect = () => {
    console.log('[InnerApp] 🔄 Transition: scenario -> console');
    setIsStarted(true);
  };

  // Callback pour quitter la console et revenir à la sélection
  const handleExitConsole = useCallback(() => {
    setIsStarted(false);
    setCurrentStep('scenario');
  }, []);

  // Pour rendre la page correspondante selon le currentStep
  const renderStep = () => {
    switch (currentStep) {
      case 'landing':
        return <LandingPage onGetStarted={handleGetStarted} />;
      case 'language':
        return <StudyLanguageSelection onLanguageSelect={handleStudyLanguageSelect} />;
      case 'level':
        return <StudyLevelSelection onLevelSelect={handleStudyLevelSelect} />;
      case 'scenario':
        return <ConversationScenarioSelection onScenarioSelect={handleScenarioSelect} />;
      default:
        return <ConsolePage onExit={handleExitConsole} />;
    }
  };

  // -----------------------
  // 4) Initialisation
  // -----------------------
  useEffect(() => {
    const initializeApp = async () => {
      try {
        console.log('[InnerApp] 🚀 Démarrage application');
        const auth = getAuth();

        // Surveille l'état d'authentification Firebase
        auth.onAuthStateChanged(async (user) => {
          console.log('[InnerApp] 👤 État authentification:', {
            isAuthenticated: !!user,
            uid: user?.uid,
          });

          // (A) On check si la route courante est une “page marketing”
          const path = location.pathname.toLowerCase();
          const isMarketing = [
            '/about',
            '/contact',
            '/pricing',
            '/how-it-works',
            // '/login',    // si on veut exclure la page de login
          ].some((key) => path.includes(key));

          if (isMarketing) {
            // On ne force pas “landing” ou “scenario” => on arrête juste le checking
            setIsAuthChecking(false);
            setIsUserDataLoading(false);
            return;
          }

          // (B) Sinon => logique habituelle
          if (user) {
            setIsUserDataLoading(true);
            await refreshUserData();

            const languageInfo = await fetchUserLanguageInfo(user.uid);

            if (!languageInfo.isNewUser && languageInfo.nativeLanguageSelected) {
              // Il a déjà choisi sa langue maternelle
              if (languageInfo.lastSelectedStudyLanguage) {
                setStudyLanguage(languageInfo.lastSelectedStudyLanguage);

                if (
                  languageInfo.lastSelectedStudyLevel !== undefined &&
                  languageInfo.lastSelectedStudyLevel !== null
                ) {
                  // Déjà un level => on va direct à la page scenario
                  setCurrentStep('scenario');
                } else {
                  // On va à la page level
                  setCurrentStep('level');
                }
              } else {
                // On va à la page “study language”
                setCurrentStep('language');
              }
            } else {
              // user n’a pas de langue maternelle => “landing”
              setCurrentStep('landing');
            }
            setIsUserDataLoading(false);
          } else {
            // Pas d’utilisateur => “landing”
            setCurrentStep('landing');
            setIsUserDataLoading(false);
          }

          setIsAuthChecking(false);
        });
      } catch (error) {
        console.error('[InnerApp] ❌ Erreur initialisation:', error);
        setIsAuthChecking(false);
        setIsUserDataLoading(false);
      }
    };

    initializeApp();
  }, [
    location.pathname,
    setStudyLanguage,
    setStudyLevel,
    refreshUserData,
  ]);

  // -----------------------
  // 5) Gère l’état “appReady”
  // -----------------------
  useEffect(() => {
    if (!isAuthChecking && !isUserDataLoading) {
      setIsAppReady(true);
    }
  }, [isAuthChecking, isUserDataLoading]);

  // Log quand on change studyLanguage ou studyLevel
  useEffect(() => {
    if (studyLanguage && studyLevel !== null) {
      console.log('[InnerApp] 🔄 Mise à jour (studyLanguage, studyLevel) =>', {
        studyLanguage,
        studyLevel,
      });
    }
  }, [studyLanguage, studyLevel]);

  // Écran de chargement si pas prêt
  if (!isAppReady) {
    return (
      <div className="flex items-center justify-center h-full bg-transparent">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <p className="text-gray-600">
            {isAuthChecking
              ? 'Checking authentication...'
              : isUserDataLoading
              ? 'Loading your preferences...'
              : 'Preparing the application...'}
          </p>
        </div>
      </div>
    );
  }

  // -----------------------
  // 6) Routes internes "/:lang/*"
  // -----------------------
  return (
    <Routes>
      {/* (1) Pages marketing => "/:lang/about", etc. */}
      <Route path="about" element={<AboutPage />} />
      <Route path="how-it-works" element={<HowItWorksPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="pricing" element={<PricingPage />} />

      {/* (2) Page principale (landing -> scenario -> console) */}
      <Route
        path=""
        element={!isStarted ? renderStep() : <ConsolePage onExit={handleExitConsole} />}
      />

      {/* (3) /:lang/settings, /:lang/success, etc. */}
      <Route path="settings" element={<SettingsPage />} />
      <Route path="success" element={<SuccessPage />} />

      {/*
        (4) On pourrait insérer la page de login paramétrée => "/:lang/login"
        <Route path="login" element={<LoginPage />} />
      */}

      {/* (5) 404 interne */}
      <Route
        path="*"
        element={
          <div className="p-4 text-center">
            <h1 className="text-2xl font-bold">404 - Not Found (InnerApp)</h1>
            <p>Oops, this page does not exist.</p>
          </div>
        }
      />
    </Routes>
  );
}

/**
 * App :
 *   - Gère le router global
 *   - Redirection "/" => "/fr"
 *   - Redirection SANS langue => "/:lang" via <LanglessRedirect>
 *   - /:lang/* => <InnerApp>
 *   - Ajout de /login (hors :lang)
 *   - 404
 */
export default function App() {
  // 1) hreflang multilingue
  useEffect(() => {
    const baseUrl = getApiUrl();
    const languages = [
      'en', // Anglais
      'fr', // Français
      'es', // Espagnol
      'de', // Allemand
      'it', // Italien
      'ja', // Japonais
      'ko', // Coréen
      'pt', // Portugais
      'zh', // Chinois (mandarin)
      'ru', // Russe
      'ar', // Arabe
      'hi', // Hindi
      'nl', // Néerlandais
      'pl', // Polonais
      'tr', // Turc
      'id', // Indonésien
      'ur', // Ourdou
    ];

    // Retire d’abord tous les <link rel="alternate">
    document.querySelectorAll('link[rel="alternate"]').forEach((link) => link.remove());

    // Ajoute un <link rel="alternate" ...> pour chaque langue
    languages.forEach((lang) => {
      const link = document.createElement('link');
      link.rel = 'alternate';
      link.hreflang = lang;
      link.href = `${baseUrl}/${lang}`;
      document.head.appendChild(link);
    });

    // x-default
    const defaultLink = document.createElement('link');
    defaultLink.rel = 'alternate';
    defaultLink.hreflang = 'x-default';
    defaultLink.href = baseUrl;
    document.head.appendChild(defaultLink);
  }, []);

  return (
    <Router>
      {/* Background global */}
      <div
        className="fixed top-0 left-0 w-full h-full"
        style={{
          backgroundImage: 'url("/background-for-all-website.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1,
        }}
      />
      <div className="relative min-h-screen flex flex-col items-center justify-center">
        <Routes>
          {/* A) "/" => AutoLangRedirect */}
          <Route path="/" element={<AutoLangRedirect />} />

          {/* B) Pages marketing/app sans :lang => redirection ex. "/contact" => "/fr/contact" */}
          <Route path="/about" element={<LanglessRedirect to="about" />} />
          <Route path="/how-it-works" element={<LanglessRedirect to="how-it-works" />} />
          <Route path="/contact" element={<LanglessRedirect to="contact" />} />
          <Route path="/pricing" element={<LanglessRedirect to="pricing" />} />
          <Route path="/settings" element={<LanglessRedirect to="settings" />} />
          <Route path="/success" element={<LanglessRedirect to="success" />} />

          {/* C) NOUVEAU => Route /login hors paramètre :lang */}
          <Route path="/login" element={<LoginPage />} />

          {/* D) "/:lang/*" => InnerApp */}
          <Route path="/:lang/*" element={<InnerApp />} />

          {/* E) 404 globale */}
          <Route
            path="*"
            element={
              <div className="p-4 text-center">
                <h1 className="text-2xl font-bold">404 - Not Found</h1>
                <p>Oops, this page does not exist.</p>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}