// src/components/LanguageSelector.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FlagIcon from './FlagIcon';

/**
 * Représente une langue d’étude :
 *  - code         : code i18n (ex: 'en', 'fr', 'es')
 *  - countryCode  : code pays ISO (ex: 'us', 'fr', 'es')
 */
interface StudyLanguage {
  code: string;
  countryCode: string;
}

/**
 * Props :
 *  - selectedLanguage : code de la langue actuellement sélectionnée (ex: 'en')
 *  - onLanguageSelect : callback quand l’utilisateur en choisit une autre
 *  - label?           : label affiché au-dessus du sélecteur
 */
interface LanguageSelectorProps {
  selectedLanguage: string;
  onLanguageSelect: (languageCode: string) => void;
  label?: string;
}

const LANGUAGE_SELECTOR_ID = 'language-selector-button';

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  selectedLanguage,
  onLanguageSelect,
  label,
}) => {
  // 1) Utilise le namespace "languages"
  const { t } = useTranslation('languages');
  const [isOpen, setIsOpen] = useState(false);

  // 2) Liste des langues disponibles
  const studyLanguages: StudyLanguage[] = [
    { code: 'en', countryCode: 'us' },
    { code: 'es', countryCode: 'es' },
    { code: 'fr', countryCode: 'fr' },
    { code: 'de', countryCode: 'de' },
    { code: 'it', countryCode: 'it' },
    { code: 'pt', countryCode: 'pt' },
    { code: 'nl', countryCode: 'nl' },
    { code: 'zh', countryCode: 'cn' },
    { code: 'ar', countryCode: 'sa' },
    { code: 'hi', countryCode: 'in' },
    { code: 'ru', countryCode: 'ru' },
    { code: 'ko', countryCode: 'kr' },
    { code: 'id', countryCode: 'id' },
    { code: 'tr', countryCode: 'tr' },
    { code: 'pl', countryCode: 'pl' },
    // Ajouts
    { code: 'ja', countryCode: 'jp' }, // Japonais
    { code: 'ur', countryCode: 'pk' }, // Ourdou
  ];

  // 3) Trouve l'objet correspondant à selectedLanguage
  const foundLang = studyLanguages.find((lang) => lang.code === selectedLanguage);
  const currentLang = foundLang || studyLanguages[0];

  // 4) Fermer le menu si on clique hors du bouton
  useEffect(() => {
    const handleWindowClick = (event: MouseEvent) => {
      const target = (event.target as HTMLElement).closest('button');
      if (target && (target as HTMLElement).id === LANGUAGE_SELECTOR_ID) {
        return; // clic sur le bouton => ne pas fermer
      }
      setIsOpen(false);
    };
    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  // 5) Au clic sur une langue => callback parent + fermer menu
  const handleLanguageChange = (lang: StudyLanguage) => {
    onLanguageSelect(lang.code);
    setIsOpen(false);
  };

  return (
    <div className="w-full flex flex-col items-center text-center">
      {/* Label */}
      {label && (
        <div
          className="
            text-[1.3rem]
            sm:text-[1.4rem]
            md:text-[1.5rem]
            font-semibold
            text-gray-700
            mb-3
            text-center
          "
        >
          {label}
        </div>
      )}

      {/* Conteneur du bouton/menu (centré) */}
      <div className="relative inline-block mx-auto text-left">
        <button
          type="button"
          id={LANGUAGE_SELECTOR_ID}
          aria-expanded={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          className={`
            inline-flex items-center justify-center
            rounded-lg
            border border-gray-300
            bg-white
            px-8 py-4
            font-semibold
            text-[1.3rem]
            md:text-[1.5rem]
            uppercase
            tracking-wide
            text-gray-800
            hover:bg-gray-50
            shadow-md
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            transition-colors
          `}
        >
          <FlagIcon countryCode={currentLang.countryCode} />
          {/* 6) On appelle t() avec la clé = code de la langue */}
          <span className="ml-3">{t(currentLang.code)}</span>

          <svg
            className="ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10.293 14.707a1 1 0 01-1.414 0l-4-4
                 a1 1 0 011.414-1.414L10 12.586l3.293-3.293
                 a1 1 0 011.414 1.414l-4 4z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        {/* Menu dropdown */}
        {isOpen && (
          <div
            className="
              absolute
              right-0
              mt-2
              w-64
              origin-top-right
              rounded-lg
              bg-white
              shadow-lg
              ring-1 ring-black ring-opacity-5
              z-50
            "
            role="menu"
            aria-orientation="vertical"
            aria-labelledby={LANGUAGE_SELECTOR_ID}
          >
            <div className="py-2">
              {studyLanguages.map((lang) => {
                const isCurrent = lang.code === currentLang.code;
                return (
                  <button
                    key={lang.code}
                    onClick={() => handleLanguageChange(lang)}
                    className={`
                      w-full text-left px-4 py-2
                      flex items-center
                      hover:bg-gray-100 hover:text-gray-900
                      transition-colors
                      ${isCurrent ? 'bg-gray-100' : ''}
                    `}
                  >
                    <FlagIcon countryCode={lang.countryCode} />
                    <span className="ml-2 text-[1.2rem] font-semibold uppercase tracking-wide">
                      {t(lang.code)}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;