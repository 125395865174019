/* -----------------------------------------------------------------------
 * src/pages/ConsolePage/useConsolePageLogic.ts
 * ----------------------------------------------------------------------- */

import { useEffect, useState, useCallback, useMemo } from 'react';

// On monte 3 fois ../ pour atteindre /src, puis on descend
import { useAuth } from '../../../context/AuthContext';
import { useLanguage } from '../../../context/LanguageContext';
import { useStudy } from '../../../context/StudyContext';
import { useUser } from '../../../context/UserContext';
import { useTotalMessageCount } from '../../../context/TotalMessageCountContext';

import { MESSAGE_LIMITS, SPECIAL_USERS } from '../../../config/limits';
// On va désormais passer un 5e argument (userFirstName) à generateInstructions
import { generateInstructions } from '../../../utils/conversation_config';

/** Hooks spécialisés existants (dans le même dossier `logic/`) */
import { useStreak } from './useStreak';
import { useScenarioCompletion } from './useScenarioCompletion';
import { useTranslateMessage } from './useTranslateMessage';
import { useConsoleMessages } from './useConsoleMessages';

/**
 * useConsolePageLogic :
 *  - Gère la logique de conversation (messages, correction, traduction)
 *  - Gère la streak quotidienne
 *  - Gère la complétion de scénario
 *  - Gère l’affichage du formulaire si user anonyme dépasse `ANONYMOUS_USER_PROMPT_LIMIT`
 *  - Gère l’affichage de l’offre d’abonnement (`isLimitReached`), mais SANS gérer 50 côté front.
 */
export function useConsolePageLogic() {
  // -------------------------------------------------------------------------
  // 1) Récupération des contextes et données
  // -------------------------------------------------------------------------
  const { currentUser } = useAuth();
  const { nativeLanguageSelected } = useLanguage();
  const { studyLanguage, studyLevel, selectedScenario } = useStudy();
  const { totalMessageCount, refreshTotalMessageCount } = useTotalMessageCount();
  const { userData, refreshUserData } = useUser();

  // Récupérer éventuellement le prénom
  const userFirstName = userData?.firstName || null;

  // -------------------------------------------------------------------------
  // 2) Hooks “Streak” et “Scenario” (déjà existants)
  // -------------------------------------------------------------------------
  const {
    showStreakAnimation,
    showStreakSuccessMessage,
    checkAndTriggerStreakIncrease,
  } = useStreak();

  const {
    showScenarioSuccessMessage,
    scenarioAlreadyCompleted,
    error: scenarioError,
    markScenarioAsCompleted,
    setError: setScenarioError,
  } = useScenarioCompletion(checkAndTriggerStreakIncrease);

  // -------------------------------------------------------------------------
  // 3) Calculer si l'utilisateur est anonyme
  // -------------------------------------------------------------------------
  const isAnonymousUser = userData?.isAnonymous ?? currentUser?.isAnonymous ?? true;

  // -------------------------------------------------------------------------
  // 4) Générer le "prompt system" (instructions dynamiques)
  //    => On passe désormais userFirstName en paramètre
  // -------------------------------------------------------------------------
  const dynamicInstructions = useMemo(() => {
    return generateInstructions(
      nativeLanguageSelected,
      studyLanguage,
      studyLevel,
      selectedScenario,
      userFirstName
    );
  }, [
    nativeLanguageSelected,
    studyLanguage,
    studyLevel,
    selectedScenario,
    userFirstName,
  ]);

  // -------------------------------------------------------------------------
  // 5) Surveiller totalMessageCount + userData => et gérer isLimitReached local
  //    (uniquement pour afficher la pop-up “PaywallOffer”).
  //    => On NE compare plus ici “50” => c’est le BACK qui gère la limite.
  // -------------------------------------------------------------------------
  const [isLimitReached, setIsLimitReached] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    // (A) Cas user “UNLIMITED” => aucune limite
    if (SPECIAL_USERS.UNLIMITED_ACCESS.includes(currentUser.uid)) {
      setIsLimitReached(false);
      return;
    }

    // (B) Si user abonné => pas de limite
    const subscriptionEndDate = userData?.subscriptionEndDate
      ? new Date(userData.subscriptionEndDate)
      : null;
    if (subscriptionEndDate && subscriptionEndDate > new Date()) {
      setIsLimitReached(false);
      return;
    }

    // (C) Sinon => c’est le backend qui renverra 403 quand la limite free est dépassée
    // => On réagit alors (voir useConsoleMessages).
  }, [currentUser, totalMessageCount, userData]);

  // -------------------------------------------------------------------------
  // 5-bis) checkIfLimitReached => vérification au clic micro, UNIQUEMENT
  //    pour l’utilisateur anonyme => compare totalMessageCount >= ANONYMOUS_USER_PROMPT_LIMIT
  //    => si on veut lui forcer l’inscription tout de suite.
  // -------------------------------------------------------------------------
  const [showRegisterEmailForm, setShowRegisterEmailForm] = useState(false);

  const checkIfLimitReached = useCallback((): boolean => {
    if (isAnonymousUser) {
      if (totalMessageCount >= MESSAGE_LIMITS.ANONYMOUS_USER_PROMPT_LIMIT) {
        setShowRegisterEmailForm(true);
        return true;
      }
    }
    return false;
  }, [isAnonymousUser, totalMessageCount]);

  // -------------------------------------------------------------------------
  // 6) handleSignUpSuccess => quand user finit le signUp
  // -------------------------------------------------------------------------
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSignUpSuccess = useCallback(() => {
    setShowRegisterEmailForm(false);
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 5000);
  }, []);

  // -------------------------------------------------------------------------
  // 7) useConsoleMessages => gère les items (messages), sessionMessageCount...
  //    + appelle setIsLimitReached(true) si le backend renvoie 403
  // -------------------------------------------------------------------------
  const {
    items,
    setItems,
    sessionMessageCount,
    handleRealtimeEvent,
  } = useConsoleMessages({
    currentUser,
    userData,
    studyLanguage,
    selectedScenario,
    refreshTotalMessageCount,
    refreshUserData,
    checkAndTriggerStreakIncrease,

    onLimitReached: () => {
      setIsLimitReached(true);
    },
  });

  // -------------------------------------------------------------------------
  // 8) Compléter la traduction => useTranslateMessage
  // -------------------------------------------------------------------------
  const [error, setError] = useState<string | null>(null);

  const { handleTranslate: handleTranslateFinal } = useTranslateMessage(
    items,
    setItems,
    (errMsg) => setError(errMsg)
  );

  // -------------------------------------------------------------------------
  // 9) Surveiller scenarioError et fusionner avec error global
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (scenarioError) {
      setError(scenarioError);
      setScenarioError(null);
    }
  }, [scenarioError, setScenarioError]);

  // -------------------------------------------------------------------------
  // 10) handleConnectingChange => indicateur de connexion en cours
  // -------------------------------------------------------------------------
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnectingChange = useCallback((connecting: boolean) => {
    setIsConnecting(connecting);
  }, []);

  // -------------------------------------------------------------------------
  // 11) Compléter scénario si on atteint MESSAGES_REQUIRED_TO_COMPLETE_SCENARIO
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (sessionMessageCount >= MESSAGE_LIMITS.MESSAGES_REQUIRED_TO_COMPLETE_SCENARIO) {
      markScenarioAsCompleted();
    }
  }, [sessionMessageCount, markScenarioAsCompleted]);

  // -------------------------------------------------------------------------
  // 12) On retourne tout => accessible dans ConsolePage
  // -------------------------------------------------------------------------
  return {
    // (A) Données conversation
    items,
    sessionMessageCount,

    // (B) UI states
    showRegisterEmailForm,
    showSuccessMessage,
    showScenarioSuccessMessage,
    scenarioAlreadyCompleted,
    isLimitReached,
    setIsLimitReached,
    error,
    isConnecting,

    // (C) Méthodes principales
    handleSignUpSuccess,
    handleTranslate: handleTranslateFinal,
    handleRealtimeEvent,
    handleConnectingChange,
    setShowRegisterEmailForm,

    // (D) GPT instructions (inclut userFirstName s'il existe)
    dynamicInstructions,

    // (E) Streak
    showStreakAnimation,
    showStreakSuccessMessage,

    // (F) Contrôle du micro (limites) => UNIQUEMENT pour anonymes
    checkIfLimitReached,
  };
}