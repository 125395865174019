// src/context/LanguageContext.tsx

import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from '../i18n';

interface LanguageContextType {
  browserLanguage: string;
  displayLanguage: string;
  setDisplayLanguage: (language: string) => void;
  nativeLanguageSelected: string | null;
  setNativeLanguageSelected: (language: string) => void;
}

// (NOUVEAU LOG) - Détermination de la langue du navigateur (initial)
console.log('[LanguageContext] (NOUVEAU LOG) Détection initiale de browserLanguage =>', {
  fromI18n: i18n.language,
  fromNavigator: navigator.language,
});

const browserLanguage = i18n.language || navigator.language || 'en';

// (NOUVEAU LOG) - Juste après avoir défini browserLanguage
console.log('[LanguageContext] (NOUVEAU LOG) Valeur finale de browserLanguage =>', browserLanguage);

const LanguageContext = createContext<LanguageContextType>({
  browserLanguage,
  displayLanguage: browserLanguage,
  setDisplayLanguage: () => {},
  nativeLanguageSelected: null,
  setNativeLanguageSelected: () => {},
});

// (NOUVEAU LOG) - Création du provider
console.log('[LanguageContext] (NOUVEAU LOG) Création du LanguageProvider');

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Langue d'affichage initialisée avec la langue du navigateur ou une langue sauvegardée
  const savedDisplayLanguage =
    localStorage.getItem('displayLanguage') || browserLanguage;

  // (NOUVEAU LOG) - Affichage de la valeur initiale de displayLanguage
  console.log('[LanguageContext] (NOUVEAU LOG) savedDisplayLanguage =>', savedDisplayLanguage);

  const [displayLanguage, setDisplayLanguage] = useState<string>(
    savedDisplayLanguage
  );

  // Langue maternelle sélectionnée par l'utilisateur
  const savedNativeLanguage = localStorage.getItem('nativeLanguageSelected');
  // (NOUVEAU LOG) - Affichage de la valeur initiale de nativeLanguageSelected
  console.log('[LanguageContext] (NOUVEAU LOG) savedNativeLanguage =>', savedNativeLanguage);

  const [nativeLanguageSelected, setNativeLanguageSelected] = useState<
    string | null
  >(savedNativeLanguage || null);

  // Mettre à jour la langue d'affichage lorsqu'elle change
  useEffect(() => {
    // (NOUVEAU LOG) - Début du useEffect pour displayLanguage
    console.log('[LanguageContext] (NOUVEAU LOG) useEffect [displayLanguage] => changement détecté', {
      newDisplayLanguage: displayLanguage,
    });

    i18n.changeLanguage(displayLanguage);
    document.documentElement.lang = displayLanguage;
    localStorage.setItem('displayLanguage', displayLanguage);

    console.log(
      '[LanguageContext] 🌐 Langue d\'affichage mise à jour :',
      displayLanguage
    );
  }, [displayLanguage]);

  // Mettre à jour la langue maternelle sélectionnée
  useEffect(() => {
    // (NOUVEAU LOG) - Début du useEffect pour nativeLanguageSelected
    console.log('[LanguageContext] (NOUVEAU LOG) useEffect [nativeLanguageSelected] => changement détecté', {
      newNativeLanguage: nativeLanguageSelected,
    });

    if (nativeLanguageSelected) {
      localStorage.setItem('nativeLanguageSelected', nativeLanguageSelected);
      console.log(
        '[LanguageContext] 🏠 Langue maternelle sélectionnée :',
        nativeLanguageSelected
      );

      // Mettre à jour la langue d'affichage pour correspondre à la langue maternelle
      setDisplayLanguage(nativeLanguageSelected);
      console.log(
        '[LanguageContext] 🌐 Langue d\'affichage mise à jour pour correspondre à la langue maternelle'
      );
    } else {
      localStorage.removeItem('nativeLanguageSelected');
      console.log('[LanguageContext] (NOUVEAU LOG) nativeLanguageSelected était nul => Suppression dans localStorage');
    }
  }, [nativeLanguageSelected]);

  // (NOUVEAU LOG) - Juste avant de retourner le provider
  console.log('[LanguageContext] (NOUVEAU LOG) Juste avant return =>', {
    finalDisplayLanguage: displayLanguage,
    finalNativeLanguageSelected: nativeLanguageSelected,
  });

  return (
    <LanguageContext.Provider
      value={{
        browserLanguage,
        displayLanguage,
        setDisplayLanguage,
        nativeLanguageSelected,
        setNativeLanguageSelected,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);