// src/pages/PaywallOffer.tsx

import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import * as Sentry from '@sentry/react';
import { getApiUrl } from '../config/environment';

// Initialiser Stripe avec la clé publique
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
  {
    apiVersion: '2023-10-16',
  }
);

const PaywallOffer = () => {
  // On cible le namespace "paywall"
  const { t: tPaywall } = useTranslation('paywall');
  const { currentUser } = useAuth();
  const apiUrl = getApiUrl();

  const handleSubscribe = async () => {
    try {
      if (!currentUser) {
        console.error('Utilisateur non connecté');
        return;
      }

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe n’a pas pu être initialisé');
      }

      // Créer la session de paiement via votre API
      const response = await fetch(`${apiUrl}/api/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: currentUser.uid,
          email: currentUser.email,
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création de la session de paiement');
      }

      const session = await response.json();

      // Rediriger vers Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          location: 'PaywallOffer - handleSubscribe',
          userId: currentUser?.uid,
        },
      });
      console.error('Erreur lors de la redirection vers Stripe :', error);
    }
  };

  return (
    <div className="bg-gray-50 p-4">
      {/* Le conteneur principal (façon carte) */}
      <div
        className="
          w-full
          max-w-md
          mx-auto
          bg-white
          rounded-md
          shadow-md
          px-4
          py-6
        "
        style={{ minWidth: '280px' }}
      >
        {/* Message statique comme limitReached */}
        <p className="text-lg text-red-600 mb-6 text-center font-bold">
          {tPaywall('limitReached')}
        </p>

        <p className="text-lg font-semibold text-red-600 mb-4 text-center">
          {tPaywall('specialOffer')}
        </p>

        <p className="mb-4 text-center">
          {tPaywall('benefitsIntro')}
        </p>

        <ul className="list-disc list-inside mb-4 space-y-2 text-left">
          <li>{tPaywall('benefits.unlimited')}</li>
          <li>{tPaywall('benefits.instant')}</li>
          <li>{tPaywall('benefits.custom')}</li>
          <li>{tPaywall('benefits.smart')}</li>
          <li>{tPaywall('benefits.more')}</li>
        </ul>

        <p className="mb-4 font-bold text-lg text-center">
          {tPaywall('price')}
        </p>

        <p className="text-sm text-gray-600 mb-6 text-center">
          {tPaywall('promo')}
        </p>

        <button
          onClick={handleSubscribe}
          className="
            w-full
            bg-blue-500
            text-white
            px-6
            py-3
            rounded-md
            hover:bg-blue-600
            transition-colors
            duration-200
            font-medium
          "
        >
          {tPaywall('subscribeButton')}
        </button>
      </div>
    </div>
  );
};

export default PaywallOffer;