// src/hooks/useWebTTS.ts
import { useCallback, useState } from 'react';

/**
 * useWebTTS :
 *   - Gère la lecture vocale (SpeechSynthesisUtterance) si dispo
 *   - Fourni `speak()`, `cancel()`, et un booléen `speaking`
 */
export function useWebTTS(defaultLang = 'en-US') {
  const [speaking, setSpeaking] = useState(false);

  /**
   * Parle un texte, avec la langue `defaultLang` (ou `options.lang`) si dispo
   */
  const speak = useCallback(
    (text: string, options?: { lang?: string; volume?: number; rate?: number }) => {
      if (!window.speechSynthesis) {
        console.warn('Speech Synthesis non supporté par ce navigateur');
        return;
      }
      if (!text.trim()) return;

      const utter = new SpeechSynthesisUtterance(text);
      utter.lang = options?.lang || defaultLang;
      utter.volume = options?.volume ?? 1.0;
      utter.rate = options?.rate ?? 1.0;

      utter.onstart = () => setSpeaking(true);
      utter.onend = () => setSpeaking(false);
      utter.onerror = () => setSpeaking(false);

      window.speechSynthesis.speak(utter);
    },
    [defaultLang]
  );

  /**
   * Annule la lecture en cours
   */
  const cancel = useCallback(() => {
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel();
      setSpeaking(false);
    }
  }, []);

  return {
    speak,
    cancel,
    speaking,
  };
}