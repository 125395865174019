// src/components/CloseButton.tsx
import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface CloseButtonProps {
  /** Fonction appelée au clic sur le bouton */
  onClick: () => void;
  /** Label ARIA pour l'accessibilité (facultatif, par défaut = "Fermer") */
  ariaLabel?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  onClick,
  ariaLabel = 'Fermer',
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={ariaLabel}
      className="
        w-12
        h-12
        flex
        items-center
        justify-center
        text-gray-600
        hover:text-blue-500
        rounded-full
        focus:outline-none
        z-50
      "
      style={{ position: 'relative' }}
    >
      <XMarkIcon className="w-8 h-8" />
    </button>
  );
};

export default CloseButton;