// src/components/SettingsButton.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';

export const SettingsButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/settings');
  };

  return (
    <button
      onClick={handleClick}
      className="
        fixed
        top-4
        right-4
        z-50
        w-12
        h-12
        flex
        items-center
        justify-center
        text-gray-600
        hover:text-blue-500
        rounded-full
        focus:outline-none
      "
      aria-label="Paramètres"
    >
      <FiSettings size={30} />
    </button>
  );
};