// src/hooks/useRealtimeWebRTC/stopSession.ts

interface StopSessionParams {
    dataChannel: RTCDataChannel | null;
    peerConnection: React.MutableRefObject<RTCPeerConnection | null>;
    setDataChannel: (dc: RTCDataChannel | null) => void;
    setIsSessionActive: (b: boolean) => void;
    setIsRecording: (b: boolean) => void;
    localAudioContextRef: React.MutableRefObject<AudioContext | null>;
    localAnalyserRef: React.MutableRefObject<AnalyserNode | null>;
    remoteAudioContextRef: React.MutableRefObject<AudioContext | null>;
    remoteAnalyserRef: React.MutableRefObject<AnalyserNode | null>;
    setLocalAnalyser: (a: AnalyserNode | null) => void;
    setRemoteAnalyser: (a: AnalyserNode | null) => void;
    audioElement: React.MutableRefObject<HTMLAudioElement | null>;
  }
  
  export function stopSession(params: StopSessionParams) {
    const {
      dataChannel,
      peerConnection,
      setDataChannel,
      setIsSessionActive,
      setIsRecording,
      localAudioContextRef,
      localAnalyserRef,
      remoteAudioContextRef,
      remoteAnalyserRef,
      setLocalAnalyser,
      setRemoteAnalyser,
      audioElement,
    } = params;
  
    console.log("[stopSession] => closing everything");
  
    if (dataChannel) {
      dataChannel.close();
    }
    if (peerConnection.current) {
      peerConnection.current.close();
      peerConnection.current = null;
    }
    setDataChannel(null);
    setIsSessionActive(false);
    setIsRecording(false);
  
    // Fermer AudioContext local
    if (localAudioContextRef.current) {
      localAudioContextRef.current.close();
      localAudioContextRef.current = null;
      localAnalyserRef.current = null;
      setLocalAnalyser(null);
    }
    // Fermer AudioContext remote
    if (remoteAudioContextRef.current) {
      remoteAudioContextRef.current.close();
      remoteAudioContextRef.current = null;
      remoteAnalyserRef.current = null;
      setRemoteAnalyser(null);
    }
  
    // Couper <audio>
    if (audioElement.current) {
      audioElement.current.pause();
      audioElement.current.srcObject = null;
    }
  }