// -----------------------------------------------------------------------
// src/pages/ConsolePage/ConsolePageOverlays.tsx
// -----------------------------------------------------------------------
import { useTranslation } from 'react-i18next';
import RegisterEmailForm from '../RegisterEmailForm';
import PaywallOffer from '../PaywallOffer';

type ConsolePageOverlaysProps = {
  showRegisterEmailForm: boolean;
  onSignUpSuccess: () => void;
  isLimitReached: boolean;
  showStreakAnimation: boolean;
  showStreakSuccessMessage: boolean;
  showSuccessMessage: boolean;
  showScenarioSuccessMessage: boolean;
  error: string | null;
};

export function ConsolePageOverlays({
  showRegisterEmailForm,
  onSignUpSuccess,
  isLimitReached,
  showStreakAnimation,
  showStreakSuccessMessage,
  showSuccessMessage,
  showScenarioSuccessMessage,
  error,
}: ConsolePageOverlaysProps) {
  // 1) On déclare 3 useTranslation, pour 3 namespaces
  const { t: tConsole } = useTranslation('consolePage');
  const { t: tScenarios } = useTranslation('scenarios');
  const { t: tRegister } = useTranslation('registerEmailForm');

  return (
    <>
      {/* TOAST: STREAK */}
      {showStreakSuccessMessage && (
        <div
          className="
            fixed top-16 left-1/2 -translate-x-1/2
            bg-green-200 text-green-800 font-semibold
            z-50 shadow-lg
            px-4 py-3
            rounded
            max-w-xl w-auto
            text-center
            text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]
            leading-snug
          "
        >
          {/* Appel à consolePage.json => 'streakSuccessMessage' */}
          {tConsole('streakSuccessMessage')}
        </div>
      )}

      {/* TOAST: SIGNUP SUCCESS */}
      {showSuccessMessage && (
        <div
          className="
            fixed top-16 left-1/2 -translate-x-1/2
            bg-green-100 text-green-800 font-semibold
            z-50 shadow-lg
            px-4 py-3
            rounded
            max-w-xl w-auto
            text-center
            text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]
            leading-snug
          "
        >
          {/* Appel à registerEmailForm.json => 'successMessage' */}
          {tRegister('successMessage')}
        </div>
      )}

      {/* TOAST: SCENARIO SUCCESS */}
      {showScenarioSuccessMessage && (
        <div
          className="
            fixed top-16 left-1/2 -translate-x-1/2
            bg-blue-100 text-blue-800 font-semibold
            z-50 shadow-lg
            px-4 py-3
            rounded
            max-w-xl w-auto
            text-center
            text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]
            leading-snug
          "
        >
          {/* Appel à scenarios.json => 'completionSuccess' */}
          {tScenarios('completionSuccess')}
        </div>
      )}

      {/* TOAST: ERROR */}
      {error && (
        <div
          className="
            fixed top-16 left-1/2 -translate-x-1/2
            bg-red-100 text-red-800 font-semibold
            z-50 shadow-lg
            px-4 py-3
            rounded
            max-w-xl w-auto
            text-center
            text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]
            leading-snug
          "
        >
          {error}
        </div>
      )}

      {/* OVERLAY EMAIL FORM */}
      {showRegisterEmailForm && (
        <div className="fixed inset-0 z-50 bg-black/50 overflow-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            <div
              className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md overflow-y-auto"
              style={{ maxHeight: '90vh' }}
            >
              <RegisterEmailForm onSuccess={onSignUpSuccess} />
            </div>
          </div>
        </div>
      )}

      {/* OVERLAY PAYWALL */}
      {isLimitReached && (
        <div className="fixed inset-0 z-50 bg-black/50 overflow-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            <div
              className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg overflow-y-auto"
              style={{ maxHeight: '90vh' }}
            >
              <PaywallOffer />
            </div>
          </div>
        </div>
      )}

      {/* STREAK ANIMATION */}
      {showStreakAnimation && (
        <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
          <div className="absolute inset-0 bg-black bg-opacity-30" />
          <div className="relative p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-red-600 text-center mb-2">+1 Streak!</h2>
            <p className="text-gray-800 text-center">
              {/* Appel à consolePage.json => 'streakIncreased' */}
              {tConsole('streakIncreased')}
            </p>
          </div>
        </div>
      )}
    </>
  );
}