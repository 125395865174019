// src/pages/Marketing/HowItWorksPage.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout';

const HowItWorksPage: React.FC = () => {
  // On cible le namespace "howItWorksPage"
  const { t } = useTranslation('howItWorksPage');

  return (
    <Layout>
      {/* SECTION "HOW IT WORKS" EN MODE FULL-BLEED */}
      <section
        className={`
          relative
          w-screen left-1/2 ml-[-50vw]
          right-1/2 mr-[-50vw]
          border-t border-gray-300
          py-24
        `}
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <div className="max-w-screen-lg mx-auto px-4 text-center text-[1.65rem] leading-snug">
          {/* Titre principal */}
          <h1 className="text-3xl md:text-4xl font-bold mb-6 text-[#243238]">
            {t('title')}
            {/* ex. "How Voxanet Works" */}
          </h1>

          {/* Paragraphe d’intro */}
          <p className="text-gray-700 mb-8 max-w-[50rem] mx-auto">
            {t('intro')}
            {/* ex. "With Voxanet, you practice speaking by using your voice..." */}
          </p>

          {/* “Steps” => liste numérotée */}
          <div
            className="
              max-w-[60rem] mx-auto
              bg-white border border-gray-200 rounded-lg
              p-6 text-left
              shadow-md
            "
          >
            <ol className="list-decimal list-inside space-y-4 text-gray-800">
              <li>{t('step1')}</li>
              <li>{t('step2')}</li>
              <li>{t('step3')}</li>
              <li>{t('step4')}</li>
            </ol>
          </div>

          {/* Lien / CTA vers la home */}
          <div className="mt-12">
            <Link
              to="/"
              className={`
                inline-block
                px-8 py-4
                bg-[#3f7afd] hover:bg-[#2f69df]
                text-white font-semibold text-[1.3rem] md:text-[1.5rem]
                rounded-lg shadow-lg
                transition-transform transform hover:scale-105
                focus:outline-none focus:ring-4
                focus:ring-blue-300
              `}
            >
              {t('goToHomepage')}
              {/* ex. "Go to Homepage" */}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HowItWorksPage;