// src/components/SignalBars.tsx
import React from 'react';

interface SignalBarsProps {
  level: number; // Valeur de 0 à 4
}

export const SignalBars: React.FC<SignalBarsProps> = ({ level }) => {
  const totalBars = 4;

  // ~15% de plus : [10,16,21,26] => [12,18,24,30]
  const barHeights = [12, 18, 24, 30];

  return (
    // On peut ajouter un className="font-bold" si on veut que ce bloc hérite du style gras.
    <div className="flex items-end font-bold space-x-0.5">
      {Array.from({ length: totalBars }, (_, i) => {
        const isActive = i < level;
        return (
          <div
            key={i}
            style={{
              // 8px + ~15% ≈ 9px
              width: '9px',
              height: `${barHeights[i]}px`,
            }}
            className={`
              rounded-sm
              transition-colors
              ${isActive ? 'bg-blue-500' : 'bg-gray-300'}
            `}
          />
        );
      })}
    </div>
  );
};

export default SignalBars;