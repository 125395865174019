// src/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// --------------------------------------
// == 1) IMPORTS PAR LANGUE & NAMESPACE ==
// --------------------------------------

// ============ AR (ar) ============
import arCommon from './locales/ar/common.json';
import arLandingPage from './locales/ar/landingPage.json';
import arScenarios from './locales/ar/scenarios.json';
import arRegisterEmailForm from './locales/ar/registerEmailForm.json';
import arLogin from './locales/ar/login.json';
import arPaywall from './locales/ar/paywall.json';
import arSuccessPage from './locales/ar/successPage.json';
import arCancelPage from './locales/ar/cancelPage.json';
import arConsolePage from './locales/ar/consolePage.json';
import arRealtime from './locales/ar/realtime.json';
import arLanguages from './locales/ar/languages.json';
import arContactPage from './locales/ar/contactPage.json';
import arAboutPage from './locales/ar/aboutPage.json';
import arPricingPage from './locales/ar/pricingPage.json';
import arHowItWorksPage from './locales/ar/howItWorksPage.json';
import arIcebreaker from './locales/ar/icebreaker.json';
// Ajout du namespace settingsPage
import arSettingsPage from './locales/ar/settingsPage.json';

// ============ DE (de) ============
import deCommon from './locales/de/common.json';
import deLandingPage from './locales/de/landingPage.json';
import deScenarios from './locales/de/scenarios.json';
import deRegisterEmailForm from './locales/de/registerEmailForm.json';
import deLogin from './locales/de/login.json';
import dePaywall from './locales/de/paywall.json';
import deSuccessPage from './locales/de/successPage.json';
import deCancelPage from './locales/de/cancelPage.json';
import deConsolePage from './locales/de/consolePage.json';
import deRealtime from './locales/de/realtime.json';
import deLanguages from './locales/de/languages.json';
import deContactPage from './locales/de/contactPage.json';
import deAboutPage from './locales/de/aboutPage.json';
import dePricingPage from './locales/de/pricingPage.json';
import deHowItWorksPage from './locales/de/howItWorksPage.json';
import deIcebreaker from './locales/de/icebreaker.json';
// Ajout du namespace settingsPage
import deSettingsPage from './locales/de/settingsPage.json';

// ============ EN (en) ============
import enCommon from './locales/en/common.json';
import enLandingPage from './locales/en/landingPage.json';
import enScenarios from './locales/en/scenarios.json';
import enRegisterEmailForm from './locales/en/registerEmailForm.json';
import enLogin from './locales/en/login.json';
import enPaywall from './locales/en/paywall.json';
import enSuccessPage from './locales/en/successPage.json';
import enCancelPage from './locales/en/cancelPage.json';
import enConsolePage from './locales/en/consolePage.json';
import enRealtime from './locales/en/realtime.json';
import enLanguages from './locales/en/languages.json';
import enContactPage from './locales/en/contactPage.json';
import enAboutPage from './locales/en/aboutPage.json';
import enPricingPage from './locales/en/pricingPage.json';
import enHowItWorksPage from './locales/en/howItWorksPage.json';
import enIcebreaker from './locales/en/icebreaker.json';
// Ajout du namespace settingsPage
import enSettingsPage from './locales/en/settingsPage.json';

// ============ ES (es) ============
import esCommon from './locales/es/common.json';
import esLandingPage from './locales/es/landingPage.json';
import esScenarios from './locales/es/scenarios.json';
import esRegisterEmailForm from './locales/es/registerEmailForm.json';
import esLogin from './locales/es/login.json';
import esPaywall from './locales/es/paywall.json';
import esSuccessPage from './locales/es/successPage.json';
import esCancelPage from './locales/es/cancelPage.json';
import esConsolePage from './locales/es/consolePage.json';
import esRealtime from './locales/es/realtime.json';
import esLanguages from './locales/es/languages.json';
import esContactPage from './locales/es/contactPage.json';
import esAboutPage from './locales/es/aboutPage.json';
import esPricingPage from './locales/es/pricingPage.json';
import esHowItWorksPage from './locales/es/howItWorksPage.json';
import esIcebreaker from './locales/es/icebreaker.json';
// Ajout du namespace settingsPage
import esSettingsPage from './locales/es/settingsPage.json';

// ============ FR (fr) ============
import frCommon from './locales/fr/common.json';
import frLandingPage from './locales/fr/landingPage.json';
import frScenarios from './locales/fr/scenarios.json';
import frRegisterEmailForm from './locales/fr/registerEmailForm.json';
import frLogin from './locales/fr/login.json';
import frPaywall from './locales/fr/paywall.json';
import frSuccessPage from './locales/fr/successPage.json';
import frCancelPage from './locales/fr/cancelPage.json';
import frConsolePage from './locales/fr/consolePage.json';
import frRealtime from './locales/fr/realtime.json';
import frLanguages from './locales/fr/languages.json';
import frContactPage from './locales/fr/contactPage.json';
import frAboutPage from './locales/fr/aboutPage.json';
import frPricingPage from './locales/fr/pricingPage.json';
import frHowItWorksPage from './locales/fr/howItWorksPage.json';
import frIcebreaker from './locales/fr/icebreaker.json';
// Ajout du namespace settingsPage
import frSettingsPage from './locales/fr/settingsPage.json';

// ============ HI (hi) ============
import hiCommon from './locales/hi/common.json';
import hiLandingPage from './locales/hi/landingPage.json';
import hiScenarios from './locales/hi/scenarios.json';
import hiRegisterEmailForm from './locales/hi/registerEmailForm.json';
import hiLogin from './locales/hi/login.json';
import hiPaywall from './locales/hi/paywall.json';
import hiSuccessPage from './locales/hi/successPage.json';
import hiCancelPage from './locales/hi/cancelPage.json';
import hiConsolePage from './locales/hi/consolePage.json';
import hiRealtime from './locales/hi/realtime.json';
import hiLanguages from './locales/hi/languages.json';
import hiContactPage from './locales/hi/contactPage.json';
import hiAboutPage from './locales/hi/aboutPage.json';
import hiPricingPage from './locales/hi/pricingPage.json';
import hiHowItWorksPage from './locales/hi/howItWorksPage.json';
import hiIcebreaker from './locales/hi/icebreaker.json';
// Ajout du namespace settingsPage
import hiSettingsPage from './locales/hi/settingsPage.json';

// ============ IT (it) ============
import itCommon from './locales/it/common.json';
import itLandingPage from './locales/it/landingPage.json';
import itScenarios from './locales/it/scenarios.json';
import itRegisterEmailForm from './locales/it/registerEmailForm.json';
import itLogin from './locales/it/login.json';
import itPaywall from './locales/it/paywall.json';
import itSuccessPage from './locales/it/successPage.json';
import itCancelPage from './locales/it/cancelPage.json';
import itConsolePage from './locales/it/consolePage.json';
import itRealtime from './locales/it/realtime.json';
import itLanguages from './locales/it/languages.json';
import itContactPage from './locales/it/contactPage.json';
import itAboutPage from './locales/it/aboutPage.json';
import itPricingPage from './locales/it/pricingPage.json';
import itHowItWorksPage from './locales/it/howItWorksPage.json';
import itIcebreaker from './locales/it/icebreaker.json';
// Ajout du namespace settingsPage
import itSettingsPage from './locales/it/settingsPage.json';

// ============ JA (ja) ============
import jaCommon from './locales/ja/common.json';
import jaLandingPage from './locales/ja/landingPage.json';
import jaScenarios from './locales/ja/scenarios.json';
import jaRegisterEmailForm from './locales/ja/registerEmailForm.json';
import jaLogin from './locales/ja/login.json';
import jaPaywall from './locales/ja/paywall.json';
import jaSuccessPage from './locales/ja/successPage.json';
import jaCancelPage from './locales/ja/cancelPage.json';
import jaConsolePage from './locales/ja/consolePage.json';
import jaRealtime from './locales/ja/realtime.json';
import jaLanguages from './locales/ja/languages.json';
import jaContactPage from './locales/ja/contactPage.json';
import jaAboutPage from './locales/ja/aboutPage.json';
import jaPricingPage from './locales/ja/pricingPage.json';
import jaHowItWorksPage from './locales/ja/howItWorksPage.json';
import jaIcebreaker from './locales/ja/icebreaker.json';
// Ajout du namespace settingsPage
import jaSettingsPage from './locales/ja/settingsPage.json';

// ============ KO (ko) ============
import koCommon from './locales/ko/common.json';
import koLandingPage from './locales/ko/landingPage.json';
import koScenarios from './locales/ko/scenarios.json';
import koRegisterEmailForm from './locales/ko/registerEmailForm.json';
import koLogin from './locales/ko/login.json';
import koPaywall from './locales/ko/paywall.json';
import koSuccessPage from './locales/ko/successPage.json';
import koCancelPage from './locales/ko/cancelPage.json';
import koConsolePage from './locales/ko/consolePage.json';
import koRealtime from './locales/ko/realtime.json';
import koLanguages from './locales/ko/languages.json';
import koContactPage from './locales/ko/contactPage.json';
import koAboutPage from './locales/ko/aboutPage.json';
import koPricingPage from './locales/ko/pricingPage.json';
import koHowItWorksPage from './locales/ko/howItWorksPage.json';
import koIcebreaker from './locales/ko/icebreaker.json';
// Ajout du namespace settingsPage
import koSettingsPage from './locales/ko/settingsPage.json';

// ============ NL (nl) ============
import nlCommon from './locales/nl/common.json';
import nlLandingPage from './locales/nl/landingPage.json';
import nlScenarios from './locales/nl/scenarios.json';
import nlRegisterEmailForm from './locales/nl/registerEmailForm.json';
import nlLogin from './locales/nl/login.json';
import nlPaywall from './locales/nl/paywall.json';
import nlSuccessPage from './locales/nl/successPage.json';
import nlCancelPage from './locales/nl/cancelPage.json';
import nlConsolePage from './locales/nl/consolePage.json';
import nlRealtime from './locales/nl/realtime.json';
import nlLanguages from './locales/nl/languages.json';
import nlContactPage from './locales/nl/contactPage.json';
import nlAboutPage from './locales/nl/aboutPage.json';
import nlPricingPage from './locales/nl/pricingPage.json';
import nlHowItWorksPage from './locales/nl/howItWorksPage.json';
import nlIcebreaker from './locales/nl/icebreaker.json';
// Ajout du namespace settingsPage
import nlSettingsPage from './locales/nl/settingsPage.json';

// ============ PL (pl) ============
import plCommon from './locales/pl/common.json';
import plLandingPage from './locales/pl/landingPage.json';
import plScenarios from './locales/pl/scenarios.json';
import plRegisterEmailForm from './locales/pl/registerEmailForm.json';
import plLogin from './locales/pl/login.json';
import plPaywall from './locales/pl/paywall.json';
import plSuccessPage from './locales/pl/successPage.json';
import plCancelPage from './locales/pl/cancelPage.json';
import plConsolePage from './locales/pl/consolePage.json';
import plRealtime from './locales/pl/realtime.json';
import plLanguages from './locales/pl/languages.json';
import plContactPage from './locales/pl/contactPage.json';
import plAboutPage from './locales/pl/aboutPage.json';
import plPricingPage from './locales/pl/pricingPage.json';
import plHowItWorksPage from './locales/pl/howItWorksPage.json';
import plIcebreaker from './locales/pl/icebreaker.json';
// Ajout du namespace settingsPage
import plSettingsPage from './locales/pl/settingsPage.json';

// ============ PT (pt) ============
import ptCommon from './locales/pt/common.json';
import ptLandingPage from './locales/pt/landingPage.json';
import ptScenarios from './locales/pt/scenarios.json';
import ptRegisterEmailForm from './locales/pt/registerEmailForm.json';
import ptLogin from './locales/pt/login.json';
import ptPaywall from './locales/pt/paywall.json';
import ptSuccessPage from './locales/pt/successPage.json';
import ptCancelPage from './locales/pt/cancelPage.json';
import ptConsolePage from './locales/pt/consolePage.json';
import ptRealtime from './locales/pt/realtime.json';
import ptLanguages from './locales/pt/languages.json';
import ptContactPage from './locales/pt/contactPage.json';
import ptAboutPage from './locales/pt/aboutPage.json';
import ptPricingPage from './locales/pt/pricingPage.json';
import ptHowItWorksPage from './locales/pt/howItWorksPage.json';
import ptIcebreaker from './locales/pt/icebreaker.json';
// Ajout du namespace settingsPage
import ptSettingsPage from './locales/pt/settingsPage.json';

// ============ RU (ru) ============
import ruCommon from './locales/ru/common.json';
import ruLandingPage from './locales/ru/landingPage.json';
import ruScenarios from './locales/ru/scenarios.json';
import ruRegisterEmailForm from './locales/ru/registerEmailForm.json';
import ruLogin from './locales/ru/login.json';
import ruPaywall from './locales/ru/paywall.json';
import ruSuccessPage from './locales/ru/successPage.json';
import ruCancelPage from './locales/ru/cancelPage.json';
import ruConsolePage from './locales/ru/consolePage.json';
import ruRealtime from './locales/ru/realtime.json';
import ruLanguages from './locales/ru/languages.json';
import ruContactPage from './locales/ru/contactPage.json';
import ruAboutPage from './locales/ru/aboutPage.json';
import ruPricingPage from './locales/ru/pricingPage.json';
import ruHowItWorksPage from './locales/ru/howItWorksPage.json';
import ruIcebreaker from './locales/ru/icebreaker.json';
// Ajout du namespace settingsPage
import ruSettingsPage from './locales/ru/settingsPage.json';

// ============ TR (tr) ============
import trCommon from './locales/tr/common.json';
import trLandingPage from './locales/tr/landingPage.json';
import trScenarios from './locales/tr/scenarios.json';
import trRegisterEmailForm from './locales/tr/registerEmailForm.json';
import trLogin from './locales/tr/login.json';
import trPaywall from './locales/tr/paywall.json';
import trSuccessPage from './locales/tr/successPage.json';
import trCancelPage from './locales/tr/cancelPage.json';
import trConsolePage from './locales/tr/consolePage.json';
import trRealtime from './locales/tr/realtime.json';
import trLanguages from './locales/tr/languages.json';
import trContactPage from './locales/tr/contactPage.json';
import trAboutPage from './locales/tr/aboutPage.json';
import trPricingPage from './locales/tr/pricingPage.json';
import trHowItWorksPage from './locales/tr/howItWorksPage.json';
import trIcebreaker from './locales/tr/icebreaker.json';
// Ajout du namespace settingsPage
import trSettingsPage from './locales/tr/settingsPage.json';

// ============ ZH (zh) ============
import zhCommon from './locales/zh/common.json';
import zhLandingPage from './locales/zh/landingPage.json';
import zhScenarios from './locales/zh/scenarios.json';
import zhRegisterEmailForm from './locales/zh/registerEmailForm.json';
import zhLogin from './locales/zh/login.json';
import zhPaywall from './locales/zh/paywall.json';
import zhSuccessPage from './locales/zh/successPage.json';
import zhCancelPage from './locales/zh/cancelPage.json';
import zhConsolePage from './locales/zh/consolePage.json';
import zhRealtime from './locales/zh/realtime.json';
import zhLanguages from './locales/zh/languages.json';
import zhContactPage from './locales/zh/contactPage.json';
import zhAboutPage from './locales/zh/aboutPage.json';
import zhPricingPage from './locales/zh/pricingPage.json';
import zhHowItWorksPage from './locales/zh/howItWorksPage.json';
import zhIcebreaker from './locales/zh/icebreaker.json';
// Ajout du namespace settingsPage
import zhSettingsPage from './locales/zh/settingsPage.json';

// ============ ID (id) ============
import idCommon from './locales/id/common.json';
import idLandingPage from './locales/id/landingPage.json';
import idScenarios from './locales/id/scenarios.json';
import idRegisterEmailForm from './locales/id/registerEmailForm.json';
import idLogin from './locales/id/login.json';
import idPaywall from './locales/id/paywall.json';
import idSuccessPage from './locales/id/successPage.json';
import idCancelPage from './locales/id/cancelPage.json';
import idConsolePage from './locales/id/consolePage.json';
import idRealtime from './locales/id/realtime.json';
import idLanguages from './locales/id/languages.json';
import idContactPage from './locales/id/contactPage.json';
import idAboutPage from './locales/id/aboutPage.json';
import idPricingPage from './locales/id/pricingPage.json';
import idHowItWorksPage from './locales/id/howItWorksPage.json';
import idIcebreaker from './locales/id/icebreaker.json';
// Ajout du namespace settingsPage
import idSettingsPage from './locales/id/settingsPage.json';

// ============ UR (ur) ============
import urCommon from './locales/ur/common.json';
import urLandingPage from './locales/ur/landingPage.json';
import urScenarios from './locales/ur/scenarios.json';
import urRegisterEmailForm from './locales/ur/registerEmailForm.json';
import urLogin from './locales/ur/login.json';
import urPaywall from './locales/ur/paywall.json';
import urSuccessPage from './locales/ur/successPage.json';
import urCancelPage from './locales/ur/cancelPage.json';
import urConsolePage from './locales/ur/consolePage.json';
import urRealtime from './locales/ur/realtime.json';
import urLanguages from './locales/ur/languages.json';
import urContactPage from './locales/ur/contactPage.json';
import urAboutPage from './locales/ur/aboutPage.json';
import urPricingPage from './locales/ur/pricingPage.json';
import urHowItWorksPage from './locales/ur/howItWorksPage.json';
import urIcebreaker from './locales/ur/icebreaker.json';
// Ajout du namespace settingsPage
import urSettingsPage from './locales/ur/settingsPage.json';

// --------------------------------------
// == 2) INITIALISATION DE I18NEXT ==
// --------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // (A) On déclare nos ressources
    resources: {
      ar: {
        common: arCommon,
        landingPage: arLandingPage,
        scenarios: arScenarios,
        registerEmailForm: arRegisterEmailForm,
        login: arLogin,
        paywall: arPaywall,
        successPage: arSuccessPage,
        cancelPage: arCancelPage,
        consolePage: arConsolePage,
        realtime: arRealtime,
        languages: arLanguages,
        contactPage: arContactPage,
        aboutPage: arAboutPage,
        pricingPage: arPricingPage,
        howItWorksPage: arHowItWorksPage,
        icebreaker: arIcebreaker,
        // Nouveau namespace :
        settingsPage: arSettingsPage,
      },
      de: {
        common: deCommon,
        landingPage: deLandingPage,
        scenarios: deScenarios,
        registerEmailForm: deRegisterEmailForm,
        login: deLogin,
        paywall: dePaywall,
        successPage: deSuccessPage,
        cancelPage: deCancelPage,
        consolePage: deConsolePage,
        realtime: deRealtime,
        languages: deLanguages,
        contactPage: deContactPage,
        aboutPage: deAboutPage,
        pricingPage: dePricingPage,
        howItWorksPage: deHowItWorksPage,
        icebreaker: deIcebreaker,
        settingsPage: deSettingsPage,
      },
      en: {
        common: enCommon,
        landingPage: enLandingPage,
        scenarios: enScenarios,
        registerEmailForm: enRegisterEmailForm,
        login: enLogin,
        paywall: enPaywall,
        successPage: enSuccessPage,
        cancelPage: enCancelPage,
        consolePage: enConsolePage,
        realtime: enRealtime,
        languages: enLanguages,
        contactPage: enContactPage,
        aboutPage: enAboutPage,
        pricingPage: enPricingPage,
        howItWorksPage: enHowItWorksPage,
        icebreaker: enIcebreaker,
        settingsPage: enSettingsPage,
      },
      es: {
        common: esCommon,
        landingPage: esLandingPage,
        scenarios: esScenarios,
        registerEmailForm: esRegisterEmailForm,
        login: esLogin,
        paywall: esPaywall,
        successPage: esSuccessPage,
        cancelPage: esCancelPage,
        consolePage: esConsolePage,
        realtime: esRealtime,
        languages: esLanguages,
        contactPage: esContactPage,
        aboutPage: esAboutPage,
        pricingPage: esPricingPage,
        howItWorksPage: esHowItWorksPage,
        icebreaker: esIcebreaker,
        settingsPage: esSettingsPage,
      },
      fr: {
        common: frCommon,
        landingPage: frLandingPage,
        scenarios: frScenarios,
        registerEmailForm: frRegisterEmailForm,
        login: frLogin,
        paywall: frPaywall,
        successPage: frSuccessPage,
        cancelPage: frCancelPage,
        consolePage: frConsolePage,
        realtime: frRealtime,
        languages: frLanguages,
        contactPage: frContactPage,
        aboutPage: frAboutPage,
        pricingPage: frPricingPage,
        howItWorksPage: frHowItWorksPage,
        icebreaker: frIcebreaker,
        settingsPage: frSettingsPage,
      },
      hi: {
        common: hiCommon,
        landingPage: hiLandingPage,
        scenarios: hiScenarios,
        registerEmailForm: hiRegisterEmailForm,
        login: hiLogin,
        paywall: hiPaywall,
        successPage: hiSuccessPage,
        cancelPage: hiCancelPage,
        consolePage: hiConsolePage,
        realtime: hiRealtime,
        languages: hiLanguages,
        contactPage: hiContactPage,
        aboutPage: hiAboutPage,
        pricingPage: hiPricingPage,
        howItWorksPage: hiHowItWorksPage,
        icebreaker: hiIcebreaker,
        settingsPage: hiSettingsPage,
      },
      it: {
        common: itCommon,
        landingPage: itLandingPage,
        scenarios: itScenarios,
        registerEmailForm: itRegisterEmailForm,
        login: itLogin,
        paywall: itPaywall,
        successPage: itSuccessPage,
        cancelPage: itCancelPage,
        consolePage: itConsolePage,
        realtime: itRealtime,
        languages: itLanguages,
        contactPage: itContactPage,
        aboutPage: itAboutPage,
        pricingPage: itPricingPage,
        howItWorksPage: itHowItWorksPage,
        icebreaker: itIcebreaker,
        settingsPage: itSettingsPage,
      },
      ja: {
        common: jaCommon,
        landingPage: jaLandingPage,
        scenarios: jaScenarios,
        registerEmailForm: jaRegisterEmailForm,
        login: jaLogin,
        paywall: jaPaywall,
        successPage: jaSuccessPage,
        cancelPage: jaCancelPage,
        consolePage: jaConsolePage,
        realtime: jaRealtime,
        languages: jaLanguages,
        contactPage: jaContactPage,
        aboutPage: jaAboutPage,
        pricingPage: jaPricingPage,
        howItWorksPage: jaHowItWorksPage,
        icebreaker: jaIcebreaker,
        settingsPage: jaSettingsPage,
      },
      ko: {
        common: koCommon,
        landingPage: koLandingPage,
        scenarios: koScenarios,
        registerEmailForm: koRegisterEmailForm,
        login: koLogin,
        paywall: koPaywall,
        successPage: koSuccessPage,
        cancelPage: koCancelPage,
        consolePage: koConsolePage,
        realtime: koRealtime,
        languages: koLanguages,
        contactPage: koContactPage,
        aboutPage: koAboutPage,
        pricingPage: koPricingPage,
        howItWorksPage: koHowItWorksPage,
        icebreaker: koIcebreaker,
        settingsPage: koSettingsPage,
      },
      nl: {
        common: nlCommon,
        landingPage: nlLandingPage,
        scenarios: nlScenarios,
        registerEmailForm: nlRegisterEmailForm,
        login: nlLogin,
        paywall: nlPaywall,
        successPage: nlSuccessPage,
        cancelPage: nlCancelPage,
        consolePage: nlConsolePage,
        realtime: nlRealtime,
        languages: nlLanguages,
        contactPage: nlContactPage,
        aboutPage: nlAboutPage,
        pricingPage: nlPricingPage,
        howItWorksPage: nlHowItWorksPage,
        icebreaker: nlIcebreaker,
        settingsPage: nlSettingsPage,
      },
      pl: {
        common: plCommon,
        landingPage: plLandingPage,
        scenarios: plScenarios,
        registerEmailForm: plRegisterEmailForm,
        login: plLogin,
        paywall: plPaywall,
        successPage: plSuccessPage,
        cancelPage: plCancelPage,
        consolePage: plConsolePage,
        realtime: plRealtime,
        languages: plLanguages,
        contactPage: plContactPage,
        aboutPage: plAboutPage,
        pricingPage: plPricingPage,
        howItWorksPage: plHowItWorksPage,
        icebreaker: plIcebreaker,
        settingsPage: plSettingsPage,
      },
      pt: {
        common: ptCommon,
        landingPage: ptLandingPage,
        scenarios: ptScenarios,
        registerEmailForm: ptRegisterEmailForm,
        login: ptLogin,
        paywall: ptPaywall,
        successPage: ptSuccessPage,
        cancelPage: ptCancelPage,
        consolePage: ptConsolePage,
        realtime: ptRealtime,
        languages: ptLanguages,
        contactPage: ptContactPage,
        aboutPage: ptAboutPage,
        pricingPage: ptPricingPage,
        howItWorksPage: ptHowItWorksPage,
        icebreaker: ptIcebreaker,
        settingsPage: ptSettingsPage,
      },
      ru: {
        common: ruCommon,
        landingPage: ruLandingPage,
        scenarios: ruScenarios,
        registerEmailForm: ruRegisterEmailForm,
        login: ruLogin,
        paywall: ruPaywall,
        successPage: ruSuccessPage,
        cancelPage: ruCancelPage,
        consolePage: ruConsolePage,
        realtime: ruRealtime,
        languages: ruLanguages,
        contactPage: ruContactPage,
        aboutPage: ruAboutPage,
        pricingPage: ruPricingPage,
        howItWorksPage: ruHowItWorksPage,
        icebreaker: ruIcebreaker,
        settingsPage: ruSettingsPage,
      },
      tr: {
        common: trCommon,
        landingPage: trLandingPage,
        scenarios: trScenarios,
        registerEmailForm: trRegisterEmailForm,
        login: trLogin,
        paywall: trPaywall,
        successPage: trSuccessPage,
        cancelPage: trCancelPage,
        consolePage: trConsolePage,
        realtime: trRealtime,
        languages: trLanguages,
        contactPage: trContactPage,
        aboutPage: trAboutPage,
        pricingPage: trPricingPage,
        howItWorksPage: trHowItWorksPage,
        icebreaker: trIcebreaker,
        settingsPage: trSettingsPage,
      },
      zh: {
        common: zhCommon,
        landingPage: zhLandingPage,
        scenarios: zhScenarios,
        registerEmailForm: zhRegisterEmailForm,
        login: zhLogin,
        paywall: zhPaywall,
        successPage: zhSuccessPage,
        cancelPage: zhCancelPage,
        consolePage: zhConsolePage,
        realtime: zhRealtime,
        languages: zhLanguages,
        contactPage: zhContactPage,
        aboutPage: zhAboutPage,
        pricingPage: zhPricingPage,
        howItWorksPage: zhHowItWorksPage,
        icebreaker: zhIcebreaker,
        settingsPage: zhSettingsPage,
      },
      id: {
        common: idCommon,
        landingPage: idLandingPage,
        scenarios: idScenarios,
        registerEmailForm: idRegisterEmailForm,
        login: idLogin,
        paywall: idPaywall,
        successPage: idSuccessPage,
        cancelPage: idCancelPage,
        consolePage: idConsolePage,
        realtime: idRealtime,
        languages: idLanguages,
        contactPage: idContactPage,
        aboutPage: idAboutPage,
        pricingPage: idPricingPage,
        howItWorksPage: idHowItWorksPage,
        icebreaker: idIcebreaker,
        settingsPage: idSettingsPage,
      },
      ur: {
        common: urCommon,
        landingPage: urLandingPage,
        scenarios: urScenarios,
        registerEmailForm: urRegisterEmailForm,
        login: urLogin,
        paywall: urPaywall,
        successPage: urSuccessPage,
        cancelPage: urCancelPage,
        consolePage: urConsolePage,
        realtime: urRealtime,
        languages: urLanguages,
        contactPage: urContactPage,
        aboutPage: urAboutPage,
        pricingPage: urPricingPage,
        howItWorksPage: urHowItWorksPage,
        icebreaker: urIcebreaker,
        settingsPage: urSettingsPage,
      },
    },

    // (B) Liste des namespaces utilisés dans tout le projet
    ns: [
      'common',
      'landingPage',
      'scenarios',
      'registerEmailForm',
      'login',
      'paywall',
      'successPage',
      'cancelPage',
      'consolePage',
      'realtime',
      'languages',
      'contactPage',
      'aboutPage',
      'pricingPage',
      'howItWorksPage',
      'icebreaker',
      // On ajoute le nouveau namespace :
      'settingsPage'
    ],
    defaultNS: 'common',
    fallbackNS: 'common',

    // (C) Langue par défaut en cas d'indisponibilité
    fallbackLng: 'en',

    // (D) Options diverses
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage'],
    },
    saveMissing: true,
    react: {
      useSuspense: false,
    },
    returnEmptyString: false,
  });

export default i18n;