// src/pages/LoginPage.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  AuthErrorCodes,
  getAuth,
} from 'firebase/auth';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Layout } from '../components/Layout';

/**
 * Composant LoginPage :
 *  - Permet de se connecter (e-mail / mot de passe)
 *  - Gère la logique de “mot de passe oublié”
 *  - Redirige si l'utilisateur est déjà logué
 */
export const LoginPage: React.FC = () => {
  // On cible le namespace "login"
  const { t: tLogin } = useTranslation('login');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [resetSent, setResetSent] = useState(false);

  const { currentUser } = useAuth(); // Pour savoir si on est déjà logué
  const navigate = useNavigate();

  // [A] Si déjà logué => on redirige
  useEffect(() => {
    if (currentUser && !currentUser.isAnonymous) {
      console.log('[LoginPage] ✅ Utilisateur déjà connecté => redirection');
      navigate('/', { replace: true });
    }
  }, [currentUser, navigate]);

  // [B] Handler principal “Login”
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setResetSent(false);

    if (!email || !password) {
      setError('Email et mot de passe requis.');
      return;
    }
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('[LoginPage] => Connexion réussie :', {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
      });

      // Redirection vers "/"
      navigate('/', { replace: true });
    } catch (err: any) {
      console.error('[LoginPage] => Erreur login =>', err);
      if (
        err.code === AuthErrorCodes.INVALID_PASSWORD ||
        err.code === AuthErrorCodes.USER_DELETED
      ) {
        setError('E-mail ou mot de passe invalide.'); 
        // <- tu peux localiser ce message si besoin, ex. tLogin('invalidCredentials')
      } else {
        setError(err.message);
      }
    }
  };

  // [C] Mot de passe oublié
  const handleResetPassword = async () => {
    setError(null);
    if (!email) {
      setError('Veuillez saisir votre adresse e-mail pour réinitialiser.'); 
      // <- idem, localisable si besoin
      return;
    }
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      console.log('[LoginPage] => Email de réinitialisation envoyé');
    } catch (err: any) {
      console.error('[LoginPage] => Erreur reset password =>', err);
      if (err.code === AuthErrorCodes.USER_DELETED) {
        setError('Aucun compte associé à cet e-mail.'); 
        // <- localisable si besoin
      } else {
        setError(err.message);
      }
    }
  };

  // [D] Rendu
  return (
    <Layout noMaxWidth>
      <div className="min-h-screen flex flex-col items-center justify-center px-4">
        
        {/*
          Conteneur principal :
          - Largeur plus importante => max-w-lg
          - p-10
          - Bordure grise => border-gray-600
          - Font min 1.2rem
        */}
        <div
          className="
            w-full max-w-lg
            bg-white
            border-2 border-gray-600
            rounded-md
            shadow
            p-10
            text-[1.2rem]
            sm:text-[1.25rem]
            md:text-[1.375rem]
            lg:text-[1.65rem]
            text-gray-800
          "
        >
          {/* Titre */}
          <h1 className="text-center font-extrabold mb-6 text-[1.4rem] sm:text-[1.5rem] md:text-[1.7rem] lg:text-[2rem]">
            {tLogin('title', 'Log in')}
          </h1>

          {/* Gestion des erreurs et feedback */}
          {error && (
            <div className="text-red-600 mb-3 text-center">
              {error}
            </div>
          )}
          {resetSent && (
            <div className="text-green-600 mb-3 text-center">
              {tLogin('resetEmailSent', 'A password reset link has been sent to your email address.')}
            </div>
          )}

          {/* Formulaire */}
          <form onSubmit={handleLogin} className="space-y-6">
            {/* Champ Email */}
            <div>
              <input
                type="email"
                placeholder={tLogin('emailPlaceholder', 'Email address')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="
                  w-full
                  border-2 border-gray-600
                  rounded-lg
                  px-4 py-3
                  text-gray-900
                  placeholder-gray-600
                  focus:outline-none
                  focus:ring-2
                  focus:ring-blue-400
                "
              />
            </div>

            {/* Champ Mot de passe + bouton œil */}
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder={tLogin('passwordPlaceholder', 'Password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="
                  w-full
                  border-2 border-gray-600
                  rounded-lg
                  px-4 py-3
                  text-gray-900
                  placeholder-gray-600
                  focus:outline-none
                  focus:ring-2
                  focus:ring-blue-400
                "
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="
                  absolute
                  inset-y-0
                  right-3
                  flex
                  items-center
                  text-gray-500
                  hover:text-gray-700
                "
                tabIndex={-1}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            {/* Bouton submit */}
            <button
              type="submit"
              className="
                w-full
                py-3
                bg-blue-500
                text-white
                font-semibold
                rounded-lg
                hover:bg-blue-600
                transition
                duration-200
                focus:outline-none
                focus:ring-4
                focus:ring-blue-300
                uppercase
                tracking-wide
              "
              // disabled = false possible, si tu veux bloquer quand form incomplet
            >
              {tLogin('loginButton', 'Log in')}
            </button>
          </form>

          {/* Lien mot de passe oublié */}
          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={handleResetPassword}
              className="text-blue-600 hover:text-blue-700"
            >
              {tLogin('forgotPassword', 'Forgot your password?')}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;