// src/pages/RegisterEmailForm.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebaseConfig';
import {
  EmailAuthProvider,
  linkWithCredential,
  AuthErrorCodes,
} from 'firebase/auth';
import { getApiUrl } from '../config/environment';
import { useUser } from '../context/UserContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function gtag_report_conversion(url?: string) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location.href = url;
    }
  };
  // @ts-ignore
  gtag('event', 'conversion', {
    send_to: 'AW-960724066/F2Y3CLDoxuYZEOL4jcoD',
    value: 0.5,
    currency: 'EUR',
    event_callback: callback,
  });
  return false;
}

interface RegisterEmailFormProps {
  onSuccess: () => void;
}

const RegisterEmailForm: React.FC<RegisterEmailFormProps> = ({ onSuccess }) => {
  // 1) On utilise le namespace "registerEmailForm"
  const { t: tRegister } = useTranslation('registerEmailForm');
  const { refreshUserData } = useUser();

  // Champs du formulaire
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // <-- NOUVEAU: prénom facultatif

  // Gérer l’affichage/masquage du MDP
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleRegisterEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('🚀 Début du processus d’inscription');

    try {
      const anonymousUser = auth.currentUser;
      console.log('👤 Utilisateur actuel :', {
        exists: !!anonymousUser,
        uid: anonymousUser?.uid,
        isAnonymous: anonymousUser?.isAnonymous,
        email: anonymousUser?.email,
      });

      if (!anonymousUser) {
        console.error('❌ Aucun utilisateur anonyme trouvé');
        return;
      }

      // Créer un credential avec l'email et le mot de passe
      console.log('📧 Tentative de création des credentials pour :', email);
      const credential = EmailAuthProvider.credential(email, password);

      try {
        console.log('🔄 Tentative de liaison du compte anonyme avec email');
        const userCredential = await linkWithCredential(anonymousUser, credential);
        console.log('✅ Compte mis à niveau avec succès :', {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          isAnonymous: userCredential.user.isAnonymous,
        });

        // Mettre à jour le statut du compte dans la base de données
        const apiUrl = getApiUrl();
        console.log('🔄 Mise à jour du statut dans la base de données :', apiUrl);

        const response = await fetch(`${apiUrl}/api/user/updateEmailStatus`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            uid: userCredential.user.uid,
            email,
            isAnonymous: false,
            firstName: firstName || undefined, // <-- on envoie si non vide
          }),
        });

        const data = await response.json();
        console.log('✅ Réponse de l’API :', data);

        // Refresh user data pour mettre à jour la propriété isAnonymous
        await refreshUserData();

        // Appeler onSuccess après une inscription réussie
        onSuccess();

        // Déclencher la conversion Google Ads
        console.log('📈 Envoi de la conversion à Google Ads (gtag_report_conversion)');
        gtag_report_conversion();

      } catch (error: any) {
        console.error('❌ Erreur détaillée :', {
          code: error.code,
          message: error.message,
          fullError: error,
        });

        if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
          // Gérer le cas où l'email existe déjà
          setError(tRegister('emailExistsError'));
        } else {
          console.error('❌ Erreur lors de la mise à niveau du compte :', error);
          setError(error.message);
        }
      }
    } catch (error: any) {
      console.error('❌ Erreur générale lors de l’inscription :', {
        error: error.message,
        fullError: error,
      });
      setError(error.message);
    }
  };

  return (
    <div
      className="
        text-center
        w-full
        max-w-md
        mx-auto
        px-4
        py-6
        bg-white
        rounded-md
        shadow-sm
      "
      style={{ minWidth: '280px' }}
    >
      <h2
        className="
          mb-3
          text-gray-700
          font-bold
          text-[1.4rem]
          sm:text-[1.5rem]
          md:text-[1.7rem]
        "
      >
        {tRegister('saveProgressMessage')}
      </h2>

      <p
        className="
          mb-6
          text-gray-500
          text-[1.1rem]
          sm:text-[1.15rem]
          md:text-[1.2rem]
        "
      >
        {tRegister('saveProgressDescription')}
      </p>

      {error && (
        <p
          className="
            mb-4
            text-red-500
            text-[1rem]
            sm:text-[1.05rem]
            md:text-[1.1rem]
          "
        >
          {error}
        </p>
      )}

      <form onSubmit={handleRegisterEmail} className="space-y-4">
        {/* Champ prénom (facultatif) */}
        <input
          type="text"
          placeholder={tRegister('firstNamePlaceholder') || 'First name (optional)'}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="
            w-full
            px-4 py-3
            border border-gray-300
            rounded-lg
            focus:outline-none
            focus:ring-2 focus:ring-blue-400
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.15rem]
          "
        />

        {/* Champ email */}
        <input
          type="email"
          placeholder={tRegister('emailPlaceholder')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="
            w-full
            px-4 py-3
            border border-gray-300
            rounded-lg
            focus:outline-none
            focus:ring-2 focus:ring-blue-400
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.15rem]
          "
        />

        {/* Champ mot de passe + bouton œil */}
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={tRegister('choosePassword')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="
              w-full
              px-4 py-3
              border border-gray-300
              rounded-lg
              focus:outline-none
              focus:ring-2 focus:ring-blue-400
              text-[1.05rem]
              sm:text-[1.1rem]
              md:text-[1.15rem]
            "
          />
          {/* Bouton pour afficher/masquer le mot de passe */}
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="
              absolute
              inset-y-0
              right-3
              flex
              items-center
              text-gray-500
              hover:text-gray-700
            "
            tabIndex={-1}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

        <button
          type="submit"
          className="
            w-full
            py-3
            mt-2
            bg-blue-500
            text-white
            font-semibold
            rounded-lg
            hover:bg-blue-600
            transition
            duration-200
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.15rem]
            focus:outline-none
            focus:ring-4 focus:ring-blue-300
          "
        >
          {tRegister('signUpButton')}
        </button>
      </form>
    </div>
  );
};

export default RegisterEmailForm;