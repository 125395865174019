// src/pages/StudyLevelSelection.tsx
import React from 'react';
import { Layout } from '../components/Layout';
import { useTranslation } from 'react-i18next';

// Contexte(s) et services
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext'; // ← pour la langue maternelle
import { useStudy } from '../context/StudyContext'; // ← pour la langue d’étude
import { updateUserSettings } from '../services/userService';

// Constantes
import { LEVELS } from '../constants/levels';

// Styles et composants
import './StudyLevelSelection.scss';
import SignalBars from '../components/SignalBars';

interface StudyLevelSelectionProps {
  onLevelSelect: () => void;
}

export const StudyLevelSelection: React.FC<StudyLevelSelectionProps> = ({
  onLevelSelect,
}) => {
  // 1) On cible le namespace "common", où se trouve "studyLevelSelection" et "languages"
  const { t: tCommon } = useTranslation('common');

  // 2) Récupère l’utilisateur
  const { currentUser } = useAuth();

  // 3) Récupère userData (où potentiellement se trouve `nativeLanguageSelected`)
  const { userData } = useUser();

  // 4) Récupère studyLanguage et setStudyLevel
  const { studyLanguage, setStudyLevel } = useStudy();

  // 5) Fonction de clic sur un niveau => on enregistre en BDD
  const handleLevelClick = async (value: number) => {
    if (!currentUser) {
      console.error('No user is currently logged in.');
      return;
    }

    console.log('Niveau d’étude choisi :', value);
    setStudyLevel(value);

    try {
      await updateUserSettings(currentUser.uid, {
        studyLanguage,
        studyLevel: value,
      });
      console.log("Niveau d'étude enregistré dans l’API/DB");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du niveau d'étude :", error);
    }

    // Signale qu’on a fini la sélection
    onLevelSelect();
  };

  return (
    <Layout>
      <div
        className="
          md:h-screen
          md:flex
          md:items-center
          md:justify-center
          px-4
        "
      >
        <div className="study-level-selection custom-top-padding">
          <h2
            className="
              text-[2rem]
              sm:text-[2.4rem]
              md:text-[3.3rem]
              font-extrabold
              text-[#243238]
              leading-tight
              text-center
              mb-8
            "
          >
            {/* Clé : common.json → studyLevelSelection.title */}
            {tCommon('studyLevelSelection.title')}
          </h2>

          <div className="levels-list">
            {LEVELS.map((value) => {
              // (A) Récupère le nom "humain" de la langue d’étude, ex. "Portuguese"
              const studyLanguageName =
                tCommon(`languages.${studyLanguage}`) || 'Foreign language';

              // (B) Récupère la langue maternelle de l’utilisateur (ou "en" par défaut)
              const nativeLangCode = userData?.nativeLanguageSelected || 'en';
              const nativeLanguageName =
                tCommon(`languages.${nativeLangCode}`) || 'Your native language';

              // (C) On injecte ces 2 variables dans la description du niveau :
              const description = tCommon(
                `studyLevelSelection.levelDescriptions.${value}`,
                {
                  studyLanguageName,
                  nativeLanguageName,
                }
              );

              return (
                <div
                  key={value}
                  className="level-card"
                  onClick={() => handleLevelClick(value)}
                >
                  <div className="flex items-center space-x-8">
                    {/* Représentation visuelle du niveau (barres de signal) */}
                    <SignalBars level={value} />

                    {/* Texte / description du niveau */}
                    <p
                      className="
                        text-[1.1rem]
                        sm:text-[1.2rem]
                        md:text-[1.3rem]
                        lg:text-[1.5rem]
                        text-gray-800
                        leading-snug
                        m-0
                        font-bold
                      "
                    >
                      {description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StudyLevelSelection;