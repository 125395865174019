// src/pages/ConsolePage/components/BottomBar.tsx

import React from 'react';
import { RealtimeWebRTCUI } from '../RealtimeWebRTCUI';
import { RealtimeVisualizer } from '../../../components/RealtimeVisualizer';
import { TextInputBoxRealtime } from '../../../pages/ConsolePage/TextInputBoxRealtime';

type BottomBarProps = {
  /** Indique si la session WebRTC est active */
  isSessionActive: boolean;
  /** Indique si on est en cours d'enregistrement (push-to-talk) */
  isRecording: boolean;
  /** RTCDataChannel, si besoin de l’état open|closed */
  dataChannel: RTCDataChannel | null;
  /** Court message affiché si l’enregistrement est trop court */
  shortRecordingMessage: string | null;

  /** AnalyserNodes */
  localAnalyserState: AnalyserNode | null;
  remoteAnalyserState: AnalyserNode | null;

  /** Callbacks microphone */
  onMicPointerDown: () => void;
  onMicPointerUp: () => void;
  /** Callback quand assistant se tait */
  onAssistantSilence: () => void;

  /** Pour afficher/masquer la zone texte */
  onToggleTextInput: () => void;
  showTextInput: boolean;

  /** Check limites free */
  checkIfLimitReached: () => boolean;
  isLimitReached: boolean;
  setIsLimitReached: (val: boolean) => void;

  /** Envoi d’un message text */
  handleSendTextMessage: (text: string) => Promise<void>;
};

export function BottomBar(props: BottomBarProps) {
  const {
    isSessionActive,
    isRecording,
    dataChannel,
    shortRecordingMessage,
    localAnalyserState,
    remoteAnalyserState,
    onMicPointerDown,
    onMicPointerUp,
    onAssistantSilence,
    onToggleTextInput,
    showTextInput,
    checkIfLimitReached,
    isLimitReached,
    handleSendTextMessage,
  } = props;

  return (
    <div className="px-4 py-4 shrink-0">
      {/* Composant micro + session WebRTC */}
      <RealtimeWebRTCUI
        isSessionActive={isSessionActive}
        isRecording={isRecording}
        dataChannel={dataChannel}
        shortRecordingMessage={shortRecordingMessage}
        checkIfLimitReached={checkIfLimitReached}
        handlePointerDown={onMicPointerDown}
        handlePointerUp={onMicPointerUp}
        onToggleTextInput={onToggleTextInput}
      />

      {/* Visualisation audio (assistant silence) */}
      <RealtimeVisualizer
        localAnalyser={localAnalyserState}
        remoteAnalyser={remoteAnalyserState}
        onAssistantSilence={onAssistantSilence}
      />

      {/* Zone de saisie texte : on peut l’afficher ici (ou plus haut) */}
      {!isLimitReached && showTextInput && (
        <div className="mt-2 lg:container lg:mx-auto lg:px-52 xl:px-96">
          <TextInputBoxRealtime
            onSendMessage={handleSendTextMessage}
            onBeforeSend={async () => true}
          />
        </div>
      )}
    </div>
  );
}