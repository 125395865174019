/* -----------------------------------------------------------------------
 * src/pages/ConsolePage/useConsoleMessages.ts
 * ----------------------------------------------------------------------- */

import { useState, useCallback } from 'react';
import { ConversationItemType } from '../../../types';

/**
 * Paramètres que ce hook reçoit depuis useConsolePageLogic :
 *  - currentUser, userData : infos sur l'utilisateur
 *  - studyLanguage, selectedScenario : contexte d'étude
 *  - refreshTotalMessageCount, refreshUserData : fonctions pour mettre à jour
 *  - checkAndTriggerStreakIncrease : pour incrémenter la streak si besoin
 *  - onLimitReached?: callback pour signaler que le backend a renvoyé 403
 */
type UseConsoleMessagesParams = {
  currentUser: any; // ou un type plus précis si vous l'avez
  userData: any;
  studyLanguage: string;
  selectedScenario: string;

  /** 
   * Si votre refreshTotalMessageCount renvoie
   * Promise<number|undefined>, on respecte la même signature ici.
   */
  refreshTotalMessageCount: () => Promise<number | undefined>;

  refreshUserData: () => Promise<void>;
  checkAndTriggerStreakIncrease: (oldStreak: number) => Promise<void>;

  /**
   * (Optionnel) Callback appelé quand le backend renvoie 403 (limite atteinte).
   * => Permet de déclencher isLimitReached = true dans useConsolePageLogic
   */
  onLimitReached?: () => void;
};

/**
 * Hook useConsoleMessages :
 *  - Gère l'état local “items” (liste de messages) et “sessionMessageCount”
 *  - Gère l'envoi de messages au backend (/api/conversation/message)
 *  - Gère les events Realtime (handleRealtimeEvent)
 *  - Incrémente la streak / totalMessageCount côté backend
 *  - Si le backend renvoie 403, on appelle onLimitReached() si fourni
 */
export function useConsoleMessages({
  currentUser,
  userData,
  studyLanguage,
  selectedScenario,
  refreshTotalMessageCount,
  refreshUserData,
  checkAndTriggerStreakIncrease,
  onLimitReached,
}: UseConsoleMessagesParams) {
  // -----------------------------------------------------------------------
  // 1) État local : liste de messages (items) + décompte sessionMessageCount
  // -----------------------------------------------------------------------
  const [items, setItems] = useState<ConversationItemType[]>([]);
  const [sessionMessageCount, setSessionMessageCount] = useState(0);

  // -----------------------------------------------------------------------
  // 2) Base URL (adapter si nécessaire)
  // -----------------------------------------------------------------------
  const API_BASE_URL =
    process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8082';

  // -----------------------------------------------------------------------
  // 3) sendUserMessageToBackend :
  //    => Envoi d'un message “user” au backend,
  //    => Retourne potentiellement la correction dans lastMessage.correction
  // -----------------------------------------------------------------------
  const sendUserMessageToBackend = useCallback(
    async (messageContent: string) => {
      if (!currentUser?.uid) return;
      if (!messageContent.trim()) return;

      const oldStreak = userData?.dailyStreak || 0;
      const payload = {
        uid: currentUser.uid,
        studyLanguage,
        scenarioId: selectedScenario,
        message: messageContent,
        role: 'user',
        timestamp: new Date().toISOString(),
      };

      try {
        // On marque localement le dernier message user “in progress” ou “sent”
        // comme étant en attente de correction
        setItems((prev) => {
          const clone = [...prev];
          for (let i = clone.length - 1; i >= 0; i--) {
            if (clone[i].role === 'user' && clone[i].status !== 'done') {
              clone[i] = {
                ...clone[i],
                text: messageContent,
                isWaitingCorrection: true,
              };
              break;
            }
          }
          return clone;
        });

        // Requête HTTP => /api/conversation/message
        const res = await fetch(`${API_BASE_URL}/api/conversation/message`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        // Si le backend renvoie 403 => limite atteinte => on appelle onLimitReached?
        if (res.status === 403) {
          console.warn('[useConsoleMessages] => 403 => limit reached (user msg)');
          if (onLimitReached) {
            onLimitReached();
          }
          return; // On arrête là
        }

        if (!res.ok) {
          throw new Error(`Server returned ${res.status}`);
        }

        const data = await res.json();
        if (data.success && data.lastMessage) {
          // On met à jour le message user qui attendait la correction
          setItems((prev) => {
            const clone = [...prev];
            // Trouver le message user qui est en attente de correction
            let foundIndex = -1;
            for (let i = clone.length - 1; i >= 0; i--) {
              if (clone[i].role === 'user' && clone[i].isWaitingCorrection) {
                foundIndex = i;
                break;
              }
            }
            if (foundIndex !== -1) {
              clone[foundIndex] = {
                ...clone[foundIndex],
                text: data.lastMessage.content,
                correction: data.lastMessage.correction || undefined,
                status: 'done',
                isWaitingCorrection: false,
              };
            } else {
              // Sécurité : si on ne retrouve pas => on push
              clone.push({
                id: '(server)' + Math.random().toString(36).slice(2),
                role: data.lastMessage.role,
                text: data.lastMessage.content,
                status: 'done',
                correction: data.lastMessage.correction,
                isWaitingCorrection: false,
              });
            }
            return clone;
          });
        }

        // Refresh totalMessageCount (context) + userData (streak)
        await refreshTotalMessageCount();
        await refreshUserData();

        // Vérifier la streak
        await checkAndTriggerStreakIncrease(oldStreak);
      } catch (err) {
        console.error('[useConsoleMessages] => Error saving user message:', err);
      }
    },
    [
      API_BASE_URL,
      currentUser,
      userData?.dailyStreak,
      studyLanguage,
      selectedScenario,
      refreshTotalMessageCount,
      refreshUserData,
      checkAndTriggerStreakIncrease,
      onLimitReached,
      setItems,
    ]
  );

  // -----------------------------------------------------------------------
  // 4) sendAssistantMessageToBackend :
  //    => Envoi d'un message “assistant” au backend (pas de correction)
  // -----------------------------------------------------------------------
  const sendAssistantMessageToBackend = useCallback(
    async (assistantContent: string) => {
      if (!currentUser?.uid) return;
      if (!assistantContent.trim()) return;

      const payload = {
        uid: currentUser.uid,
        studyLanguage,
        scenarioId: selectedScenario,
        message: assistantContent,
        role: 'assistant',
        timestamp: new Date().toISOString(),
      };

      try {
        const res = await fetch(`${API_BASE_URL}/api/conversation/message`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        // Si 403 => limit => on appelle onLimitReached?
        if (res.status === 403) {
          console.warn('[useConsoleMessages] => 403 => limit reached (assistant msg)');
          if (onLimitReached) {
            onLimitReached();
          }
          return; // On arrête
        }
        if (!res.ok) {
          throw new Error(`Assistant message error: HTTP ${res.status}`);
        }
      } catch (err) {
        console.error('[useConsoleMessages] => Error saving assistant message:', err);
      }
    },
    [
      API_BASE_URL,
      currentUser,
      studyLanguage,
      selectedScenario,
      onLimitReached,
    ]
  );

  // -----------------------------------------------------------------------
  // 5) handleRealtimeEvent => gère les events Realtime : transcription, etc.
  // -----------------------------------------------------------------------
  const handleRealtimeEvent = useCallback(
    (event: any) => {
      if (!event?.type) return;

      switch (event.type) {
        // A) conversation.item.created => nouveau message user
        case 'conversation.item.created': {
          const { item } = event;
          if (item?.type === 'message' && item.role === 'user') {
            // Vérif si c'est “start”
            if (Array.isArray(item.content)) {
              const isStart = item.content.some(
                (c: any) =>
                  c.type === 'input_text' && c.text.trim().toLowerCase() === 'start'
              );
              if (isStart) break; // on ignore le "start"
            }

            // On incrémente le nombre de messages
            setSessionMessageCount((count) => count + 1);

            // Récupérer le texte réel, s'il y en a dans item.content
            let extractedText = '🔄';
            let foundTextPart: any = null;
            if (Array.isArray(item.content)) {
              foundTextPart = item.content.find(
                (c: any) => c.type === 'input_text'
              );
              if (foundTextPart?.text) {
                extractedText = foundTextPart.text;
              }
            }

            // On ajoute la bulle user avec soit "🔄", soit le texte détecté
            setItems((prev) => [
              ...prev,
              {
                id: item.id || '(no_id)',
                role: 'user',
                text: extractedText,
                status: 'in_progress',
                isWaitingCorrection: false,
              },
            ]);

            // Si on a effectivement un texte différent de "start",
            // on l'envoie immédiatement au backend pour correction
            if (
              foundTextPart?.text &&
              foundTextPart.text.trim().toLowerCase() !== 'start'
            ) {
              sendUserMessageToBackend(foundTextPart.text);
            }
          }
          break;
        }

        // B) Fin de transcription vocale
        case 'conversation.item.input_audio_transcription.completed': {
          const { item_id, transcript } = event;
          if (transcript?.trim()) {
            // On retrouve la bulle placeholder => on la met à jour
            setItems((prev) => {
              const idx = prev.findIndex((m) => m.id === item_id);
              if (idx === -1) return prev;
              const clone = [...prev];
              clone[idx] = {
                ...clone[idx],
                text: transcript,
                status: 'in_progress',
                isWaitingCorrection: true, // attend correction
              };
              return clone;
            });

            // On envoie => backend => correction
            sendUserMessageToBackend(transcript);
          }
          break;
        }

        // C) Réponse assistante => on insère / met à jour un message “assistant”
        case 'response.output_item.added':
        case 'response.output_item.done': {
          const { item } = event;
          if (item?.role === 'assistant') {
            // On concatène les transcripts s'il y en a plusieurs
            let foundText = '';
            if (Array.isArray(item.content)) {
              const transcriptPart = item.content.find((c: any) => c.transcript);
              foundText = transcriptPart?.transcript || '';
            }

            // On update ou insère ce message assistant
            setItems((prev) => {
              const idx = prev.findIndex((m) => m.id === item.id);
              if (idx === -1) {
                // On l'ajoute
                return [
                  ...prev,
                  {
                    id: item.id || '(assistant_id)',
                    role: 'assistant',
                    text: foundText,
                    status: 'in_progress',
                    isWaitingCorrection: false,
                  },
                ];
              }
              // Sinon, on le met à jour
              const clone = [...prev];
              clone[idx] = {
                ...clone[idx],
                text: foundText,
                status: 'done',
              };
              return clone;
            });

            // On l’envoie au backend => (pas de correction pour l’assistant)
            if (foundText.trim()) {
              sendAssistantMessageToBackend(foundText);
            }
          }
          break;
        }

        default:
          // Autres events => on ignore
          break;
      }
    },
    [sendUserMessageToBackend, sendAssistantMessageToBackend]
  );

  // -----------------------------------------------------------------------
  // 6) Retourne l'API du hook
  // -----------------------------------------------------------------------
  return {
    items,
    setItems,
    sessionMessageCount,
    setSessionMessageCount,
    handleRealtimeEvent,
  };
}