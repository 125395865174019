// src/pages/ConsolePage/useTranslateMessage.ts

import { useCallback } from 'react';
import { useLanguage } from '../../../context/LanguageContext';

// On suppose que tu l'as déjà quelque part
// Sinon tu peux la définir ainsi :
export type ConversationItemType = {
  id: string;
  role: 'user' | 'assistant';
  status: string;
  text: string;
  translation?: string;
};

type UseTranslateMessageReturn = {
  handleTranslate: (messageId: string) => Promise<void>;
};

/**
 * useTranslateMessage
 *
 * @param items - tableau d'items de conversation
 * @param setItems - setter pour items (React.useState)
 * @param setError - setter pour l'erreur (React.useState)
 */
export function useTranslateMessage(
  items: ConversationItemType[],
  setItems: React.Dispatch<React.SetStateAction<ConversationItemType[]>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>
): UseTranslateMessageReturn {
  const { nativeLanguageSelected } = useLanguage();

  const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8082';

  const handleTranslate = useCallback(
    async (messageId: string) => {
      try {
        // On type aussi la fonction du findIndex
        const index = items.findIndex(
          (item: ConversationItemType) => item.id === messageId
        );
        if (index === -1) return;

        const messageToTranslate = items[index];
        if (!messageToTranslate.text?.trim()) return;

        const payload = {
          text: messageToTranslate.text,
          targetLanguage: nativeLanguageSelected,
        };

        const response = await fetch(`${API_BASE_URL}/translate`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (data?.translatedText) {
          const updated = [...items];
          updated[index] = {
            ...messageToTranslate,
            translation: data.translatedText,
          };
          setItems(updated);
        } else {
          throw new Error(data.error || 'Erreur inconnue lors de la traduction');
        }
      } catch (err: any) {
        setError(`Erreur de traduction: ${err.message}`);
      }
    },
    [items, setItems, setError, nativeLanguageSelected, API_BASE_URL]
  );

  return { handleTranslate };
}