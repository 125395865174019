// src/pages/ConsolePage/RealtimeWebRTCUI.tsx

import React from 'react';
import { Mic } from 'react-feather';

type RealtimeWebRTCUIProps = {
  /** Indique si la session WebRTC est active */
  isSessionActive: boolean;
  /** Indique si on est en cours d'enregistrement (push-to-talk) */
  isRecording: boolean;
  /** RTCDataChannel, si besoin de l'état open|closed */
  dataChannel: RTCDataChannel | null;
  /** Court message affiché si l'enregistrement est trop court */
  shortRecordingMessage: string | null;

  /** Fonction déclenchée au « pointer down » (début push-to-talk) */
  handlePointerDown: () => void;
  /** Fonction déclenchée au « pointer up » (fin push-to-talk) */
  handlePointerUp: () => void;

  /** Vérifie si on a atteint la limite (paywall, quota, etc.) */
  checkIfLimitReached: () => boolean;

  /**
   * (Optionnel) Callback pour afficher/masquer
   * la zone de saisie texte (icône « clavier »).
   */
  onToggleTextInput?: () => void;
};

export function RealtimeWebRTCUI({
  isSessionActive,
  isRecording,
  dataChannel,
  shortRecordingMessage,
  checkIfLimitReached,
  handlePointerDown,
  handlePointerUp,
  onToggleTextInput,
}: RealtimeWebRTCUIProps) {
  // Seul un dataChannel ouvert + session active autorisent le push-to-talk
  const canPushToTalk = isSessionActive && dataChannel?.readyState === 'open';

  /**
   * handleMicPointerDownWithCapture:
   *  1) Vérifie la limite,
   *  2) setPointerCapture pour maintenir pointerUp,
   *  3) appelle handlePointerDown().
   */
  const handleMicPointerDownWithCapture = (evt: React.PointerEvent<HTMLButtonElement>) => {
    if (checkIfLimitReached()) {
      console.warn('[RealtimeWebRTCUI] Limit reached => microphone blocked');
      return;
    }
    // On capture le pointer => pointerUp fonctionnera même hors du bouton
    evt.currentTarget.setPointerCapture(evt.pointerId);

    handlePointerDown();
  };

  /**
   * handleMicPointerUpWithRelease:
   *   - releasePointerCapture => handlePointerUp().
   */
  const handleMicPointerUpWithRelease = (evt: React.PointerEvent<HTMLButtonElement>) => {
    evt.currentTarget.releasePointerCapture(evt.pointerId);
    handlePointerUp();
  };

  /**
   * handleMicPointerCancel:
   *   - Sur pointerCancel => on arrête l’enregistrement
   */
  const handleMicPointerCancel = (evt: React.PointerEvent<HTMLButtonElement>) => {
    evt.currentTarget.releasePointerCapture(evt.pointerId);
    handlePointerUp();
  };

  return (
    <div style={{ position: 'relative', minHeight: 150 }}>
      {/* (1) Infobulle si l'enregistrement est trop court */}
      {shortRecordingMessage && (
        <div
          className="
            fixed
            top-1/2 left-1/2
            -translate-x-1/2 -translate-y-1/2
            z-50
            text-center
          "
        >
          <div
            className="
              inline-block
              p-4
              bg-red-100 text-red-800 font-semibold
              shadow-lg
            "
          >
            {shortRecordingMessage}
          </div>
        </div>
      )}

      {/* (2) Barre de contrôle (micro + clavier) */}
      {isSessionActive && (
        <div
          style={{
            position: 'absolute',
            bottom: 16,
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            // [AJOUT] Décalage vers la droite (~0.5cm ≈ 20px)
            transform: 'translateX(20px)', 
          }}
        >
          {/* Bouton micro (push-to-talk) */}
          <button
            disabled={!canPushToTalk}
            onPointerDown={handleMicPointerDownWithCapture}
            onPointerUp={handleMicPointerUpWithRelease}
            onPointerCancel={handleMicPointerCancel}
            onContextMenu={(e) => e.preventDefault()}
            className={`
              select-none border-0 rounded-full
              inline-flex items-center justify-center
              w-20 h-20 p-3
              ${
                isRecording
                  ? 'bg-red-600'
                  : !canPushToTalk
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-500'
              }
            `}
            style={{ touchAction: 'none' }}
            aria-label="Microphone"
          >
            <Mic className="text-white w-9 h-9" />
          </button>

          {/* Bouton clavier (optionnel) */}
          {onToggleTextInput && (
            <button
              type="button"
              onClick={onToggleTextInput}
              className="
                h-12 w-12
                flex items-center justify-center
                bg-white text-gray-800
                rounded-full
                shadow
                hover:bg-gray-100
                transition
              "
              aria-label="Clavier"
            >
              <img
                src="/icons/keyboard-icon.png"
                alt="Clavier"
                className="w-6 h-6"
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
}