// src/components/Layout.tsx (NOUVEAU)
import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
  /** 
   * Si on passe noMaxWidth à true, on enlève le max-w-screen-lg
   * et on laisse la page prendre toute la largeur.
   */
  noMaxWidth?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  noMaxWidth = false,
}) => {
  return (
    // Retiré items-center pour éviter le centrage vertical forcé
    <div className="flex flex-col min-h-screen w-screen overflow-x-hidden">
      {/* Optionnel : un header ici */}

      {/* 
        On fait en sorte que le <main> puisse s’étendre et scroller. 
        Retiré le flex + justify-center, remplacé par overflow-y-auto. 
      */}
      <main className="flex-1 w-full overflow-y-auto">
        {/* 
          On gère la largeur max ou non, comme avant 
        */}
        <div className={noMaxWidth ? 'w-full px-4' : 'max-w-screen-lg mx-auto px-4'}>
          {children}
        </div>
      </main>

      {/* Optionnel : un footer ici */}
    </div>
  );
};