// src/pages/ConsolePage/RealtimeLogViewer.tsx
import React from 'react';

interface RealtimeLogViewerProps {
  events: any[];
}

export function RealtimeLogViewer({ events }: RealtimeLogViewerProps) {
  return (
    <div
      className="
        absolute left-0 right-0 bottom-0
        h-64
        bg-black text-white
        overflow-auto
        p-2 text-xs
        z-50
      "
    >
      <h3 className="font-bold mb-1">Realtime Events</h3>
      <div className="space-y-2">
        {events.map((evt, idx) => (
          <pre
            key={idx}
            className="bg-black bg-opacity-25 px-2 py-1 border border-white/20 rounded"
            style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
          >
            {JSON.stringify(evt, null, 2)}
          </pre>
        ))}
      </div>
    </div>
  );
}