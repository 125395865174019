// /src/pages/ConsolePage/IcebreakerButtons.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStudy } from '../../context/StudyContext';
import { getCultureData } from '../../utils/cultureMap';

type IcebreakerButtonsProps = {
  onSelect: (category: string) => void;
};

/**
 * renderEmojiLabel :
 *   - Ex : "😄 Funny" => on sépare en "😄" et "Funny",
 *   - On agrandit l'emoji (fontSize: 1.3em) et on lui met un margin-right,
 *   - On laisse le texte normal.
 */
function renderEmojiLabel(fullLabel: string) {
  const parts = fullLabel.split(' ');
  if (parts.length === 1) {
    return fullLabel;
  }

  const emojiPart = parts[0];
  const textPart = parts.slice(1).join(' ');

  return (
    <span className="inline-flex items-center">
      <span style={{ fontSize: '1.3em', marginRight: '0.35em' }}>
        {emojiPart}
      </span>
      <span>{textPart}</span>
    </span>
  );
}

/**
 * renderCultureEmoji :
 *   - Pour le bouton Culture, on assemble "cultureEmoji" (agrandi)
 *     et le "cultureLabel" normalement.
 */
function renderCultureEmoji(cultureEmoji: string, cultureLabel: string) {
  return (
    <span className="inline-flex items-center">
      <span style={{ fontSize: '1.3em', marginRight: '0.35em' }}>
        {cultureEmoji}
      </span>
      <span>{cultureLabel}</span>
    </span>
  );
}

export function IcebreakerButtons({ onSelect }: IcebreakerButtonsProps) {
  const { t } = useTranslation('icebreaker');
  const { studyLanguage } = useStudy();

  // Récupère le texte + drapeau pour le bouton “culture”
  const { name: rawCultureName, emoji: cultureEmoji } = getCultureData(studyLanguage);
  const cultureButtonLabel =
    rawCultureName.charAt(0).toUpperCase() + rawCultureName.slice(1);

  // On récupère les labels depuis i18n (par ex. "😄 Funny")
  const funLabel = t('funButton', { lng: studyLanguage });
  const interestingLabel = t('interestingButton', { lng: studyLanguage });
  const randomLabel = t('randomButton', { lng: studyLanguage });

  return (
    <div className="mb-4 mx-4 lg:container lg:mx-auto lg:px-52 xl:px-96">
      {/*
        - On limite la largeur globale,
        - On crée une grille 2×2 en dessous,
        - On la déplace de -0.5cm vers la gauche (transform).
      */}
      <div
        className="
          grid
          grid-cols-2
          grid-rows-2
          gap-3
          w-full
          max-w-[450px]
          mx-auto
        "
        style={{
          gridAutoRows: '1fr',
          transform: 'translateX(-0.5cm)', // Décalage vers la gauche
        }}
      >
        {/* (1) FUN */}
        <button
          className="
            border border-[#333]
            bg-white
            text-gray-800 font-bold
            shadow-sm
            flex items-center justify-center
            p-4
            rounded-2xl
            whitespace-nowrap
            transition-all
            hover:-translate-y-0.5 hover:shadow-md hover:bg-gray-50
            w-full h-full
            leading-snug
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.2rem]
            lg:text-[1.4rem]
          "
          onClick={() => onSelect('fun')}
        >
          {renderEmojiLabel(funLabel)}
        </button>

        {/* (2) INTERESTING */}
        <button
          className="
            border border-[#333]
            bg-white
            text-gray-800 font-bold
            shadow-sm
            flex items-center justify-center
            p-4
            rounded-2xl
            whitespace-nowrap
            transition-all
            hover:-translate-y-0.5 hover:shadow-md hover:bg-gray-50
            w-full h-full
            leading-snug
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.2rem]
            lg:text-[1.4rem]
          "
          onClick={() => onSelect('interesting')}
        >
          {renderEmojiLabel(interestingLabel)}
        </button>

        {/* (3) CULTURE => drapeau + label sur la même ligne */}
        <button
          className="
            border border-[#333]
            bg-white
            text-gray-800 font-bold
            shadow-sm
            flex items-center justify-center
            gap-2
            p-4
            rounded-2xl
            whitespace-nowrap
            transition-all
            hover:-translate-y-0.5 hover:shadow-md hover:bg-gray-50
            w-full h-full
            leading-snug
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.2rem]
            lg:text-[1.4rem]
          "
          onClick={() => onSelect('culture')}
        >
          {renderCultureEmoji(cultureEmoji, cultureButtonLabel)}
        </button>

        {/* (4) RANDOM */}
        <button
          className="
            border border-[#333]
            bg-white
            text-gray-800 font-bold
            shadow-sm
            flex items-center justify-center
            p-4
            rounded-2xl
            whitespace-nowrap
            transition-all
            hover:-translate-y-0.5 hover:shadow-md hover:bg-gray-50
            w-full h-full
            leading-snug
            text-[1.05rem]
            sm:text-[1.1rem]
            md:text-[1.2rem]
            lg:text-[1.4rem]
          "
          onClick={() => onSelect('random')}
        >
          {renderEmojiLabel(randomLabel)}
        </button>
      </div>
    </div>
  );
}

export default IcebreakerButtons;