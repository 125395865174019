//voxanet/src/pages/ConversationScenarioSelection/scenariosList.ts

export const scenarios = [
  // 1) "Conversation Libre" en premier
  {
    id: 'free_chat',
    emoji: '💬',
    titleKey: 'conversationScenarioSelection.scenarios.freeChat.title',
    descriptionKey: 'conversationScenarioSelection.scenarios.freeChat.description',
  },
  
  // 2) Ensuite le reste :
  {
    id: 'cafe', 
    emoji: '☕️',
    titleKey: 'conversationScenarioSelection.scenarios.cafe.title',
    descriptionKey: 'conversationScenarioSelection.scenarios.cafe.description',
  },
    {
      id: 'airport',
      emoji: '✈️',
      titleKey: 'conversationScenarioSelection.scenarios.airport.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.airport.description',
    },
    {
      id: 'restaurant',
      emoji: '🍽️',
      titleKey: 'conversationScenarioSelection.scenarios.restaurant.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.restaurant.description',
    },
    {
      id: 'hotel',
      emoji: '🏨',
      titleKey: 'conversationScenarioSelection.scenarios.hotel.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.hotel.description',
    },
    {
      id: 'shopping',
      emoji: '🛍️',
      titleKey: 'conversationScenarioSelection.scenarios.shopping.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.shopping.description',
    },
    {
      id: 'doctor',
      emoji: '🩺',
      titleKey: 'conversationScenarioSelection.scenarios.doctor.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.doctor.description',
    },
    {
      id: 'make_new_friend',
      emoji: '👫',
      titleKey: 'conversationScenarioSelection.scenarios.makeNewFriend.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.makeNewFriend.description',
    },
    {
      id: 'friday_night',
      emoji: '🌃',
      titleKey: 'conversationScenarioSelection.scenarios.fridayNight.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.fridayNight.description',
    },
    {
      id: 'pizzeria',
      emoji: '🍕',
      titleKey: 'conversationScenarioSelection.scenarios.pizzeria.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.pizzeria.description',
    },
    {
      id: 'friends_birthday',
      emoji: '🎉',
      titleKey: 'conversationScenarioSelection.scenarios.friendsBirthday.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.friendsBirthday.description',
    },
    {
      id: 'cocktail_bar',
      emoji: '🍹',
      titleKey: 'conversationScenarioSelection.scenarios.cocktailBar.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.cocktailBar.description',
    },
    {
      id: 'hilton_hotel',
      emoji: '🏨',
      titleKey: 'conversationScenarioSelection.scenarios.hiltonHotel.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.hiltonHotel.description',
    },
    {
      id: 'order_pizza',
      emoji: '📞🍕',
      titleKey: 'conversationScenarioSelection.scenarios.orderPizza.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.orderPizza.description',
    },
    {
      id: 'colleagues_at_work',
      emoji: '👔',
      titleKey: 'conversationScenarioSelection.scenarios.colleaguesAtWork.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.colleaguesAtWork.description',
    },
    {
      id: 'saturday',
      emoji: '📅',
      titleKey: 'conversationScenarioSelection.scenarios.saturday.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.saturday.description',
    },
    {
      id: 'talk_to_taxi_driver',
      emoji: '🚕',
      titleKey: 'conversationScenarioSelection.scenarios.talkToTaxiDriver.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.talkToTaxiDriver.description',
    },
    {
      id: 'buy_clothes',
      emoji: '👗',
      titleKey: 'conversationScenarioSelection.scenarios.buyClothes.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.buyClothes.description',
    },
    {
      id: 'buy_sim_card',
      emoji: '📱',
      titleKey: 'conversationScenarioSelection.scenarios.buySimCard.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.buySimCard.description',
    },
    {
      id: 'flirt_with_someone',
      emoji: '❤️',
      titleKey: 'conversationScenarioSelection.scenarios.flirtWithSomeone.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.flirtWithSomeone.description',
    },
    {
      id: 'choose_movie',
      emoji: '🎬',
      titleKey: 'conversationScenarioSelection.scenarios.chooseMovie.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.chooseMovie.description',
    },
    {
      id: 'ask_local_advice',
      emoji: '🗺️',
      titleKey: 'conversationScenarioSelection.scenarios.askLocalAdvice.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.askLocalAdvice.description',
    },
    {
      id: 'order_takeaway',
      emoji: '🥡',
      titleKey: 'conversationScenarioSelection.scenarios.orderTakeaway.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.orderTakeaway.description',
    },
    {
      id: 'return_product',
      emoji: '🔄',
      titleKey: 'conversationScenarioSelection.scenarios.returnProduct.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.returnProduct.description',
    },
    {
      id: 'at_the_beach',
      emoji: '🏖️',
      titleKey: 'conversationScenarioSelection.scenarios.atTheBeach.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.atTheBeach.description',
    },
    {
      id: 'at_the_hairdresser',
      emoji: '💇',
      titleKey: 'conversationScenarioSelection.scenarios.atTheHairdresser.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.atTheHairdresser.description',
    },
    {
      id: 'open_bank_account',
      emoji: '🏦',
      titleKey: 'conversationScenarioSelection.scenarios.openBankAccount.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.openBankAccount.description',
    },
    {
      id: 'negotiate_price',
      emoji: '💰',
      titleKey: 'conversationScenarioSelection.scenarios.negotiatePrice.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.negotiatePrice.description',
    },
    {
      id: 'join_gym',
      emoji: '🏋️',
      titleKey: 'conversationScenarioSelection.scenarios.joinGym.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.joinGym.description',
    },
    {
      id: 'plan_movie_night',
      emoji: '🎥',
      titleKey: 'conversationScenarioSelection.scenarios.planMovieNight.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.planMovieNight.description',
    },
    {
      id: 'make_shopping_list',
      emoji: '📝',
      titleKey: 'conversationScenarioSelection.scenarios.makeShoppingList.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.makeShoppingList.description',
    },
    {
      id: 'talk_about_music',
      emoji: '🎵',
      titleKey: 'conversationScenarioSelection.scenarios.talkAboutMusic.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.talkAboutMusic.description',
    },
    {
      id: 'choose_concert',
      emoji: '🎤',
      titleKey: 'conversationScenarioSelection.scenarios.chooseConcert.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.chooseConcert.description',
    },
    {
      id: 'plan_wedding',
      emoji: '💍',
      titleKey: 'conversationScenarioSelection.scenarios.planWedding.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.planWedding.description',
    },
    {
      id: 'nightclub',
      emoji: '💃',
      titleKey: 'conversationScenarioSelection.scenarios.nightclub.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.nightclub.description',
    },
    {
      id: 'talk_about_food',
      emoji: '🍲',
      titleKey: 'conversationScenarioSelection.scenarios.talkAboutFood.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.talkAboutFood.description',
    },
    {
      id: 'meet_someone',
      emoji: '🤝',
      titleKey: 'conversationScenarioSelection.scenarios.meetSomeone.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.meetSomeone.description',
    },
    {
      id: 'file_complaint',
      emoji: '📝',
      titleKey: 'conversationScenarioSelection.scenarios.fileComplaint.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.fileComplaint.description',
    },
    {
      id: 'talk_with_language_teacher',
      emoji: '👩‍🏫',
      titleKey: 'conversationScenarioSelection.scenarios.talkWithLanguageTeacher.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.talkWithLanguageTeacher.description',
    },
    {
      id: 'healthy_eating',
      emoji: '🥗',
      titleKey: 'conversationScenarioSelection.scenarios.healthyEating.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.healthyEating.description',
    },
    {
      id: 'supermarket',
      emoji: '🛒',
      titleKey: 'conversationScenarioSelection.scenarios.supermarket.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.supermarket.description',
    },
    {
      id: 'bakery',
      emoji: '🥖',
      titleKey: 'conversationScenarioSelection.scenarios.bakery.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.bakery.description',
    },
    {
      id: 'cooking',
      emoji: '👩‍🍳',
      titleKey: 'conversationScenarioSelection.scenarios.cooking.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.cooking.description',
    },
    {
      id: 'wine_tasting',
      emoji: '🍷',
      titleKey: 'conversationScenarioSelection.scenarios.wineTasting.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.wineTasting.description',
    },
    {
      id: 'train_station',
      emoji: '🚆',
      titleKey: 'conversationScenarioSelection.scenarios.trainStation.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.trainStation.description',
    },
    {
      id: 'customs_immigration',
      emoji: '🛃',
      titleKey: 'conversationScenarioSelection.scenarios.customsImmigration.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.customsImmigration.description',
    },
    {
      id: 'car_rental',
      emoji: '🚗',
      titleKey: 'conversationScenarioSelection.scenarios.carRental.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.carRental.description',
    },
    {
      id: 'medical_emergency',
      emoji: '🚑',
      titleKey: 'conversationScenarioSelection.scenarios.medicalEmergency.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.medicalEmergency.description',
    },
    {
      id: 'pharmacy',
      emoji: '💊',
      titleKey: 'conversationScenarioSelection.scenarios.pharmacy.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.pharmacy.description',
    },
    {
      id: 'first_day_class',
      emoji: '🎒',
      titleKey: 'conversationScenarioSelection.scenarios.firstDayClass.title',
      descriptionKey: 'conversationScenarioSelection.scenarios.firstDayClass.description',
    },
  ];
  