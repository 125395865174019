// src/constants/levels.ts

// Pour réduire la duplication, on peut n'en garder qu'une forme plus générique :
// (Par exemple, on stocke juste les indices [0,1,2,3,4], 
// et on dérive labelKey/descriptionKey quand on s'en sert.)
//
// Mais si vous préférez garder la structure "labelKey" & "descriptionKey"
// directement dans constants, vous pouvez faire un tableau d'objets complet
// et gérer la différence "value" vs "level" au moment de l'affichage.

export const LEVELS = [0, 1, 2, 3, 4];