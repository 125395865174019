// src/context/TotalMessageCountContext.tsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import { getApiUrl } from '../config/environment';
import { useAuth } from './AuthContext'; // Pour accéder à currentUser

interface TotalMessageCountContextType {
  totalMessageCount: number;
  refreshTotalMessageCount: () => Promise<number | undefined>; // Retourne la nouvelle valeur ou undefined en cas d'erreur
}

const TotalMessageCountContext = createContext<TotalMessageCountContextType>({
  totalMessageCount: 0,
  refreshTotalMessageCount: async () => 0,
});

// (NOUVEAU LOG) - Indique la création du Provider
console.log('[TotalMessageCountContext] (NOUVEAU LOG) Création du TotalMessageCountProvider');

export const TotalMessageCountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [totalMessageCount, setTotalMessageCount] = useState<number>(0);
  const { currentUser } = useAuth();

  // (NOUVEAU LOG) - Affichage initial de totalMessageCount
  console.log('[TotalMessageCountContext] (NOUVEAU LOG) État initial:', {
    totalMessageCount,
  });

  /**
   * Récupère le totalMessageCount depuis le backend.
   * Retourne la nouvelle valeur ou undefined en cas d'erreur.
   */
  const fetchTotalMessageCount = async (): Promise<number | undefined> => {
    try {
      if (!currentUser) {
        console.warn('[TotalMessageCountContext] (NOUVEAU LOG) Utilisateur non authentifié => pas de requête au backend');
        return 0;
      }

      // (NOUVEAU LOG) - Juste avant l’appel à l’endpoint getMessageCount
      console.log('[TotalMessageCountContext] (NOUVEAU LOG) Appel de /getMessageCount =>', {
        uid: currentUser.uid,
        endpoint: `${getApiUrl()}/api/user/getMessageCount?uid=${currentUser.uid}`,
      });

      const response = await fetch(`${getApiUrl()}/api/user/getMessageCount?uid=${currentUser.uid}`);

      // (NOUVEAU LOG) - Réception de la réponse brute
      console.log('[TotalMessageCountContext] (NOUVEAU LOG) Réponse brute de /getMessageCount =>', {
        status: response.status,
        statusText: response.statusText,
      });

      if (response.status === 404) {
        console.warn('[TotalMessageCountContext] (NOUVEAU LOG) Utilisateur non trouvé, totalMessageCount défini à 0');
        setTotalMessageCount(0);
        return 0;
      }

      const data = await response.json();

      // (NOUVEAU LOG) - Contenu JSON après parse
      console.log('[TotalMessageCountContext] (NOUVEAU LOG) Data JSON de /getMessageCount =>', data);

      if (response.ok) {
        console.log('[TotalMessageCountContext] totalMessageCount récupéré avec succès =>', data.totalMessageCount);
        setTotalMessageCount(data.totalMessageCount);
        return data.totalMessageCount;
      } else {
        console.error(
          '[TotalMessageCountContext] Erreur lors de la récupération du totalMessageCount:',
          data.error
        );
        // (NOUVEAU LOG) - On log la valeur courante quand on échoue à mettre à jour
        console.log('[TotalMessageCountContext] (NOUVEAU LOG) On conserve l’ancienne valeur =>', totalMessageCount);
        return totalMessageCount; // Renvoie la valeur actuelle en cas d'erreur
      }
    } catch (error) {
      console.error('[TotalMessageCountContext] Erreur lors de la récupération du totalMessageCount:', error);
      console.log('[TotalMessageCountContext] (NOUVEAU LOG) On conserve l’ancienne valeur =>', totalMessageCount);
      return totalMessageCount; // Renvoie la valeur actuelle en cas d'erreur
    }
  };

  useEffect(() => {
    // Récupère le totalMessageCount au montage du composant ou lorsque currentUser change
    if (currentUser) {
      console.log('[TotalMessageCountContext] (NOUVEAU LOG) useEffect => currentUser détecté => fetchTotalMessageCount');
      fetchTotalMessageCount();
    } else {
      console.log('[TotalMessageCountContext] (NOUVEAU LOG) useEffect => pas de currentUser => pas de fetch');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  /**
   * Méthode publique pour forcer la mise à jour du totalMessageCount.
   */
  const refreshTotalMessageCount = async (): Promise<number | undefined> => {
    console.log('[TotalMessageCountContext] (NOUVEAU LOG) refreshTotalMessageCount() appelé');
    return await fetchTotalMessageCount();
  };

  // (NOUVEAU LOG) - Juste avant le return, on montre l’état courant
  console.log('[TotalMessageCountContext] (NOUVEAU LOG) Juste avant return =>', {
    totalMessageCount,
  });

  return (
    <TotalMessageCountContext.Provider value={{ totalMessageCount, refreshTotalMessageCount }}>
      {children}
    </TotalMessageCountContext.Provider>
  );
};

export const useTotalMessageCount = () => useContext(TotalMessageCountContext);