// -----------------------------------------------------------------------
// src/components/RealtimeVisualizer.tsx
// -----------------------------------------------------------------------
import React, { useRef, useEffect, useState } from 'react';
import { WavRenderer } from '../utils/wav_renderer';

type RealtimeVisualizerProps = {
  localAnalyser: AnalyserNode | null;
  remoteAnalyser: AnalyserNode | null;
  /** Callback quand on détecte ≥ 1000ms de silence après que l’assistant ait parlé. */
  onAssistantSilence?: () => void;
};

export function RealtimeVisualizer({
  localAnalyser,
  remoteAnalyser,
  onAssistantSilence,
}: RealtimeVisualizerProps) {
  const localCanvasRef = useRef<HTMLCanvasElement>(null);
  const remoteCanvasRef = useRef<HTMLCanvasElement>(null);

  // Pour lisser l’affichage
  const localPrevArrayRef = useRef<number[]>([]);
  const remotePrevArrayRef = useRef<number[]>([]);

  // Nombre de barres
  const NUMBER_OF_BARS = 6;

  // Filtrage des extrêmes
  const LOWER_PERCENT = 0.25;
  const UPPER_PERCENT = 0.75;

  // Détection de silence
  const lastFrameTimeRef = useRef(performance.now());
  const silenceMsRef = useRef(0);
  const [alreadyCalledSilence, setAlreadyCalledSilence] = useState(false);

  // **NOUVEAU** : booléen pour savoir si l’assistant a jamais parlé
  const [assistantHasSpoken, setAssistantHasSpoken] = useState(false);

  // Paramètres
  const amplitudeThreshold = 0.02;     // en dessous => silence
  const SILENCE_REQUIRED_MS = 1000;    // 1 seconde

  useEffect(() => {
    let isMounted = true;

    if (!localAnalyser && !remoteAnalyser) return;

    // freqBinCount => tampons
    const localBinCount = localAnalyser?.frequencyBinCount ?? 0;
    const remoteBinCount = remoteAnalyser?.frequencyBinCount ?? 0;
    const localFreqData = localBinCount > 0 ? new Uint8Array(localBinCount) : null;
    const remoteFreqData = remoteBinCount > 0 ? new Uint8Array(remoteBinCount) : null;

    // -----------------------------------------------------
    // computeBars : calcule le “profil de barres”
    // -----------------------------------------------------
    function computeBars(
      freqData: Uint8Array,
      prevArray: number[],
      startIndex: number,
      endIndex: number,
      smoothing = 0.7
    ): number[] {
      const barValues = new Array(NUMBER_OF_BARS).fill(0);
      const totalBins = endIndex - startIndex;
      const binSize = Math.floor(totalBins / NUMBER_OF_BARS);

      for (let i = 0; i < NUMBER_OF_BARS; i++) {
        let sum = 0;
        let count = 0;
        const binStart = startIndex + i * binSize;
        // la dernière barre va jusqu'à endIndex
        const binEnd = (i < NUMBER_OF_BARS - 1) ? binStart + binSize : endIndex;

        for (let j = binStart; j < binEnd; j++) {
          sum += freqData[j];
          count++;
        }
        const avg = count > 0 ? sum / count : 0;

        // Exponential smoothing
        const oldVal = prevArray[i] ?? 0;
        const newVal = avg * (1 - smoothing) + oldVal * smoothing;
        barValues[i] = newVal;
      }
      return barValues;
    }

    // -----------------------------------------------------
    // centerPeak : fait tourner le tableau pour que la barre max soit au centre
    // -----------------------------------------------------
    function centerPeak(barValues: number[]): number[] {
      if (!barValues.length) return barValues;
      const arr = [...barValues];
      const maxIndex = arr.indexOf(Math.max(...arr));
      const middle = Math.floor(arr.length / 2);
      const shift = middle - maxIndex;
      if (shift === 0) return arr;

      const rotated = new Array(arr.length);
      for (let i = 0; i < arr.length; i++) {
        const newIndex = (i + shift + arr.length) % arr.length;
        rotated[newIndex] = arr[i];
      }
      return rotated;
    }

    // -----------------------------------------------------
    // renderFrame : raf
    // -----------------------------------------------------
    function renderFrame() {
      if (!isMounted) return;
      const now = performance.now();
      const dt = now - lastFrameTimeRef.current;
      lastFrameTimeRef.current = now;

      // ---------- LOCAL (micro) ----------
      if (localAnalyser && localFreqData && localCanvasRef.current) {
        const ctx = localCanvasRef.current.getContext('2d');
        if (!ctx) return;
        const canvas = localCanvasRef.current;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        localAnalyser.getByteFrequencyData(localFreqData);

        const startLocal = Math.floor(localFreqData.length * LOWER_PERCENT);
        const endLocal = Math.floor(localFreqData.length * UPPER_PERCENT);

        let localBars = computeBars(localFreqData, localPrevArrayRef.current, startLocal, endLocal, 0.7);
        localBars = centerPeak(localBars);
        localPrevArrayRef.current = localBars;

        // Normaliser en [0..2]
        const localFloatArray = new Float32Array(localBars.length);
        for (let i = 0; i < localBars.length; i++) {
          let val = (localBars[i] / 255) * 2;
          if (val > 1) val = 1;
          localFloatArray[i] = val;
        }

        WavRenderer.drawBars(
          canvas,
          ctx,
          localFloatArray,
          '#0099ff',
          localFloatArray.length,
          0,
          4,
          false
        );
      }

      // ---------- REMOTE (assistant) ----------
      if (remoteAnalyser && remoteFreqData && remoteCanvasRef.current) {
        const ctx = remoteCanvasRef.current.getContext('2d');
        if (!ctx) return;
        const canvas = remoteCanvasRef.current;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        remoteAnalyser.getByteFrequencyData(remoteFreqData);

        const startRemote = Math.floor(remoteFreqData.length * LOWER_PERCENT);
        const endRemote = Math.floor(remoteFreqData.length * UPPER_PERCENT);

        let remoteBars = computeBars(remoteFreqData, remotePrevArrayRef.current, startRemote, endRemote, 0.7);
        remoteBars = centerPeak(remoteBars);
        remotePrevArrayRef.current = remoteBars;

        const remoteFloatArray = new Float32Array(remoteBars.length);
        for (let i = 0; i < remoteBars.length; i++) {
          let val = (remoteBars[i] / 255) * 2;
          if (val > 1) val = 1;
          remoteFloatArray[i] = val;
        }

        WavRenderer.drawBars(
          canvas,
          ctx,
          remoteFloatArray,
          '#009900', // assistant color
          remoteFloatArray.length,
          0,
          4,
          false
        );

        // 1) amplitude moyenne => si > threshold => l'assistant “parle”
        const avgAmplitude = remoteFloatArray.reduce((sum, v) => sum + v, 0) / remoteFloatArray.length;

        if (avgAmplitude > amplitudeThreshold) {
          // => assistant est audible
          // => on retient qu’il a parlé
          if (!assistantHasSpoken) {
            setAssistantHasSpoken(true);
          }
          // reset le compteur de silence
          silenceMsRef.current = 0;
          setAlreadyCalledSilence(false);

        } else {
          // => amplitude <= threshold => potentiellement silence
          // => seulement si l'assistant a déjà parlé
          if (assistantHasSpoken && !alreadyCalledSilence) {
            silenceMsRef.current += dt;
            if (silenceMsRef.current >= SILENCE_REQUIRED_MS) {
              // => 1s de silence => callback
              setAlreadyCalledSilence(true);
              silenceMsRef.current = 0;

              if (onAssistantSilence) {
                onAssistantSilence();
              }
            }
          }
        }
      }

      requestAnimationFrame(renderFrame);
    }

    requestAnimationFrame(renderFrame);

    return () => {
      isMounted = false;
    };
  }, [
    localAnalyser,
    remoteAnalyser,
    onAssistantSilence,
    assistantHasSpoken,
    alreadyCalledSilence
  ]);

  return (
    <div className="flex justify-center p-1 rounded-lg gap-0.5 w-full">
      <canvas
        ref={localCanvasRef}
        className="w-[120px] h-[40px] sm:w-[100px] sm:h-[30px]"
      />
      <canvas
        ref={remoteCanvasRef}
        className="w-[120px] h-[40px] sm:w-[100px] sm:h-[30px]"
      />
    </div>
  );
}