// src/pages/SettingsPage.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { useLanguage } from '../context/LanguageContext';
import { useStudy } from '../context/StudyContext';
import LanguageSelector from '../components/LanguageSelector';
import LevelSelector from '../components/LevelSelector';
import { updateUserSettings } from '../services/userService';
import CloseButton from '../components/CloseButton';

export const SettingsPage: React.FC = () => {
  // On cible le namespace "settingsPage"
  const { t: tSettings } = useTranslation('settingsPage');

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const {
    nativeLanguageSelected,
    setNativeLanguageSelected,
  } = useLanguage();

  const {
    studyLanguage,
    setStudyLanguage,
    studyLevel,
    setStudyLevel,
  } = useStudy();

  // Si la langue d’étude ou le niveau ne sont pas encore définis, afficher un loader
  if (!studyLanguage || studyLevel === null) {
    return (
      <Layout>
        <div className="flex items-center justify-center min-h-screen">
          <p>{tSettings('loading')}</p>
        </div>
      </Layout>
    );
  }

  // Callbacks pour mettre à jour en base
  const handleNativeLanguageChange = async (language: string) => {
    if (!currentUser) return;
    setNativeLanguageSelected(language);
    await updateUserSettings(currentUser.uid, { nativeLanguageSelected: language });
  };

  const handleStudyLanguageChange = async (language: string) => {
    if (!currentUser) return;
    setStudyLanguage(language);
    await updateUserSettings(currentUser.uid, { studyLanguage: language });
  };

  const handleStudyLevelChange = async (level: number) => {
    if (!currentUser) return;
    setStudyLevel(level);
    await updateUserSettings(currentUser.uid, {
      studyLanguage,
      studyLevel: level,
    });
  };

  return (
    <Layout>
      <div className="relative w-full min-h-screen flex flex-col items-center justify-center px-4">
        {/* Bouton fermer (croix) en position fixe en haut à gauche */}
        <div className="fixed top-4 left-4">
          <CloseButton onClick={() => navigate(-1)} />
        </div>

        {/* Contenu principal (max-w-xl) */}
        <div className="w-full max-w-xl flex flex-col items-center">
          {/* Titre principal “Paramètres” */}
          <h1
            className="
              text-[2rem]
              sm:text-[2.4rem]
              md:text-[3.3rem]
              font-extrabold
              text-[#243238]
              leading-tight
              text-center
              mb-8
            "
          >
            {tSettings('title')}
          </h1>

          {/* Liste des sections */}
          <div className="w-full flex flex-col gap-8">
            {/* (1) Langue maternelle */}
            <div className="flex flex-col items-center">
              <LanguageSelector
                selectedLanguage={nativeLanguageSelected || ''}
                onLanguageSelect={handleNativeLanguageChange}
                label={tSettings('nativeLanguage')}
              />
            </div>

            {/* (2) Langue d'étude */}
            <div className="flex flex-col items-center">
              <LanguageSelector
                selectedLanguage={studyLanguage}
                onLanguageSelect={handleStudyLanguageChange}
                label={tSettings('studyLanguage')}
              />
            </div>

            {/* (3) Niveau d'étude */}
            <div className="flex flex-col items-center">
              <LevelSelector
                selectedLevel={studyLevel}
                onLevelSelect={handleStudyLevelChange}
                label={tSettings('studyLevel')}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SettingsPage;