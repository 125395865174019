// src/hooks/useRealtimeWebRTC/useDataChannelListener.ts
import { useEffect } from "react";

interface DataChannelListenerParams {
  dataChannel: RTCDataChannel | null;
  onRealtimeEvent?: (evt: any) => void;
  showDebug: boolean;
  setEvents: React.Dispatch<React.SetStateAction<any[]>>;
  setLastResponseId: (id: string | null) => void;
  setLastAssistantItemId: (id: string | null) => void;
}

export function useDataChannelListener({
  dataChannel,
  onRealtimeEvent,
  showDebug,
  setEvents,
  setLastResponseId,
  setLastAssistantItemId,
}: DataChannelListenerParams) {
  useEffect(() => {
    if (!dataChannel) return;

    function handleMessage(evt: MessageEvent) {
      try {
        const parsed = JSON.parse(evt.data);
        onRealtimeEvent?.(parsed);
        setEvents((prev) => [parsed, ...prev]);

        if (showDebug) {
          console.log("[useDataChannelListener] =>", parsed);
        } else {
          console.log("[useDataChannelListener] => type:", parsed.type);
        }

        // (1) response.created => mémoriser l'id
        if (parsed.type === "response.created") {
          const respId = parsed.response?.id;
          if (respId) {
            setLastResponseId(respId);
            console.log("[useDataChannelListener] => new response =>", respId);
          }
        }

        // (2) response.done => reset lastResponseId
        if (parsed.type === "response.done") {
          setLastResponseId(null);
        }

        // (3) output_item.added => item assistant => mémoriser l'id
        if (parsed.type === "response.output_item.added") {
          const item = parsed.item;
          if (item?.role === "assistant") {
            setLastAssistantItemId(item.id);
          }
        }
      } catch (err) {
        console.warn("[useDataChannelListener] => Could not parse JSON =>", evt.data);
      }
    }

    dataChannel.addEventListener("message", handleMessage);
    return () => {
      dataChannel.removeEventListener("message", handleMessage);
    };
  }, [
    dataChannel,
    onRealtimeEvent,
    showDebug,
    setEvents,
    setLastResponseId,
    setLastAssistantItemId,
  ]);
}