// src/hooks/useRealtimeWebRTC/startSession.ts

import { handleDataChannelOpen } from "./handleDataChannelOpen";

interface StartSessionParams {
  setIsConnecting: (b: boolean) => void;
  onConnectingChange?: (b: boolean) => void;
  peerConnection: React.MutableRefObject<RTCPeerConnection | null>;
  audioElement: React.MutableRefObject<HTMLAudioElement | null>;
  localAudioContextRef: React.MutableRefObject<AudioContext | null>;
  localAnalyserRef: React.MutableRefObject<AnalyserNode | null>;
  remoteAudioContextRef: React.MutableRefObject<AudioContext | null>;
  remoteAnalyserRef: React.MutableRefObject<AnalyserNode | null>;
  setLocalAnalyser: (a: AnalyserNode | null) => void;
  setRemoteAnalyser: (a: AnalyserNode | null) => void;
  setDataChannel: (dc: RTCDataChannel | null) => void;
  setIsSessionActive: (b: boolean) => void;
  initialInstructions: string;
  onAnalyzersReady?: (local: AnalyserNode | null, remote: AnalyserNode | null) => void;
  showDebug: boolean;

  // Pour stocker les events reçus ou internes
  setEvents: React.Dispatch<React.SetStateAction<any[]>>;
}

export async function startSession(params: StartSessionParams) {
  const {
    setIsConnecting,
    onConnectingChange,
    peerConnection,
    audioElement,
    localAudioContextRef,
    localAnalyserRef,
    remoteAudioContextRef,
    remoteAnalyserRef,
    setLocalAnalyser,
    setRemoteAnalyser,
    setDataChannel,
    setIsSessionActive,
    initialInstructions,
    onAnalyzersReady,
    showDebug,
    setEvents,
  } = params;

  try {
    setIsConnecting(true);
    onConnectingChange?.(true);

    // 1) Récupérer la clé ephemeral
    const tokenResponse = await fetch("/api/realtime/token");
    if (!tokenResponse.ok) {
      throw new Error(`Token request failed: ${tokenResponse.status}`);
    }
    const data = await tokenResponse.json();
    const ephemeralKey = data?.client_secret?.value;
    if (!ephemeralKey) {
      throw new Error("No ephemeralKey returned from /api/realtime/token");
    }

    // 2) Créer RTCPeerConnection
    const pc = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun1.l.google.com:19302" }],
    });
    peerConnection.current = pc;

    // 3) Créer un <audio> pour la voix distante
    audioElement.current = document.createElement("audio");
    audioElement.current.autoplay = true;

    // 4) Sur pc.ontrack => on récupère le flux remote => on l’assigne au <audio>
    pc.ontrack = (evt) => {
      if (showDebug) console.log("[startSession] => ontrack => got remote stream");
      const remoteStream = evt.streams[0];
      if (!audioElement.current) return;
      audioElement.current.srcObject = remoteStream;

      // AudioContext côté remote
      remoteAudioContextRef.current = new AudioContext();
      remoteAnalyserRef.current = remoteAudioContextRef.current.createAnalyser();
      remoteAnalyserRef.current.fftSize = 256;

      const remoteSource = remoteAudioContextRef.current.createMediaStreamSource(remoteStream);
      remoteSource.connect(remoteAnalyserRef.current);

      setRemoteAnalyser(remoteAnalyserRef.current);
      onAnalyzersReady?.(localAnalyserRef.current, remoteAnalyserRef.current);
    };

    // 5) Capturer micro local => pc.addTrack
    const userMediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    userMediaStream.getTracks().forEach((track) => pc.addTrack(track, userMediaStream));

    // AudioContext local + analyser
    localAudioContextRef.current = new AudioContext();
    localAnalyserRef.current = localAudioContextRef.current.createAnalyser();
    localAnalyserRef.current.fftSize = 256;

    const localSource = localAudioContextRef.current.createMediaStreamSource(userMediaStream);
    localSource.connect(localAnalyserRef.current);

    setLocalAnalyser(localAnalyserRef.current);
    onAnalyzersReady?.(localAnalyserRef.current, remoteAnalyserRef.current);

    // 6) DataChannel
    const dc = pc.createDataChannel("oai-events");
    setDataChannel(dc);

    dc.onopen = () => {
      if (showDebug) {
        console.log("[startSession] => dataChannel open => session.update");
      }
      // => 1) Logger un event
      setEvents((prev) => [
        ...prev,
        { type: "SESSION_OPEN", timestamp: Date.now() },
      ]);

      // => 2) Envoyer les instructions initiales (“session.update”, etc.)
      handleDataChannelOpen(dc, initialInstructions);
    };

    dc.onclose = () => {
      if (showDebug) console.log("[startSession] => dataChannel closed");
    };

    // 7) createOffer => POST => OpenAI Realtime => retrieve answer
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    const baseUrl = "https://api.openai.com/v1/realtime";
    const model = "gpt-4o-realtime-preview-2024-12-17";

    const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
      method: "POST",
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${ephemeralKey}`,
        "Content-Type": "application/sdp",
      },
    });
    if (!sdpResponse.ok) {
      throw new Error(`OpenAI Realtime API returned ${sdpResponse.status}`);
    }

    const rawAnswer = await sdpResponse.text();
    const answer: RTCSessionDescriptionInit = {
      type: "answer",
      sdp: rawAnswer,
    };
    await pc.setRemoteDescription(answer);

    setIsSessionActive(true);
    setIsConnecting(false);
    onConnectingChange?.(false);

    if (showDebug) console.log("[startSession] => session active!");
  } catch (err) {
    console.error("[startSession] => error:", err);
    setIsConnecting(false);
    onConnectingChange?.(false);
  }
}