// src/pages/Marketing/AboutPage.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout';

const AboutPage: React.FC = () => {
  // On cible le namespace "aboutPage"
  const { t } = useTranslation('aboutPage');

  return (
    <Layout>
      <section
        className="
          relative
          /* Full-bleed */
          w-screen left-1/2 ml-[-50vw]
          right-1/2 mr-[-50vw]
          border-t border-gray-300
          py-24
        "
      >
        <div className="max-w-screen-lg mx-auto px-4 text-center text-[1.65rem] leading-snug">
          {/* Title */}
          <h1 className="text-3xl md:text-4xl font-bold mb-6">
            {t('title')}
            {/* e.g. "About Voxanet" */}
          </h1>

          {/* Intro paragraph */}
          <p className="text-gray-700 mb-6 max-w-[50rem] mx-auto">
            {t('intro')}
            {/* e.g. "Voxanet is an AI-powered platform dedicated..." */}
          </p>

          {/* Our Story title */}
          <h2 className="text-2xl md:text-3xl font-semibold mt-10 mb-4">
            {t('ourStoryTitle')}
            {/* e.g. "Our Story" */}
          </h2>

          {/* Our Story content */}
          <p className="text-gray-700 mb-12 max-w-[50rem] mx-auto">
            {t('ourStoryContent')}
            {/* e.g. "We started as a small team... to provide real-time AI feedback..." */}
          </p>

          {/* Link/CTA to go back to homepage */}
          <Link
            to="/"
            className={`
              inline-block
              px-8 py-4
              bg-[#3f7afd] hover:bg-[#2f69df]
              text-white font-bold text-[1.4rem] md:text-[1.5rem] rounded-lg
              transition-transform transform hover:scale-105 focus:outline-none focus:ring-4
              focus:ring-blue-300
            `}
          >
            {t('goToHomepage')}
            {/* e.g. "Go to Homepage" */}
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;