// src/components/FlagIcon.tsx

import React from 'react';
import 'flag-icons/css/flag-icons.min.css';

interface FlagIconProps {
  countryCode: string;
  size?: number;
  className?: string; // <-- AJOUT : on autorise le className
}

export const FlagIcon: React.FC<FlagIconProps> = ({
  countryCode,
  size = 24,
  className = '',
}) => {
  // On applique width/height
  const style = {
    width: size,
    height: size,
    display: 'inline-block',
  };

  // On combine la classe "fi fi-xx" + celle qui vient de className
  // (soit en plain string, soit via un utilitaire comme classNames)
  const finalClass = `fi fi-${countryCode.toLowerCase()} ${className}`;

  return (
    <span
      className={finalClass}
      style={style}
    />
  );
};

export default FlagIcon;