// src/pages/ConsolePage/ConsolePageHeader.tsx
import React from 'react';
import CloseButton from '../../components/CloseButton';
import { MESSAGE_LIMITS } from '../../config/limits';

type ConsolePageHeaderProps = {
  onExitClick: () => void;
  sessionMessageCount: number;
  showDev: boolean;
  onToggleLogs: () => void;
};

export function ConsolePageHeader({
  onExitClick,
  sessionMessageCount,
  showDev,
  onToggleLogs,
}: ConsolePageHeaderProps) {
  return (
    <>
      {/* Bouton fermer */}
      <div className="absolute top-4 left-4 z-50">
        <CloseButton onClick={onExitClick} />
      </div>

      {/* Indicateur x/y + bouton logs */}
      <div className="absolute top-4 right-4 flex flex-col items-end gap-2 z-50">
        <div
          className="
            bg-blue-50 border border-blue-200 shadow-sm
            px-3 py-1 rounded-full
            text-gray-800 leading-snug
            text-[1.2rem] sm:text-[1.25rem]
            md:text-[1.375rem] lg:text-[1.65rem]
            pr-4
          "
        >
          ⭐️ {sessionMessageCount}/{MESSAGE_LIMITS.MESSAGES_REQUIRED_TO_COMPLETE_SCENARIO}
        </div>

        {/* Bouton logs si dev */}
        {showDev && (
          <button
            onClick={onToggleLogs}
            className="
              px-3 py-1 bg-gray-100 border border-gray-300
              rounded-md hover:bg-gray-200 transition
              text-sm
            "
          >
            Show/Hide Logs
          </button>
        )}
      </div>

      {/* Header minimal */}
      <div className="flex items-center justify-center p-2 px-4 min-h-[40px] shrink-0">
        <img src="/openai-logomark.svg" alt="Logo" className="w-6 h-6" />
      </div>
    </>
  );
}