// /Users/frederic/voxanet/src/utils/langToBCP47.ts

/**
 * Convertit le code de langue (ex: "fr", "en", "pt", etc.)
 * en un code BCP-47 pour la Web Speech API (ex: "fr-FR", "en-US", "pt-PT").
 */
export function convertLangToBCP47(lang: string): string {
    switch (lang) {
      case 'en':
        return 'en-US';
      case 'es':
        return 'es-ES';
      case 'fr':
        return 'fr-FR';
      case 'de':
        return 'de-DE';
      case 'it':
        return 'it-IT';
      case 'pt':
        return 'pt-PT';
      case 'nl':
        return 'nl-NL';
      case 'zh':
        return 'zh-CN';
      case 'ar':
        return 'ar-SA';
      case 'hi':
        return 'hi-IN';
      case 'ru':
        return 'ru-RU';
      case 'ko':
        return 'ko-KR';
      case 'id':
        return 'id-ID';
      case 'tr':
        return 'tr-TR';
      case 'pl':
        return 'pl-PL';
      case 'ja':
        return 'ja-JP';
      case 'ur':
        return 'ur-PK';
      default:
        // Par défaut, on renvoie l'anglais US si la langue n'est pas gérée
        return 'en-US';
    }
  }