// src/context/AuthContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { User } from 'firebase/auth';
import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
} from 'firebase/auth';
import { getApiUrl } from '../config/environment';

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  loading: true,
});

/**
 * (NOUVEAU) Variable pour traquer la dernière fois
 * qu'on a appelé registerOrUpdateUser
 */
let lastRegisterCallTimestamp = 0;

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  console.log('[AuthContext] === COMPONENT MOUNTED ==='); // Nouveau log : on voit quand le composant se monte

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  /**
   * registerOrUpdateUser
   * => Enregistre / met à jour un utilisateur (uid) avec :
   *    - la langue par défaut du navigateur
   *    - la timezone
   *    - l'heure de rappel préférée
   */
  const registerOrUpdateUser = async (
    uid: string,
    browserLanguage: string,
    timezone: string,
    preferredReminderHour: number
  ) => {
    try {
      const apiUrl = getApiUrl();
      const now = Date.now();
      const msSinceLastCall = now - lastRegisterCallTimestamp;

      console.log('---------------------------------------------------');
      console.log('[AuthContext] 📡 registerOrUpdateUser() CALLED =>', {
        uid,
        browserLanguage,
        timezone,
        preferredReminderHour,
        msSinceLastCall,
        now: new Date().toISOString(),
      });
      console.log('---------------------------------------------------');

      // On met à jour la variable pour la prochaine fois
      lastRegisterCallTimestamp = now;

      // -- LOG supplémentaire avant le fetch
      console.log('[AuthContext] => On va faire un fetch POST /api/user/registerOrUpdateUser', {
        apiUrl,
        endpoint: `${apiUrl}/api/user/registerOrUpdateUser`,
        bodyToSend: {
          uid,
          defaultBrowserLanguage: browserLanguage,
          timezone,
          preferredReminderHour,
        },
      });

      // (NOUVEAU LOG) – Log plus verbeux avant d'effectuer le fetch
      console.log('[AuthContext] => fetch params:', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': browserLanguage,
        },
        body: JSON.stringify({
          uid,
          defaultBrowserLanguage: browserLanguage,
          timezone,
          preferredReminderHour,
        }),
      });

      const response = await fetch(`${apiUrl}/api/user/registerOrUpdateUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': browserLanguage,
        },
        body: JSON.stringify({
          uid,
          defaultBrowserLanguage: browserLanguage,
          timezone,
          preferredReminderHour,
        }),
      });

      console.log('[AuthContext] => fetch response.status =', response.status);

      if (!response.ok) {
        console.error('[AuthContext] ❌ Échec de l\'enregistrement de l\'utilisateur :', {
          status: response.status,
          statusText: response.statusText,
        });
      } else {
        console.log('[AuthContext] ✅ Utilisateur enregistré (HTTP OK)');

        // Optionnel: on essaie d'afficher la réponse JSON pour aider au debug
        try {
          const data = await response.json();
          console.log('[AuthContext] => Réponse JSON du serveur :', data);
        } catch (parseError) {
          console.warn('[AuthContext] ⚠️ Impossible de parser la réponse JSON :', parseError);
        }

        // (NOUVEAU LOG) – Confirmation de la réussite du fetch
        console.log('[AuthContext] => (NOUVEAU LOG) user update success =>', {
          timestamp: new Date().toISOString(),
        });
      }
    } catch (error) {
      console.error('[AuthContext] ❌ Erreur lors de registerOrUpdateUser :', {
        error: error instanceof Error ? error.message : error,
        stack: error instanceof Error ? error.stack : 'no-stack',
        timestamp: new Date().toISOString(),
      });
    }
  };

  useEffect(() => {
    console.log('[AuthContext] 🔄 useEffect => Démarrage de la surveillance Firebase Auth');

    const auth = getAuth();

    // Surveille les changements d'état de l'utilisateur Firebase
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('[AuthContext] onAuthStateChanged => detected user =', user?.uid || null);

      if (!user) {
        console.log('[AuthContext] 🔑 Aucun utilisateur => on va tenter signInAnonymously');
        try {
          const anonymousUser = await signInAnonymously(auth);

          console.log('[AuthContext] ✅ Connexion anonyme réussie :', {
            uid: anonymousUser.user.uid,
            timestamp: new Date().toISOString(),
          });

          // Log avant l'appel
          console.log('[AuthContext] (NOUVEAU LOG) Avant registerOrUpdateUser (anonymous)', {
            uid: anonymousUser.user.uid,
          });

          // Enregistrer l'utilisateur côté backend
          const browserLanguage = navigator.language || 'en';

          // [NOUVEAU] On récupère la timezone du client
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
          // [NOUVEAU] On définit une valeur par défaut pour l'heure préférée
          const defaultReminderHour = 17;

          await registerOrUpdateUser(
            anonymousUser.user.uid,
            browserLanguage,
            userTimezone,
            defaultReminderHour
          );

          console.log('[AuthContext] => Juste avant setCurrentUser(anonymousUser.user) ...');
          setCurrentUser(anonymousUser.user);
          setLoading(false);

          // Confirmation post enregistrement
          console.log('[AuthContext] => After registerOrUpdateUser (anonymous)', {
            currentUserUid: anonymousUser.user.uid,
            timestamp: new Date().toISOString(),
          });
        } catch (error: unknown) {
          console.error('[AuthContext] ❌ Erreur de connexion anonyme :', {
            error: error instanceof Error ? error.message : 'Erreur inconnue',
            timestamp: new Date().toISOString(),
          });
          setLoading(false);

          // Log plus détaillé
          console.log('[AuthContext] (NOUVEAU LOG) Error details =>', {
            errorStack: error instanceof Error ? error.stack : 'No stack',
          });
        }
      } else {
        // Utilisateur détecté
        console.log('[AuthContext] 👤 Utilisateur détecté :', {
          uid: user.uid,
          isAnonymous: user.isAnonymous,
          email: user.email,
          emailVerified: user.emailVerified,
          timestamp: new Date().toISOString(),
        });

        // Log avant l'appel
        console.log('[AuthContext] => Avant registerOrUpdateUser (logged user)', {
          uid: user.uid,
        });

        // Enregistrer l'utilisateur côté backend
        const browserLanguage = navigator.language || 'en';

        // [NOUVEAU] Récupérer la timezone
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
        // [NOUVEAU] Même logique => on peut garder 17 par défaut ou le récupérer d'ailleurs
        const defaultReminderHour = 17;

        await registerOrUpdateUser(
          user.uid,
          browserLanguage,
          userTimezone,
          defaultReminderHour
        );

        console.log('[AuthContext] => Juste avant setCurrentUser(user) ...');
        setCurrentUser(user);
        setLoading(false);

        // Confirmation post enregistrement
        console.log('[AuthContext] => After registerOrUpdateUser (logged user)', {
          currentUserUid: user.uid,
          timestamp: new Date().toISOString(),
        });
      }
    });

    return () => {
      console.log('[AuthContext] 🔚 useEffect => Fin de la surveillance (unsubscribe)');
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Petit log pour voir si currentUser change après coup
    console.log('[AuthContext] => currentUser state changed =>', {
      newValue: currentUser?.uid,
      isAnonymous: currentUser?.isAnonymous,
      loadingState: loading,
      timestamp: new Date().toISOString(),
    });
  }, [currentUser, loading]);

  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);