// src/pages/LandingFAQSection.tsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Exemple de section FAQ avec des collapse (accordéons).
 */
export const LandingFAQSection: React.FC = () => {
  // On cible le namespace "landingPage"
  const { t } = useTranslation('landingPage');
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  // Récupération du tableau "faqs" => t('faqs', { returnObjects: true })
  const faqs = t('faqs', { returnObjects: true }) as Array<{
    question: string;
    answer: string;
  }>;

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Vérification au cas où faqs serait undefined ou ne serait pas un tableau
  if (!Array.isArray(faqs)) {
    return null; // ou un fallback "Pas de FAQ"
  }

  return (
    <section
      className={`
        /* Full-bleed */
        relative
        w-screen left-1/2 ml-[-50vw]
        right-1/2 mr-[-50vw]
        border-t border-gray-300
        py-24
        bg-white
      `}
    >
      <div className="max-w-screen-lg mx-auto px-4 text-[1.65rem] leading-snug">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-10">
          {t('faqSectionTitle')}
        </h2>

        <div className="space-y-4">
          {faqs.map((faq, idx) => (
            <div
              key={idx}
              className="border rounded-lg overflow-hidden hover:shadow"
            >
              <button
                type="button"
                className="
                  w-full px-4 py-3 text-left
                  flex justify-between items-center
                  focus:outline-none
                  text-gray-800
                "
                onClick={() => toggleFAQ(idx)}
              >
                <span className="font-semibold">{faq.question}</span>
                <span className="ml-2 text-gray-500">
                  {openIndex === idx ? '-' : '+'}
                </span>
              </button>
              {openIndex === idx && (
                <div className="px-4 pb-4 text-gray-700">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};