// -----------------------------------------------------------------------
// src/pages/ConsolePage/FeedbackSidePanel.tsx
// -----------------------------------------------------------------------
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConversationItemType } from '../../types';

/**
 * convertAsterisksToColoredBold :
 *   - Transforme **texte** en <strong style="color:X;">texte</strong>.
 */
function convertAsterisksToColoredBold(str: string, color: string): string {
  return str.replace(
    /\*\*(.+?)\*\*/g,
    `<strong style="color:${color};">$1</strong>`
  );
}

type FeedbackSidePanelProps = {
  item: ConversationItemType;
  onClose: () => void;
};

/**
 * FeedbackSidePanel :
 *   - Titre “Feedback” (même taille que “Your phrase” / “Corrected phrase”),
 *   - Si feedbackColor=green => message simple,
 *   - Si feedbackColor=red  => affiche:
 *       1) Label + pastille rouge ("X") => "Your phrase"
 *       2) Label + pastille verte ("✓") => "Corrected phrase"
 *       3) L'explication si elle existe
 *
 * Largeur ~20% plus grande que la version précédente,
 * et croix de fermeture (×) deux fois plus grande (text-4xl).
 */
export default function FeedbackSidePanel({
  item,
  onClose,
}: FeedbackSidePanelProps) {
  // 1) On récupère deux namespaces : "consolePage" et "common"
  //    => on utilise des alias tConsole / tCommon
  const { t: tConsole } = useTranslation('consolePage');
  const { t: tCommon } = useTranslation('common');

  // Récupération de la correction
  const {
    feedbackColor = 'green',
    userOriginalSentence = '',
    correctedSentenceByAssistant = '',
    explanation = '',
  } = item.correction || {};

  const isGreen = feedbackColor === 'green';

  // Couleurs
  const originalHtml = convertAsterisksToColoredBold(userOriginalSentence, '#cc0000');
  const correctedHtml = convertAsterisksToColoredBold(correctedSentenceByAssistant, '#2eb93e');

  return (
    <div
      className="
        fixed top-0 right-0
        w-full
        sm:w-[30rem]  /* élargi de 24rem à 30rem */
        md:w-[34rem]  /* élargi de 28rem à 34rem */
        h-full
        bg-white shadow-2xl
        border-l border-gray-200
        flex flex-col
        z-50
        animate-slide-in-from-right
      "
    >
      {/* Barre de titre */}
      <div className="px-4 py-3 border-b border-gray-100 flex items-center justify-between">
        <h2
          className="
            font-bold
            text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]
            text-gray-700
          "
        >
          {/* "Feedback" => consolePage.feedbackPanel.title */}
          {tConsole('feedbackPanel.title')}
        </h2>
        <button
          onClick={onClose}
          className="
            px-3 py-1
            text-gray-500
            hover:text-gray-800
            transition-colors
            text-4xl /* doublement taille */
            leading-none
          "
          // fallback => 'Close' si tCommon('close') n'est pas défini
          title={tCommon('close') || 'Close'}
        >
          ×
        </button>
      </div>

      {/* Contenu principal */}
      <div
        className="
          flex-1 overflow-auto p-4 space-y-6 text-gray-800
          text-[1.2rem] sm:text-[1.25rem] md:text-[1.375rem] lg:text-[1.65rem]
          leading-relaxed
        "
      >
        {isGreen ? (
          /* Cas "green" => message simple */
          <div>
            <p className="font-semibold text-green-700">
              {/* consolePage.feedbackPanel.greenMessage */}
              {tConsole('feedbackPanel.greenMessage')}
            </p>
          </div>
        ) : (
          /* Cas "red" => phrase originale + corrigée + explication */
          <>
            {/* Phrase originale (rouge) */}
            <div>
              <div className="flex items-center mb-2">
                <div
                  className="
                    w-10 h-10
                    mr-3
                    flex items-center justify-center
                    rounded-full
                    bg-red-600
                  "
                >
                  <span className="text-white text-xl font-bold">✕</span>
                </div>
                <p className="font-medium text-gray-900">
                  {/* consolePage.feedbackPanel.yourPhraseLabel */}
                  {tConsole('feedbackPanel.yourPhraseLabel')}
                </p>
              </div>
              <div
                className="
                  bg-gray-50 border border-gray-200 rounded
                  p-3
                  whitespace-pre-wrap
                "
                dangerouslySetInnerHTML={{ __html: originalHtml }}
              />
            </div>

            {/* Phrase corrigée (vert) */}
            <div>
              {/* Label + pastille verte en flex row */}
              <div className="flex items-center mb-2">
                <div
                  className="
                    w-10 h-10
                    mr-3
                    flex items-center justify-center
                    rounded-full
                    bg-green-600
                  "
                >
                  <span className="text-white text-xl font-bold">✓</span>
                </div>
                <p className="font-medium text-gray-900">
                  {tConsole('feedbackPanel.correctedPhraseLabel')}
                </p>
              </div>
              <div
                className="
                  bg-gray-50 border border-gray-200 rounded
                  p-3
                  whitespace-pre-wrap
                "
                dangerouslySetInnerHTML={{ __html: correctedHtml }}
              />
            </div>

            {/* Explication (facultatif) */}
            <div>
              <p className="font-medium text-gray-900 mb-2">
                {tConsole('feedbackPanel.explanationLabel')}
              </p>
              <div
                className="
                  bg-white
                  border border-gray-300
                  rounded
                  p-3
                "
              >
                {explanation.trim() !== ''
                  ? explanation
                  : tConsole('feedbackPanel.emptyExplanation')}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}