// src/components/LevelSelector.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LEVELS } from '../constants/levels';

interface LevelSelectorProps {
  selectedLevel: number | null;
  onLevelSelect: (level: number) => void;
  label: string; // ex: "Votre niveau"
}

const LevelSelector: React.FC<LevelSelectorProps> = ({
  selectedLevel,
  onLevelSelect,
  label,
}) => {
  // 1) On cible le namespace "common"
  const { t } = useTranslation('common');

  return (
    <div className="text-center mb-12">
      {/* Titre ou label au-dessus */}
      <div
        className="
          text-[1.3rem]
          sm:text-[1.4rem]
          md:text-[1.5rem]
          font-semibold
          text-gray-700
          mb-3
          text-center
        "
      >
        {label}
      </div>

      <select
        value={selectedLevel ?? ''}
        onChange={(e) => onLevelSelect(Number(e.target.value))}
        className="
          p-3
          border border-gray-300
          rounded-lg
          text-[1.2rem]
          md:text-[1.3rem]
          cursor-pointer
          w-full
          shadow-sm
          focus:ring-2
          focus:ring-blue-300
          text-gray-700
          font-semibold
        "
        style={{ textAlignLast: 'center' }}
      >
        {LEVELS.map((value) => {
          // 2) On compose les clés pour levelLabels & levelDescriptions
          const labelKey = `studyLevelSelection.levelLabels.${value}`;
          const descriptionKey = `studyLevelSelection.levelDescriptions.${value}`;

          return (
            <option key={value} value={value}>
              {`${t(labelKey)}: ${t(descriptionKey)}`}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default LevelSelector;