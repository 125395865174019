// src/pages/Marketing/PricingPage.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout';

const PricingPage: React.FC = () => {
  // On cible le namespace "pricingPage"
  const { t } = useTranslation('pricingPage');

  return (
    <Layout>
      {/* SECTION PRICING EN MODE FULL-BLEED, TRANSPARENT */}
      <section
        className={`
          relative
          w-screen left-1/2 ml-[-50vw]
          right-1/2 mr-[-50vw]
          border-t border-gray-300
          py-24
        `}
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <div className="max-w-screen-lg mx-auto px-4 text-center text-[1.65rem] leading-snug">
          {/* Titre principal de la page */}
          <h1 className="text-3xl md:text-4xl font-bold mb-6">
            {t('title')}
          </h1>

          {/* Paragraphe d’intro */}
          <p className="text-gray-700 mb-8 max-w-[50rem] mx-auto">
            {t('intro')}
          </p>

          {/* “Carte” Premium */}
          <div
            className="
              p-6 mb-10
              max-w-[40rem] mx-auto
              text-left
              rounded-lg shadow-md
              bg-white
              border border-gray-200
            "
          >
            <h2 className="text-2xl font-semibold mb-4 text-[#243238]">
              {t('premiumPlan.title')}
            </h2>

            {/* Liste des avantages */}
            <ul className="list-disc list-inside mb-4 text-gray-700">
              <li>{t('premiumPlan.benefit1')}</li>
              <li>{t('premiumPlan.benefit2')}</li>
              <li>{t('premiumPlan.benefit3')}</li>
            </ul>

            {/* Prix */}
            <span className="text-2xl font-bold text-[#243238]">
              {t('premiumPlan.price')}
            </span>
          </div>

          {/* Lien vers la page Contact */}
          <p className="text-gray-700 mb-8">
            {t('contactPrompt')}{' '}
            <Link
              to="/contact"
              className="text-blue-600 underline hover:text-blue-800 transition-colors"
            >
              {t('contactUsLink')}
            </Link>
          </p>

          {/* CTA → Go to Homepage */}
          <Link
            to="/"
            className={`
              inline-block
              px-8 py-4
              bg-[#3f7afd] hover:bg-[#2f69df]
              text-white font-bold text-[1.3rem] md:text-[1.5rem]
              rounded-lg shadow-lg
              transition-transform transform hover:scale-105
              focus:outline-none focus:ring-4
              focus:ring-blue-300
            `}
          >
            {t('goToHomepage')}
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default PricingPage;