// /src/pages/ConsolePage/index.tsx

import React, { useState, useEffect, useRef } from 'react';

// Hooks et logique
import { useConsolePageLogic } from './logic/useConsolePageLogic';
import { useRealtimeWebRTC } from '../../hooks/useRealtimeWebRTC/useRealtimeWebRTC';
import { useAuth } from '../../context/AuthContext';
import { useStudy } from '../../context/StudyContext';

// UI et composants internes
import { ConsolePageHeader } from './ConsolePageHeader';
import { ConsolePageOverlays } from './ConsolePageOverlays';
import { RealtimeLogViewer } from './RealtimeLogViewer';

// Sous-composants "nouveaux" (ConversationSection & BottomBar)
import { ConversationSection } from './components/ConversationSection';
import { BottomBar } from './components/BottomBar';

// Utils
import { checkPaywallLimit } from './checkPaywallLimit';

type ConsolePageProps = {
  onExit: () => void;
};

export function ConsolePage({ onExit }: ConsolePageProps) {
  const { currentUser } = useAuth();
  const { selectedScenario } = useStudy();

  // 1) Récupère la logique principale (messages, paywall, scenario, etc.)
  const {
    items,
    sessionMessageCount,
    showRegisterEmailForm,
    showSuccessMessage,
    showScenarioSuccessMessage,
    isLimitReached,
    setIsLimitReached,
    error,
    isConnecting,
    handleSignUpSuccess,
    handleTranslate,
    handleRealtimeEvent: originalRealtimeEvent,
    handleConnectingChange,
    dynamicInstructions,
    showStreakAnimation,
    showStreakSuccessMessage,
    checkIfLimitReached,
  } = useConsolePageLogic();

  // 2) UI pour suggestion bubble
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [revealedPhrase, setRevealedPhrase] = useState<string | null>(null);

  // 3) UI pour icebreaker (uniquement en scenario free_chat)
  const [showIcebreaker, setShowIcebreaker] = useState(false);
  const [skipSuggestionInFreeChat, setSkipSuggestionInFreeChat] = useState(
    selectedScenario === 'free_chat'
  );

  // 4) Realtime WebRTC
  const {
    isSessionActive,
    isRecording,
    dataChannel,
    shortRecordingMessage,
    events,
    stopSession,
    handlePointerDown,
    handlePointerUp,
  } = useRealtimeWebRTC({
    autoStartSession: true,
    onConnectingChange: handleConnectingChange,
    onRealtimeEvent: customRealtimeHandler,
    showDebug: false,
    initialInstructions: dynamicInstructions,
    onAnalyzersReady: (local, remote) => {
      setLocalAnalyserState(local);
      setRemoteAnalyserState(remote);
    },
  });

  // Pour RealtimeVisualizer
  const [localAnalyserState, setLocalAnalyserState] = useState<AnalyserNode | null>(null);
  const [remoteAnalyserState, setRemoteAnalyserState] = useState<AnalyserNode | null>(null);

  // 5) Pour afficher/masquer logs dev
  const [showRealtimeLogs, setShowRealtimeLogs] = useState(false);

  // 6) Pour afficher/masquer la zone de saisie texte
  const [showTextInput, setShowTextInput] = useState(false);

  // 7) DRAPEAU micro (éviter un double pointerUp)
  const userHasReleased = useRef(false);

  // ------------------------------------------------------------------
  // A) Intercepteur Realtime => masque la suggestion si user message
  // ------------------------------------------------------------------
  function customRealtimeHandler(evt: any) {
    originalRealtimeEvent(evt);

    // Désactiver la suggestion bubble quand un user message apparaît
    if (evt.type === 'conversation.item.created') {
      const item = evt.item;
      if (item?.role === 'user') {
        setShowSuggestion(false);
        setRevealedPhrase(null);
      }
    }
  }

  // ------------------------------------------------------------------
  // B) handleMicPointerDown => commence l'enregistrement, check paywall
  // ------------------------------------------------------------------
  async function handleMicPointerDown() {
    userHasReleased.current = false;
    handlePointerDown(); // => visuel & audio

    // 1) Bloquer si anonyme trop de messages
    if (checkIfLimitReached()) {
      console.warn('[ConsolePage] => limit => forcibly pointerUp');
      if (!userHasReleased.current) {
        handlePointerUp();
      }
      return;
    }

    // 2) Vérif paywall => si paywallBlocked => pointerUp
    if (currentUser?.uid) {
      try {
        const paywallBlocked = await checkPaywallLimit(currentUser.uid);
        if (paywallBlocked) {
          console.warn('[ConsolePage] => paywall => forcibly pointerUp');
          setIsLimitReached(true);
          if (!userHasReleased.current) {
            handlePointerUp();
          }
        }
      } catch (err) {
        console.error('[ConsolePage] => handleMicPointerDown => error =>', err);
      }
    }
  }
  function handleMicPointerUp() {
    userHasReleased.current = true;
    handlePointerUp();
  }

  // ------------------------------------------------------------------
  // C) onAssistantSilence => callback quand l’assistant se tait
  // ------------------------------------------------------------------
  function handleAssistantSilence() {
    console.log('[ConsolePage] => onAssistantSilence => scenario=', selectedScenario);

    if (selectedScenario === 'free_chat') {
      if (sessionMessageCount === 0) {
        // => Afficher icebreaker + skip la suggestion
        setShowIcebreaker(true);
        setSkipSuggestionInFreeChat(true);
      } else {
        // user a déjà parlé
        if (skipSuggestionInFreeChat) {
          setSkipSuggestionInFreeChat(false);
        } else {
          // afficher la bulle
          setShowSuggestion(true);
          setRevealedPhrase(null);
        }
      }
    } else {
      // scenarios normaux => afficher direct la suggestion
      setShowSuggestion(true);
      setRevealedPhrase(null);
    }
  }

  // ------------------------------------------------------------------
  // D) handleSendTextMessage => envoi d’un message text => dataChannel
  // ------------------------------------------------------------------
  async function handleSendTextMessage(text: string) {
    setShowIcebreaker(false);

    // Bloquer anonyme
    if (checkIfLimitReached()) {
      console.warn('[ConsolePage] => limit => skip text message');
      return;
    }

    // Bloquer paywall
    if (currentUser?.uid) {
      const paywallBlocked = await checkPaywallLimit(currentUser.uid);
      if (paywallBlocked) {
        setIsLimitReached(true);
        return;
      }
    }

    // Vérifier dataChannel
    if (!dataChannel || dataChannel.readyState !== 'open') {
      console.warn('[ConsolePage] => dataChannel non open => skip text');
      return;
    }

    // On envoie 2 events: conversation.item.create & response.create
    const createEvt = {
      type: 'conversation.item.create',
      item: {
        type: 'message',
        role: 'user',
        content: [{ type: 'input_text', text }],
      },
    };
    const responseEvt = { type: 'response.create' };

    dataChannel.send(JSON.stringify(createEvt));
    dataChannel.send(JSON.stringify(responseEvt));
  }

  // ------------------------------------------------------------------
  // E) handleExitClick => stopSession + onExit
  // ------------------------------------------------------------------
  function handleExitClick() {
    stopSession();
    onExit();
  }

  // ------------------------------------------------------------------
  // F) Toggle logs
  // ------------------------------------------------------------------
  function handleToggleLogs() {
    setShowRealtimeLogs((prev) => !prev);
  }

  // ------------------------------------------------------------------
  // G) Effets de montage / debug
  // ------------------------------------------------------------------
  useEffect(() => {
    console.log('[ConsolePage] => MOUNTED');
    return () => {
      console.log('[ConsolePage] => UNMOUNTED');
    };
  }, []);

  // ------------------------------------------------------------------
  // H) Dev or prod
  // ------------------------------------------------------------------
  const isDev =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NODE_ENV === 'development' ||
    process.env.REACT_APP_ENVIRONMENT === 'development';

  // ------------------------------------------------------------------
  // I) Rendu principal
  // ------------------------------------------------------------------
  return (
    <div
      data-component="ConsolePage"
      className="relative w-screen h-screen flex flex-col font-sans overflow-hidden"
    >
      {/* (1) HEADER */}
      <ConsolePageHeader
        onExitClick={handleExitClick}
        sessionMessageCount={sessionMessageCount}
        showDev={isDev}
        onToggleLogs={handleToggleLogs}
      />

      {/* (2) Contenu principal (Conversation + barre du bas) */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* (2-A) La section conversation */}
        <ConversationSection
          items={items}
          showIcebreaker={showIcebreaker}
          setShowIcebreaker={setShowIcebreaker}
          showSuggestion={showSuggestion}
          setShowSuggestion={setShowSuggestion}
          revealedPhrase={revealedPhrase}
          setRevealedPhrase={setRevealedPhrase}
          skipSuggestionInFreeChat={skipSuggestionInFreeChat}
          setSkipSuggestionInFreeChat={setSkipSuggestionInFreeChat}
          handleSendTextMessage={handleSendTextMessage}
          handleTranslate={handleTranslate}
        />

        {/* (2-B) La barre du bas (micro + visualizer + bouton clavier) */}
        <BottomBar
          isSessionActive={isSessionActive}
          isRecording={isRecording}
          dataChannel={dataChannel}
          shortRecordingMessage={shortRecordingMessage}
          localAnalyserState={localAnalyserState}
          remoteAnalyserState={remoteAnalyserState}
          onMicPointerDown={handleMicPointerDown}
          onMicPointerUp={handleMicPointerUp}
          onAssistantSilence={handleAssistantSilence}
          onToggleTextInput={() => setShowTextInput((prev) => !prev)}
          checkIfLimitReached={checkIfLimitReached}
          // Passage du state pour potentiellement masquer input si isLimitReached
          isLimitReached={isLimitReached}
          setIsLimitReached={setIsLimitReached}
          // Pour text input
          showTextInput={showTextInput}
          handleSendTextMessage={handleSendTextMessage}
        />
      </div>

      {/* (3) Overlay “isConnecting” */}
      {isConnecting && (
        <div className="absolute inset-0 z-50 bg-white bg-opacity-75 flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-blue-500" />
        </div>
      )}

      {/* (4) Overlays => paywall, success, scenario, etc. */}
      <ConsolePageOverlays
        showRegisterEmailForm={showRegisterEmailForm}
        onSignUpSuccess={handleSignUpSuccess}
        isLimitReached={isLimitReached}
        showStreakAnimation={showStreakAnimation}
        showStreakSuccessMessage={showStreakSuccessMessage}
        showSuccessMessage={showSuccessMessage}
        showScenarioSuccessMessage={showScenarioSuccessMessage}
        error={error}
      />

      {/* (5) Logs dev */}
      {isDev && showRealtimeLogs && <RealtimeLogViewer events={events} />}
    </div>
  );
}

export default ConsolePage;