// src/hooks/useRealtimeWebRTC/pointerHandlers.ts

import { MutableRefObject, useCallback } from "react";

interface PointerHandlersParams {
  dataChannel: RTCDataChannel | null;
  audioElement: MutableRefObject<HTMLAudioElement | null>;
  remoteAudioContextRef: MutableRefObject<AudioContext | null>;
  setIsRecording: (b: boolean) => void;
  setShortRecordingMessage: (msg: string | null) => void;
  t: (key: string) => string; // traduction
  lastResponseId: string | null;
  setLastResponseId: (id: string | null) => void;
  lastAssistantItemId: string | null;
  setLastAssistantItemId: (id: string | null) => void;
  recordingStartRef: MutableRefObject<number | null>;
  setIsLimitReached?: (b: boolean) => void; // si tu en as besoin
}

export function usePointerHandlers({
  dataChannel,
  audioElement,
  remoteAudioContextRef,
  setIsRecording,
  setShortRecordingMessage,
  t,
  lastResponseId,
  setLastResponseId,
  lastAssistantItemId,
  setLastAssistantItemId,
  recordingStartRef,
  setIsLimitReached,
}: PointerHandlersParams) {

  // handlePointerDown
  const handlePointerDown = useCallback(() => {
    if (!dataChannel || dataChannel.readyState !== "open") {
      console.warn("[useRealtimeWebRTC] => handlePointerDown => dataChannel not open");
      return;
    }

    console.log("[useRealtimeWebRTC] => handlePointerDown => CANCEL + TRUNCATE + local mute");

    // (1) Cancel
    const cancelPayload = {
      type: "response.cancel",
      response_id: lastResponseId || undefined,
    };
    console.log("... sending CANCEL =>", cancelPayload);
    dataChannel.send(JSON.stringify(cancelPayload));

    // (2) Truncate
    if (lastAssistantItemId) {
      const truncatePayload = {
        type: "conversation.item.truncate",
        item_id: lastAssistantItemId,
        content_index: 0,
        audio_end_ms: 0,
      };
      console.log("... sending TRUNCATE =>", truncatePayload);
      dataChannel.send(JSON.stringify(truncatePayload));
    }

    // (3) Mute local
    if (audioElement.current) {
      audioElement.current.muted = true;
    }
    if (remoteAudioContextRef.current?.state === "running") {
      remoteAudioContextRef.current.suspend();
    }

    // (4) Start recording
    setIsRecording(true);
    recordingStartRef.current = performance.now();

    // clear buffer
    dataChannel.send(JSON.stringify({ type: "input_audio_buffer.clear" }));
  }, [
    dataChannel,
    lastResponseId,
    lastAssistantItemId,
    audioElement,
    remoteAudioContextRef,
    setIsRecording,
    recordingStartRef,
  ]);

  // handlePointerUp
  const handlePointerUp = useCallback(() => {
    if (!dataChannel || dataChannel.readyState !== "open") {
      console.warn("[useRealtimeWebRTC] => handlePointerUp => dataChannel not open");
      return;
    }
    setIsRecording(false);

    if (!recordingStartRef.current) return;
    const durationMs = performance.now() - recordingStartRef.current;
    console.log("[useRealtimeWebRTC] => pointerUp => recording duration =", durationMs, "ms");

    // si < 1.5s
    if (durationMs < 1500) {
      console.log("... recording too short => clearing buffer");
      dataChannel.send(JSON.stringify({ type: "input_audio_buffer.clear" }));
      setShortRecordingMessage(t("shortRecordingWarning"));
      setTimeout(() => setShortRecordingMessage(null), 3000);
      return;
    }

    // (A) unmute local
    if (audioElement.current) {
      audioElement.current.muted = false;
    }
    if (remoteAudioContextRef.current?.state === "suspended") {
      remoteAudioContextRef.current.resume();
    }

    // (B) commit
    dataChannel.send(JSON.stringify({ type: "input_audio_buffer.commit" }));
    dataChannel.send(
      JSON.stringify({
        type: "response.create",
        response: { modalities: ["audio", "text"] },
      })
    );
  }, [
    dataChannel,
    audioElement,
    remoteAudioContextRef,
    setIsRecording,
    setShortRecordingMessage,
    t,
    recordingStartRef,
  ]);

  return { handlePointerDown, handlePointerUp };
}