// -----------------------------------------------------------------------
// src/pages/ConsolePage/ConversationList.tsx
// -----------------------------------------------------------------------
import React, { useState, useCallback } from 'react';
import FeedbackSidePanel from './FeedbackSidePanel';

// (A) We import the unified types from your central file: src/types.ts
import { ConversationItemType } from '../../types';

type ConversationListProps = {
  /** Array of conversation messages (either user or assistant). */
  items: ConversationItemType[];

  /** Function to request the translation of an assistant message. */
  onTranslate: (id: string) => void;

  /**
   * Utility to avoid line breaks before punctuation (e.g. turning " ..." into " ...").
   */
  preventLineBreaks: (txt: string | undefined) => string;
};

/**
 * ConversationList:
 *   - Renders the main list of user/assistant messages.
 *   - Shows a spinner if `isWaitingCorrection === true` (user messages only).
 *   - Displays a red/green badge for user messages after correction (based on `feedbackColor`).
 *   - Lets you open a side panel (FeedbackSidePanel) for more details about the correction.
 */
export function ConversationList({
  items,
  onTranslate,
  preventLineBreaks,
}: ConversationListProps) {
  // Track which conversation item is selected for the right-side correction panel
  const [selectedItemForSidePanel, setSelectedItemForSidePanel] =
    useState<ConversationItemType | null>(null);

  /**
   * Toggles the FeedbackSidePanel open/close.
   * If clicking again on the same item, it closes the panel.
   */
  const handleOpenSidePanel = useCallback(
    (item: ConversationItemType) => {
      if (selectedItemForSidePanel?.id === item.id) {
        setSelectedItemForSidePanel(null);
      } else {
        setSelectedItemForSidePanel(item);
      }
    },
    [selectedItemForSidePanel]
  );

  return (
    <div className="relative">
      {/* (1) Main list of messages */}
      <div className="content-block-body" data-conversation-content>
        {items.map((item) => {
          const isAssistant = item.role === 'assistant';

          // Assistant on the left, user on the right
          const wrapperClasses = isAssistant
            ? 'justify-start'
            : 'justify-end';

          // Visual style for the “bubble”
          const bubbleClasses = isAssistant
            ? 'bg-white border border-[#333] shadow-sm'
            : 'bg-blue-50 border border-blue-500 shadow-sm';

          // We only show a spinner or badge for user messages
          let pastilleContent: React.ReactNode = null;
          if (!isAssistant) {
            // 1) If correction is in progress => show spinner
            if (item.isWaitingCorrection) {
              pastilleContent = (
                <div
                  className="
                    w-10 h-10
                    mr-3
                    flex items-center justify-center
                    cursor-default
                  "
                  title="Correction en cours..."
                >
                  <div
                    className="
                      w-8 h-8
                      rounded-full
                      border-4 border-blue-400
                      border-l-transparent
                      animate-spin
                    "
                  />
                </div>
              );
            }
            // 2) If correction is done => show a green or red badge
            else if (item.correction) {
              const { feedbackColor } = item.correction;
              // (Assuming we only have "green" or "red" in your type)
              const isGreen = (feedbackColor === 'green');
              const colorClass = isGreen ? 'bg-green-600' : 'bg-red-600';
              const iconInside = isGreen ? '✓' : '✕';

              pastilleContent = (
                <div
                  className={`
                    ${colorClass}
                    w-10 h-10
                    mr-3
                    flex items-center justify-center
                    rounded-full
                    cursor-pointer
                  `}
                  title="Afficher la correction"
                  onClick={() => handleOpenSidePanel(item)}
                >
                  <span className="text-white text-xl font-bold">
                    {iconInside}
                  </span>
                </div>
              );
            }
          }

          return (
            <div
              key={item.id}
              className={`
                flex items-center
                ${wrapperClasses}
                mb-4 mx-4
                lg:container lg:mx-auto lg:px-52
                xl:px-96
              `}
            >
              {/* If user => spinner or badge to the left of the bubble */}
              {pastilleContent}

              {/* The message bubble itself */}
              <div
                className={`
                  relative p-4 rounded-2xl
                  max-w-[80%] md:max-w-[85%] lg:max-w-[90%]
                  ${bubbleClasses}
                `}
              >
                <div
                  className="
                    text-gray-800 leading-snug
                    text-[1.2rem]
                    sm:text-[1.25rem]
                    md:text-[1.375rem]
                    lg:text-[1.65rem]
                    pr-4
                  "
                >
                  {isAssistant ? (
                    /* Assistant message (optionally with translation) */
                    <div>
                      {preventLineBreaks(item.text || '(assistant...)')}
                      {item.translation && (
                        <div
                          className="
                            mt-2
                            italic
                            text-gray-600
                            text-[1.2rem]
                            sm:text-[1.25rem]
                            md:text-[1.375rem]
                            lg:text-[1.65rem]
                            leading-snug
                          "
                        >
                          {item.translation}
                        </div>
                      )}
                    </div>
                  ) : (
                    /* User message */
                    <div>
                      {preventLineBreaks(item.text || '(user...)')}
                    </div>
                  )}
                </div>

                {/* “Traduire” button => only for assistant messages */}
                {isAssistant && (
                  <button
                    onClick={() => onTranslate(item.id)}
                    className="
                      absolute
                      bottom-2
                      right-2
                      opacity-50
                      hover:opacity-100
                      transition-opacity
                    "
                    aria-label="Traduire ce message"
                  >
                    <img
                      src="/translation-button.png"
                      alt="Translate"
                      className="w-5 h-5"
                    />
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* (2) Correction side panel => shown if a user message is selected */}
      {selectedItemForSidePanel?.correction && (
        <FeedbackSidePanel
          item={selectedItemForSidePanel}
          onClose={() => setSelectedItemForSidePanel(null)}
        />
      )}
    </div>
  );
}

export default ConversationList;