// /src/pages/ConsolePage/components/ConversationSection.tsx

import React, { useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

// On récupère currentUser via AuthContext
import { useAuth } from '../../../context/AuthContext';
// On récupère studyLanguage et selectedScenario via StudyContext
import { useStudy } from '../../../context/StudyContext';

// Composants visuels
import { ConversationList } from '../ConversationList';
import { IcebreakerButtons } from '../IcebreakerButtons';
import { SuggestionBubble } from '../SuggestionBubble';

// **On importe ton type existant (avec 'status')**
import { ConversationItemType } from '../../../types';

// Utils
import { getCultureData } from '../../../utils/cultureMap';

type ConversationSectionProps = {
  /**
   * items => tableau de messages, avec le type existant `ConversationItemType`
   * (qui inclut status, etc.).
   */
  items: ConversationItemType[];

  showIcebreaker: boolean;
  setShowIcebreaker: Dispatch<SetStateAction<boolean>>;

  showSuggestion: boolean;
  setShowSuggestion: Dispatch<SetStateAction<boolean>>;

  revealedPhrase: string | null;
  setRevealedPhrase: Dispatch<SetStateAction<string | null>>;

  skipSuggestionInFreeChat: boolean;
  setSkipSuggestionInFreeChat: Dispatch<SetStateAction<boolean>>;

  /**
   * handleSendTextMessage => envoie un message texte via dataChannel
   * renvoie une Promise<void>.
   */
  handleSendTextMessage: (text: string) => Promise<void>;

  /** handleTranslate => déclenche la traduction d'un message */
  handleTranslate: (messageId: string) => void;
};

export function ConversationSection(props: ConversationSectionProps) {
  const {
    items,
    showIcebreaker,
    setShowIcebreaker,
    showSuggestion,
    setShowSuggestion,
    revealedPhrase,
    setRevealedPhrase,
    setSkipSuggestionInFreeChat,
    handleSendTextMessage,
    handleTranslate,
  } = props;

  // i18n (2 namespaces) => icebreaker.json + consolePage.json
  const { t: tIcebreaker } = useTranslation('icebreaker');
  const { t: tConsole } = useTranslation('consolePage');

  // AuthContext => currentUser
  const { currentUser } = useAuth();
  // StudyContext => studyLanguage, selectedScenario
  const { studyLanguage, selectedScenario } = useStudy();

  // REF pour auto-scroll
  const conversationContentRef = useRef<HTMLDivElement>(null);

  // -------------------------------------------------------------------------
  // 1) handleIcebreakerSelect => envoie un message user
  // -------------------------------------------------------------------------
  async function handleIcebreakerSelect(category: string) {
    let userMsg = '';
    switch (category) {
      case 'fun':
        userMsg = tIcebreaker('funMessage', { lng: studyLanguage });
        break;
      case 'interesting':
        userMsg = tIcebreaker('interestingMessage', { lng: studyLanguage });
        break;
      case 'culture': {
        const { name: cultureName } = getCultureData(studyLanguage);
        userMsg = tIcebreaker('cultureMessagePattern', {
          lng: studyLanguage,
          cultureName,
        });
        break;
      }
      case 'random':
        userMsg = tIcebreaker('randomMessage', { lng: studyLanguage });
        break;
      default:
        userMsg = tIcebreaker('surpriseMessage', { lng: studyLanguage });
        break;
    }

    // Envoie le message
    await handleSendTextMessage(userMsg);

    // Ferme l'UI “icebreaker”
    setShowIcebreaker(false);
    // Autorise la suggestion bubble
    setSkipSuggestionInFreeChat(false);
  }

  // -------------------------------------------------------------------------
  // 2) handleClickLightbulb => fetch suggestion
  // -------------------------------------------------------------------------
  async function handleClickLightbulb() {
    if (!currentUser?.uid) {
      console.warn('[ConversationSection] => handleClickLightbulb => no user => skip');
      return;
    }

    const payload = {
      uid: currentUser.uid,
      studyLanguage,
      scenarioId: selectedScenario,
    };

    try {
      console.log('[ConversationSection] => POST /api/conversation/suggestion =>', payload);
      const res = await fetch('/api/conversation/suggestion', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!res.ok) {
        console.warn('[ConversationSection] => /suggestion => HTTP error =>', res.status);
        return;
      }

      const data = await res.json();
      console.log('[ConversationSection] => /suggestion => data =', data);

      const suggestion = data.suggestion?.trim() || '(Pas de suggestion)';
      setRevealedPhrase(suggestion);
      setShowSuggestion(true);
    } catch (err) {
      console.error('[ConversationSection] => handleClickLightbulb => error =>', err);
    }
  }

  // -------------------------------------------------------------------------
  // 3) Auto-scroll quand items changent
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (conversationContentRef.current) {
      conversationContentRef.current.scrollTop =
        conversationContentRef.current.scrollHeight;
    }
  }, [items]);

  // -------------------------------------------------------------------------
  // 4) Auto-scroll si showIcebreaker
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (showIcebreaker && conversationContentRef.current) {
      conversationContentRef.current.scrollTo({
        top: conversationContentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [showIcebreaker]);

  // -------------------------------------------------------------------------
  // 5) Auto-scroll si la bulle de suggestion s'affiche ou se met à jour
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (showSuggestion && conversationContentRef.current) {
      // On attend un petit délai pour que la bulle se rende
      setTimeout(() => {
        conversationContentRef.current?.scrollTo({
          top: conversationContentRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 50);
    }
  }, [showSuggestion, revealedPhrase]);

  // -------------------------------------------------------------------------
  // 6) Rendu
  // -------------------------------------------------------------------------
  return (
    <div
      ref={conversationContentRef}
      className="flex-grow w-full overflow-y-auto mt-4"
      style={{ maxHeight: 'calc(100vh - 250px)' }}
    >
      {/* (A) Liste des messages => ConversationList */}
      <ConversationList
        items={items} // => ConversationItemType[]
        onTranslate={handleTranslate}
        preventLineBreaks={(txt) =>
          txt?.replace(/ (\?|!|:|;)/g, '\u00A0$1') || ''
        }
      />

      {/* (B) IcebreakerButtons => si showIcebreaker */}
      {showIcebreaker && (
        <IcebreakerButtons onSelect={handleIcebreakerSelect} />
      )}

      {/* (C) SuggestionBubble => si showSuggestion */}
      {showSuggestion && (
        <SuggestionBubble
          text={tConsole('micInstruction')}
          onClick={handleClickLightbulb}
          revealedPhrase={revealedPhrase}
        />
      )}
    </div>
  );
}